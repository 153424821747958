import { Component, OnInit, Input, ElementRef } from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Shape from 'd3-shape';

// length of the overflow bar and gradient
// percentage of circle as a decimal (currently ~60 degrees)
const OVERFLOW_LENGTH = 0.16;

//convert percent of circle into angle from zero in radians
function percentageToRad(percentage: number): number {
    return percentage * 2 * Math.PI;
}

//gets the cartesian x coordinate of point
//angle in radians, offset by 90 degrees since 0 is (0,1) not (1,0) like normal cartesian
function coordinateX(radius: number, angle: number): number {
    return radius * Math.cos(angle - Math.PI / 2);
}

//gets the cartesian x coordinate of point
//angle in radians, offset by 90 degrees since 0 is (0,1) not (1,0) like normal cartesian
function coordinateY(radius: number, angle: number): number {
    return radius * Math.sin(angle - Math.PI / 2);
}

//generates the gradient to lighten the end of the progress bar when overlapping itself from +100% acheivement
function generateOverflowGradient(
    percentage: number,
    arcWidth: number,
    defs: any
) {
    // overflow just needs to show the final part of the arc to apply the gradient
    // ~40 degrees was chosen but anything under 90% should render okay if gradient isnt major contrast
    // x1, y2 offset by
    let gradient = defs
        .append('linearGradient')
        .attr('id', 'overflow-gradient' + percentage)
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr(
            'x1',
            coordinateX(arcWidth, percentageToRad(percentage - OVERFLOW_LENGTH))
        )
        .attr(
            'y1',
            coordinateY(arcWidth, percentageToRad(percentage - OVERFLOW_LENGTH))
        )
        .attr('x2', coordinateX(arcWidth, percentageToRad(percentage)))
        .attr('y2', coordinateY(arcWidth, percentageToRad(percentage)));

    gradient
        .append('stop')
        .attr('class', 'stop-left')
        .attr('offset', '0')
        .attr('stop-color', '#029289');

    gradient
        .append('stop')
        .attr('class', 'stop-right')
        .attr('offset', '0.95')
        .attr('stop-color', '#00d1c1');
}

function generateShadowGradient(defs: any) {
    let shadowGradient = defs
        .append('radialGradient')
        .attr('id', 'shadow-gradient');

    shadowGradient
        .append('stop')
        .attr('offset', '0.4')
        .attr('stop-color', '#000')
        .attr('stop-opacity', '.9');

    shadowGradient
        .append('stop')
        .attr('offset', '1')
        .attr('stop-color', '#000')
        .attr('stop-opacity', '.2');
}
@Component({
    selector: 'app-progress-ring',
    templateUrl: './progress-ring.component.html',
    styleUrls: ['./progress-ring.component.scss'],
})
export class ProgressRingComponent implements OnInit {
    @Input()
    completed: number = 0;

    @Input()
    committed: number = 0;

    arc: any;
    svg: any;
    meter: any;
    defs: any;

    static uniqueID: number = 0;
    id: number;

    constructor() {
        this.id = ProgressRingComponent.uniqueID++;
    }

    get radius(): number {
        return 100;
    }

    get boxSize(): number {
        return this.radius * 2;
    }

    get thickness(): number {
        return Math.round((this.radius * 2) / 7.5);
    }

    get arcWidth(): number {
        return this.radius - this.thickness / 2;
    }

    get percentage(): number {
        if (!this.committed) {
            return 0;
        }
        return this.completed / this.committed;
    }

    ngOnInit() {}

    ngOnChanges() {
        /*
         * timeout was added since the div hasnt been added by the DOM at the point
         * of this being called so the selector cannot find the element.
         * this allows it to render and angular change detection to fire before selecting
         */

        setTimeout(() => {
            d3.select('#progress-ring-' + this.id + ' svg').remove();
            this.drawSvg();
        }, 100);
    }

    drawSvg() {
        this.arc = d3Shape
            .arc()
            .startAngle(0)
            .outerRadius(this.radius)
            .innerRadius(this.radius - this.thickness)
            .cornerRadius(this.thickness / 2);

        this.svg = d3
            .select('#progress-ring-' + this.id)
            .append('svg')
            .classed('svg' + this.id, true)
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .attr('viewBox', '0 0 ' + this.boxSize + ' ' + this.boxSize)
            .append('g')
            .attr(
                'transform',
                'translate(' + this.boxSize / 2 + ',' + this.boxSize / 2 + ')'
            );

        this.meter = this.svg.append('g').attr('class', 'progress-meter');

        // background ring
        this.meter
            .append('path')
            .attr('class', 'background')
            .attr('fill', '#ddd')
            .attr('d', this.arc.endAngle(2 * Math.PI));

        // main progress ring
        this.meter
            .append('path')
            .attr('class', 'progress')
            .attr('fill', '#029289')
            .attr('d', this.arc.endAngle(percentageToRad(this.percentage)));

        // create overflow ring for acheivement over 100%
        // overflow ring has gradient and dropshadow to create contrast from ring below
        if (this.percentage > 1) {
            //only want decimal for the angle
            let overflowPercentage = this.percentage % 1;

            this.defs = this.svg.append('defs');

            generateOverflowGradient(
                overflowPercentage,
                this.arcWidth,
                this.defs
            );

            //instead of using drop shadow , use a circle with opacity gradient
            generateShadowGradient(this.defs);

            // circle to add shadow effect between overflow and main bar
            // keeps confined within the circle and moves proper direction
            let shadowCircle = this.meter
                .append('circle')
                .attr(
                    'cx',
                    coordinateX(
                        this.arcWidth,
                        percentageToRad(overflowPercentage - 0.009)
                    )
                )
                .attr(
                    'cy',
                    coordinateY(
                        this.arcWidth,
                        percentageToRad(overflowPercentage - 0.009)
                    )
                )
                .attr('r', this.thickness / 2)
                .style('fill', 'url(#shadow-gradient)');

            // overflow just needs to show the final part of the arc to apply the gradient
            // ~40 degrees was chosen but anything under 90 degrees should render okay if gradient isnt major contrast
            this.meter
                .append('path')
                .attr('class', 'overflow')
                .attr(
                    'fill',
                    'url(#overflow-gradient' + overflowPercentage + ')'
                )
                .attr(
                    'd',
                    this.arc.startAngle(
                        percentageToRad(overflowPercentage - OVERFLOW_LENGTH)
                    )
                )
                .attr(
                    'd',
                    this.arc.endAngle(percentageToRad(overflowPercentage))
                );
        }
    }
}
