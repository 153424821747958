import { ROLE_CLIENT } from './authentication.service';

export interface InvitePro {
    id: number;
    name: string;
    city: string;
    province: string;
    country: string;
}

export interface InviteProTeam {
    id: number;
    members: InvitePro[];
}

export interface Invite {
    id: number;
    accessCode: string;
    isStudent: boolean;
    providesRole: string;
    connectProTeam: InviteProTeamRef;
    connectPro: InviteProRef;
}

export type InviteRef = Invite | null;
export type InviteProRef = InvitePro | null;
export type InviteProTeamRef = InviteProTeam | null;

export function isInvite(data: unknown): data is Invite {
    const invite = data as Invite;
    return !!(
        invite &&
        typeof invite.id === 'number' &&
        typeof invite.accessCode === 'string' &&
        typeof invite.providesRole === 'string' &&
        typeof invite.isStudent === 'boolean' &&
        (invite.connectProTeam === null ||
            isInviteProTeam(invite.connectProTeam)) &&
        (invite.connectPro === null || isInvitePro(invite.connectPro))
    );
}

export function isInvitePro(data: unknown): data is InvitePro {
    const pro = data as InvitePro;
    return !!(
        pro &&
        typeof pro.id === 'number' &&
        typeof pro.name === 'string' &&
        typeof pro.city === 'string' &&
        typeof pro.province === 'string' &&
        typeof pro.country === 'string'
    );
}

export function isInviteProTeam(data: unknown): data is InviteProTeam {
    const proTeam = data as InviteProTeam;
    return !!(
        proTeam &&
        typeof proTeam.id === 'number' &&
        Array.isArray(proTeam.members) &&
        proTeam.members.every(isInvitePro)
    );
}

export function asInviteProTeam(data: any): InviteProTeam {
    if (!data) {
        return null;
    }
    const members = data['members'];
    if (!Array.isArray(members)) {
        return null;
    }
    const proTeam = {
        id: data['id'],
        members: members.map(asInvitePro),
    };
    if (isInviteProTeam(proTeam)) {
        return proTeam;
    }
    return null;
}

export function asInvitePro(data: any): InviteProRef {
    if (!data) {
        return null;
    }
    const pro = {
        id: data['id'],
        name: data['name'],
        city: data['city'],
        province: data['province'],
        country: data['country'],
    };
    if (isInvitePro(pro)) {
        return pro;
    }
    return null;
}

export function asInvite(data: any): InviteRef {
    if (!data) {
        return null;
    }
    if (data['connect_pro'] === undefined) {
        return null;
    }
    if (data['connect_pro_team'] === undefined) {
        return null;
    }
    const invite = {
        id: data['id'],
        accessCode: data['access_code'],
        providesRole: data['provides_role'],
        isStudent: data['is_student'],
        connectProTeam: asInviteProTeam(data['connect_pro_team']),
        connectPro: asInvitePro(data['connect_pro']),
    };
    if (isInvite(invite)) {
        return invite;
    }
    return null;
}

export function asInviteArray(data: any): Invite[] | null {
    if (!data) {
        return null;
    }
    if (!Array.isArray(data)) {
        return null;
    }
    const invites = data.map(asInvite);
    if (!invites.every(isInvite)) {
        return null;
    }
    return invites;
}

export function getFirstClientInvite(invites: Invite[]): InviteRef {
    const clientInvite = invites.find(
        (invite) => invite.providesRole === ROLE_CLIENT
    );
    if (!clientInvite) {
        return null;
    }
    return clientInvite;
}
