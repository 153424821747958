// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main > p {
  margin-left: var(--app-page-margin);
  margin-right: var(--app-page-margin);
  margin-bottom: 0.25em;
}

ion-button ion-icon {
  color: white;
}

ion-input[formControlName=accessCode] {
  text-transform: uppercase;
}

.page-content p {
  padding: 0 1em;
}

.terms {
  --min-height: unset;
}

ion-label {
  margin: 0;
}

ion-item {
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/pro-registration/pro-registration.page.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;EACA,oCAAA;EACA,qBAAA;AACJ;;AAGI;EACI,YAAA;AAAR;;AAIA;EACI,yBAAA;AADJ;;AAIA;EACI,cAAA;AADJ;;AAIA;EACI,mBAAA;AADJ;;AAIA;EACI,SAAA;AADJ;;AAIA;EAEI,iBAAA;AAFJ","sourcesContent":["main > p {\n    margin-left: var(--app-page-margin);\n    margin-right: var(--app-page-margin);\n    margin-bottom: 0.25em;\n}\n\nion-button {\n    ion-icon {\n        color: white;\n    }\n}\n\nion-input[formControlName='accessCode'] {\n    text-transform: uppercase;\n}\n\n.page-content p {\n    padding: 0 1em;\n}\n\n.terms {\n    --min-height: unset;\n}\n\nion-label {\n    margin: 0;\n}\n\nion-item {\n    // Otherwise the top/bottom toggle highlight box gets cut off\n    overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
