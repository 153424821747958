import { Injectable } from '@angular/core';

import { Preferences } from '@capacitor/preferences';

/* Storage access keys must be declared here and must be unique app-wide */
export enum StorageKey {
    PUSH = 'ggf_push',
    DEVICE = 'ggf_device',
    TOKEN = 'ggf_token',
    COUNTRY = 'ggf_country',
    // Used only for tests:
    TEST = 'test',
}

// Key prefix for all stored data. Note: automatically added and
// removed when saving or fetching data.
export const STORAGE_GROUP = 'GGF';

/*
 * Asynchronous storage service. This wraps around the Capacitor Storage API.
 * This service will also automatically fetch (legacy) data from local storage
 * and migrate it into capacitor storage.
 * (see https://capacitorjs.com/docs/apis/storage)
 */
@Injectable({
    providedIn: 'root',
})
export class StorageService {
    // Tracks whether storage configuration has already happened
    mustConfigure: boolean = true;

    constructor() {}

    /*
     * Configures capacitor storage for use by our app. Note: this MUST be
     * called before interacting with the storage API.
     */
    private configure(): Promise<void> {
        if (!this.mustConfigure) {
            return Promise.resolve();
        }
        return Preferences.configure({
            group: STORAGE_GROUP,
        }).then(() => {
            this.mustConfigure = false;
        });
    }

    /*
     * Save data to capacitor storage. Returns a promise that resolves when
     * complete.
     */
    save(key: StorageKey, value: string): Promise<void> {
        return this.configure().then(() => {
            return Preferences.set({ key: key, value: value });
        });
    }

    /*
     * Load data from capacitor storage. (returned via promise) This function
     * first attempts to load from capacitor storage, then from local storage,
     * and finally returns the value given by 'fallback'. Note: any data loaded
     * from local storage is migrated to capacitor storage. (copied + removed)
     */
    async load(key: StorageKey, fallback: string = ''): Promise<string | null> {
        await this.configure();
        const result = await Preferences.get({ key: key });
        if (result.value !== null) {
            return result.value;
        }
        /* Fallback to loading and migrating data from local storage. (ie. data
         * stored in an older version of the app) Note this load happens using
         * the key only, and doens't include the key prefix. */
        const legacy = localStorage.getItem(key);
        if (legacy !== null) {
            /* Note on the web version capacitor storage uses local storage. So
             * this effectively just adds the storage prefix to keys.
             * (ie 'ggf_token' => 'GGF.ggf_token') */
            await this.save(key, legacy);
            localStorage.removeItem(key);
            return legacy;
        }
        return fallback;
    }

    /*
     * Remove a previously stored item from capacitor storage. Returns a promise
     * that resolves when the removal is complete.
     */
    async remove(key: StorageKey): Promise<void> {
        await this.configure();
        await Preferences.remove({ key: key });
    }

    /*
     * Clear the contents of capacitor storage. Returns a promise that resolves
     * when the clearing is complete.
     */
    async clear(): Promise<void> {
        await this.configure();
        await Preferences.clear();
    }
}
