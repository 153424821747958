import { DateTime, Interval } from 'luxon';

import { Activity } from './activity.service';

import {
    getAdherence,
    getAverageMood,
    getAverageEffort,
    getScheduledMinutes,
    getCompletedActivities,
    getCompletedMinutes,
} from './activity-model';

import { getWeekStart, getWeekEnd } from '@app/date-utils';

export interface WeeklySummary {
    // The interval for this week. Note the week always starts at Monday 00:00
    // and ends the following Monday same time, less 1 ms.
    readonly interval: Interval;
    // Note: ISO date format (eg. "2022-03-28")
    readonly weekStartISO: string;
    readonly adherence: number;
    // Note: an average mood/effort of 0 for the week means no relfections
    // were logged for that week. Otherwise these values range 1-5.
    readonly averageMood: number;
    readonly averageEffort: number;
    readonly recordedActivities: number;
    readonly recordedMinutes: number;
    readonly scheduledActivities: number;
    readonly scheduledMinutes: number;
    readonly committedActivities: number;
    readonly committedMinutes: number;
}

export type WeeklySummaryRef = WeeklySummary | null;

export function isWeeklySummary(data: unknown): data is WeeklySummary {
    const summary = data as WeeklySummary;
    return !!(
        summary &&
        summary.interval instanceof Interval &&
        typeof summary.weekStartISO === 'string' &&
        typeof summary.adherence === 'number' &&
        typeof summary.averageMood === 'number' &&
        typeof summary.averageEffort === 'number' &&
        typeof summary.recordedActivities === 'number' &&
        typeof summary.recordedMinutes === 'number' &&
        typeof summary.scheduledActivities === 'number' &&
        typeof summary.scheduledMinutes === 'number' &&
        typeof summary.committedActivities === 'number' &&
        typeof summary.committedMinutes === 'number'
    );
}

export function asWeeklySummary(data: any): WeeklySummaryRef {
    if (!data) {
        return null;
    }
    const weekStart = DateTime.fromISO(data.week_start);
    const weekEnd = getWeekEnd(weekStart);
    const interval = Interval.fromDateTimes(weekStart, weekEnd);
    const summary = {
        interval: interval,
        weekStartISO: data.week_start,
        adherence: data.adherence,
        averageMood: data.average_mood,
        averageEffort: data.average_effort,
        recordedMinutes: data.recorded_minutes,
        recordedActivities: data.recorded_activities,
        scheduledMinutes: data.scheduled_minutes,
        scheduledActivities: data.scheduled_activities,
        committedMinutes: data.committed_minutes,
        committedActivities: data.committed_activities,
    };
    if (!isWeeklySummary(summary)) {
        return null;
    }
    return summary;
}

export function asWeeklySummaryArray(data: any): WeeklySummary[] | null {
    if (!Array.isArray(data)) {
        return null;
    }
    const summaries = data.map(asWeeklySummary);
    if (!summaries.every(isWeeklySummary)) {
        return null;
    }
    return summaries;
}

/* Returns the aggregated number of activites for a set of summaries */
export function getTotalActivities(
    summaries: readonly WeeklySummary[]
): number {
    return summaries
        .map((summary) => summary.recordedActivities)
        .reduce((previous, current) => previous + current, 0);
}

/* Returns the aggregated number of minutes for a set of summaries */
export function getTotalMinutes(summaries: readonly WeeklySummary[]): number {
    return summaries
        .map((summary) => summary.recordedMinutes)
        .reduce((previous, current) => previous + current, 0);
}

/* Returns the average number of activites for a set of summaries */
export function getAverageActivitiesPerWeek(
    summaries: readonly WeeklySummary[]
): number {
    if (summaries.length === 0) {
        return 0;
    }
    let averageActivitiesPerWeek =
        getTotalActivities(summaries) / summaries.length;
    return parseFloat(averageActivitiesPerWeek.toFixed(1));
}

/* Returns the average number of minutes for a set of summaries */
export function getAverageMinutesPerWeek(
    summaries: readonly WeeklySummary[]
): number {
    if (summaries.length === 0) {
        return 0;
    }
    let averageMinutesPerWeek = getTotalMinutes(summaries) / summaries.length;
    return parseFloat(averageMinutesPerWeek.toFixed(0));
}

/* Returns the weekly summaries sorted by (week) start date in acending order*/
export function sortedByStartTime(
    summaries: readonly WeeklySummary[]
): readonly WeeklySummary[] {
    return summaries.slice().sort((w1, w2) => {
        if (w1.interval.start > w2.interval.start) return 1;
        else if (w1.interval.start < w2.interval.start) return -1;
        return 0;
    });
}

export function sortedByDescendingStartTime(
    summaries: readonly WeeklySummary[]
): readonly WeeklySummary[] {
    return sortedByStartTime(summaries).slice().reverse();
}

export function makeWeeklySummaryFromExisting(
    existing: WeeklySummary,
    activities: readonly Activity[]
): WeeklySummary {
    return {
        interval: existing.interval,
        weekStartISO: existing.weekStartISO,
        adherence: getAdherence(
            activities,
            existing.committedActivities,
            existing.committedMinutes
        ),
        averageMood: getAverageMood(activities),
        averageEffort: getAverageEffort(activities),
        recordedActivities: getCompletedActivities(activities).length,
        recordedMinutes: getCompletedMinutes(activities),
        scheduledActivities: activities.length,
        scheduledMinutes: getScheduledMinutes(activities),
        committedActivities: existing.committedActivities,
        committedMinutes: existing.committedMinutes,
    };
}

export function getFirstWeeklySummary(
    summaries: readonly WeeklySummary[]
): WeeklySummaryRef {
    if (summaries.length === 0) {
        return null;
    }
    const sorted = sortedByStartTime(summaries);
    return sorted[0];
}

export function getLastWeeklySummary(
    summaries: readonly WeeklySummary[]
): WeeklySummaryRef {
    if (summaries.length === 0) {
        return null;
    }
    const sorted = sortedByStartTime(summaries);
    return sorted[sorted.length - 1];
}
