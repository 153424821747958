import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import { ToastController } from '@ionic/angular';

import { TimeTracker } from '@app/date-utils';

/*
 * A helper function for registering/unregistering a single handler for window
 * click events. New handlers replace previously assigned handlers. This is
 * used by the ToastManager to dismiss toasts when the user clicks anywhere
 * on the screen.
 */

class WindowClickHandler {
    handler: any;

    /* Set the current event handler, replacing the previous handler (if any) */
    set(func: any) {
        this.remove();
        window.addEventListener('click', func);
        this.handler = func;
    }

    /* Remove the handler (if any) */
    remove() {
        if (this.handler) {
            window.removeEventListener('click', this.handler);
            this.handler = null;
        }
    }
}

/* Manages displaying of a single toast notification at a time. When a new
 * toast is created it automatically displaces the old one. */
@Injectable({
    providedIn: 'root',
})
export class ToastService {
    toast: HTMLIonToastElement = null;
    windowClickHandler = new WindowClickHandler();
    ignoreRouterEventsTimer = new TimeTracker(1000);

    constructor(private toastCtrl: ToastController, private router: Router) {
        /* Automatically close toasts when the user navigates away, but only
         * if the toast has been open for a period of time. This prevents race
         * conditions where the user does something, the page creates a toast
         * and then navigates away causing the toast to quickly appear then
         * disappear. */
        this.router.events.subscribe((event) => {
            if (this.ignoreRouterEventsTimer.isDone && event) {
                this.dismiss();
            }
        });
    }

    /* Create a new toast notification and remove any previously created by
     * this class. */
    create(args: any): Promise<void> {
        return this.toastCtrl.create(args).then((toast) => {
            this.replace(toast);
            toast.present();
            toast.onDidDismiss().then(() => {
                /* This check avoids the race condition between asking that the
                 * current toast be dismissed, and onDidDismiss being called.
                 * This effectively checks if we've created a new toast between
                 * those events. */
                if (toast === this.toast) {
                    this.toast = null;
                }
            });
        });
    }

    createSuccess(message: string, duration: number = 0): Promise<void> {
        return this.create({
            id: 'toast-overlay',
            cssClass: 'success-toast',
            position: 'top',
            message: message,
            duration: duration,
        });
    }

    createFailure(message: string, duration: number = 0): Promise<void> {
        return this.create({
            id: 'toast-overlay',
            cssClass: 'error-toast',
            message: message,
            duration: duration,
        });
    }

    private replace(toast: HTMLIonToastElement) {
        if (this.toast) {
            this.dismiss();
        }
        this.toast = toast;
        this.ignoreRouterEventsTimer.start();
        if (toast) {
            // Dismiss this toast when the user clicks anywhere on the screen
            this.windowClickHandler.set(() => this.dismiss());
        }
    }

    /* Dismiss the currently open toast (if any) created by this class.
     * Returns a promise that resolves when the toast is finally removed. */
    dismiss(): Promise<boolean> {
        if (this.toast) {
            // Remove the window click handler so it's not always hanging
            // around, responding to every click.
            this.windowClickHandler.remove();
            const promise = this.toast.dismiss();
            this.toast = null;
            return promise;
        }
        return Promise.resolve(false);
    }
}
