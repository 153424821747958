import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
    constructor() {}

    retryWhen(error: HttpErrorResponse) {
        if (error.status === 502) {
            return timer(1000);
        }
        throw error;
    }

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next
            .handle(request)
            .pipe(retry({ count: 3, delay: this.retryWhen }));
    }
}
