import memoize from 'micro-memoize';

import { DateTime } from 'luxon';

import { Injectable } from '@angular/core';

import {
    HttpClient,
    HttpErrorResponse,
    HttpStatusCode,
} from '@angular/common/http';

import { getAPIError, InvalidServerResponse } from '@app/services/error';

import { UrlService, CannotBuildUrl } from '@app/services/url.service';

import {
    WeeklySummary,
    WeeklySummaryRef,
    isWeeklySummary,
    asWeeklySummary,
    asWeeklySummaryArray,
    sortedByStartTime,
    sortedByDescendingStartTime,
    getTotalActivities,
    getTotalMinutes,
    getFirstWeeklySummary,
    getLastWeeklySummary,
    getAverageActivitiesPerWeek,
    getAverageMinutesPerWeek,
} from './summary-model';

import { WeeklySummaryCacheService } from './summary-cache.service';

export {
    WeeklySummary,
    WeeklySummaryRef,
    isWeeklySummary,
    asWeeklySummary,
    asWeeklySummaryArray,
    getTotalActivities,
    getTotalMinutes,
    sortedByStartTime,
    sortedByDescendingStartTime,
    getFirstWeeklySummary,
    getLastWeeklySummary,
    getAverageActivitiesPerWeek,
    getAverageMinutesPerWeek,
};

const getFirstWeeklySummaryCached = memoize(getFirstWeeklySummary);
const getLastWeeklySummaryCached = memoize(getLastWeeklySummary);

@Injectable({
    providedIn: 'root',
})
export class SummaryService {
    constructor(
        private http: HttpClient,
        private urlService: UrlService,
        private cache: WeeklySummaryCacheService
    ) {}

    /*
     * Fetch the complete weekly summary history for this client and returns
     * it as a flat list. Note: this also updates the cache which is used on
     * subsequent calls.
     */
    async fetchWeekly(): Promise<readonly WeeklySummary[]> {
        if (this.cache.isValid) {
            return Promise.resolve(this.cache.summaries);
        }
        const url = await this.urlService.getWeeklySummaryUrl();
        return this.http
            .get(url)
            .toPromise()
            .then((payload) => {
                const summaries = asWeeklySummaryArray(payload);
                if (!summaries) {
                    throw InvalidServerResponse(payload);
                }
                return summaries;
            })
            .then((summaries) => {
                return this.cache.set(summaries);
            })
            .catch((response: HttpErrorResponse) => {
                throw getAPIError(response);
            });
    }

    /*
     * Returns the entirety of the weekly summary cache sorted by starting date
     * in descending order.
     */
    getWeeklyCached(): readonly WeeklySummary[] {
        return this.cache.summaries;
    }

    /*
     * Returns weekly summary cache for year of date
     */
    getYearCached(date: DateTime): readonly WeeklySummary[] {
        return this.cache.getYear(date);
    }

    /*
     * Returns the WeeklySummary that starts on the given date. Note only the
     * date portion of the DateTime is used to search the cache.
     */
    getWeekCached(weekStart: DateTime): WeeklySummaryRef {
        return this.cache.getWeek(weekStart);
    }

    /*
     * Returns the date of first week activity
     */
    getFirstWeekCache(): WeeklySummaryRef {
        return getFirstWeeklySummaryCached(this.cache.summaries);
    }

    /*
     * Returns the date of last/most recent week activity
     */
    getLastWeekCache(): WeeklySummaryRef {
        return getLastWeeklySummaryCached(this.cache.summaries);
    }

    clear(): Promise<void> {
        return this.cache.clear();
    }
}
