import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { DurationPopoverComponent } from '@app/components/duration-popover/duration-popover.component';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-duration-button',
    templateUrl: './duration-button.component.html',
    styleUrls: ['./duration-button.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DurationButtonComponent),
        },
    ],
})
export class DurationButtonComponent implements ControlValueAccessor {
    activityDuration: number;

    @Input()
    label: string = 'Duration';

    @Input()
    readonly: boolean = false;

    @Output()
    change: EventEmitter<number> = new EventEmitter();

    constructor(private popoverController: PopoverController) {}

    //calls show popover and if it returns none zero update activityDuration
    async onButtonClick(event) {
        if (this.readonly) {
            return;
        }
        this.touched = true;
        if (this.onTouched) {
            this.onTouched();
        }
        let popoverDuration = await DurationPopoverComponent.show(
            event,
            this.activityDuration,
            this.popoverController
        );
        if (popoverDuration !== 0) {
            this.activityDuration = popoverDuration;
            this.onChange(popoverDuration);
            this.change.emit(popoverDuration);
        }
    }

    onChange: any = () => {};

    onTouched = () => {};

    touched: boolean = false;

    disabled: boolean = false;

    writeValue(value: any): void {
        this.activityDuration = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
