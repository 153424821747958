import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Activity } from '@app/services/activity-model';

@Component({
    selector: 'app-weekly-progress',
    templateUrl: './weekly-progress.component.html',
    styleUrls: ['./weekly-progress.component.scss'],
})
export class WeeklyProgressComponent implements OnInit {
    @Input() activities: readonly Activity[] = [];
    @Input() committedActivities: number = 0;
    @Input() committedMinutes: number = 0;
    @Input() recordedMinutes: number = 0;
    @Input() inHistoricContext: boolean = false;

    constructor(private router: Router) {}

    get numMinutesToGo(): number {
        return this.committedMinutes - this.recordedMinutes;
    }

    ngOnInit() {}
}
