/*
 * See https://github.com/firebase/firebase-js-sdk
 * And https://firebase.google.com/docs/reference/js/messaging_
 */

import { Injectable, NgZone } from '@angular/core';

import { Capacitor } from '@capacitor/core';

import { deleteToken, getToken, MessagePayload } from 'firebase/messaging';

import { StorageService } from './storage.service';

import { PushService } from './push.service';

import {
    EVENT_TYPE_NOTIFICATION_ACTION,
    asNotificationActionData,
} from './notification-model';

import { FirebaseMessagingConfigurator } from './firebase-messaging-js-configurator';

import { environment } from '@app/../environments/environment';

import { wait } from '@app/form-utils';

// Defined in the firebase js SDK
const FIREBASE_PERMISSION_BLOCKED_ERROR_CODE = 'messaging/permission-blocked';

/*
 * Returns the state (eg. "granted") of the notification permission browser setting.
 * (see https://developer.mozilla.org/en-US/docs/Web/API/Notification/permission_static)
 */
async function getPermissionState(): Promise<string> {
    try {
        const state = Notification.permission;
        if (state !== 'default') {
            return state;
        }
        /* There is a possible bug in chrome/linux where the permission value changes
         * shortly after page load. Waiting a short period of time seems to fix it.
         * (see https://www.pivotaltracker.com/story/show/186042588/comments/238801155 */
        await wait();
        return Notification.permission;
    } catch (error) {
        console.error('getPermissionState: unexpected error', error);
        return '';
    }
}

/*
 * Implements web push notifications when the app is run in a browser. (ie non-native)
 */
@Injectable({
    providedIn: 'root',
})
export class WebPushService extends PushService {
    firebaseConfigurator = new FirebaseMessagingConfigurator(
        (messagePayload: MessagePayload) => {
            this.received.emit(messagePayload.data);
        }
    );

    constructor(storage: StorageService, private ngZone: NgZone) {
        super(storage);
        /*
         * Receive notification action events from our firebase service worker. These are
         * triggered when the app receives a notification and the app is currently running
         * in the browser but the tab/window doesn't have focus.
         */
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data['type'] === EVENT_TYPE_NOTIFICATION_ACTION) {
                    const actionData = event.data['notificationActionData'];
                    this.actionPerformed.emit({
                        notificationActionData: actionData,
                    });
                }
            });
        }
    }

    async getIsAvailable(): Promise<boolean> {
        const isAvailable = await this.firebaseConfigurator.isAvailable();
        return isAvailable && !!environment.firebaseVapidKey;
    }

    async configure(): Promise<void> {
        await this.firebaseConfigurator.configure();
    }

    async getDeclined(): Promise<boolean> {
        const state = await getPermissionState();
        return state === 'denied';
    }

    async getShouldPromptUser(): Promise<boolean> {
        // prompt - the OS says we should prompt the user for permissions
        // default - eg. permission was previously granted, then un-granted but not denied
        const state = await getPermissionState();
        const isAvailable = await this.getIsAvailable();
        return isAvailable && (state === 'prompt' || state === 'default');
    }

    async register(): Promise<boolean> {
        const result = await this.firebaseConfigurator.configure();
        if (!result) {
            console.log('WebPushService: messaging not available');
            return false;
        }
        if (!environment.firebaseVapidKey) {
            console.log(
                'WebPushService: messaging not available (no vapid key'
            );
            return false;
        }
        let token = '';
        try {
            token = await getToken(result.messaging, {
                serviceWorkerRegistration: result.serviceWorker,
                vapidKey: environment.firebaseVapidKey,
            });
        } catch (error) {
            if (error.code === FIREBASE_PERMISSION_BLOCKED_ERROR_CODE) {
                console.log('WebPushService: notification permission blocked');
                this.ngZone.run(() => {
                    this.declined.emit();
                });
                return false;
            }
            console.error(
                'WebPushService: unexpected error getting token:',
                error
            );
            this.ngZone.run(() => {
                this.registrationError.emit(error);
            });
            return false;
        }
        this.ngZone.run(() => {
            this.registered.emit(token);
        });
        return true;
    }

    async clear() {
        const result = await this.firebaseConfigurator.configure();
        if (!result) {
            return;
        }
        try {
            await deleteToken(result.messaging);
        } catch (error) {
            console.error('could not delete firebase token:', error);
        }
        await super.clear();
    }
}
