// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding: var(--app-page-margin);
}

section {
  margin: 1em;
  max-width: 40em;
  margin: 0 auto;
}
section h1 {
  font-size: x-large;
}

th,
td {
  border-bottom: 1px solid var(--app-color-border);
}

table {
  width: 100%;
  margin: 0 auto;
  max-width: 40em;
}
table th {
  color: var(--ion-color-primary);
  text-transform: uppercase;
  font-size: smaller;
  text-align: left;
  padding: 0.5em;
}
table td {
  text-align: left;
  padding: 0.5em;
}
table td:first-of-type {
  white-space: nowrap;
}

ion-content {
  max-height: 75vh;
}

ion-footer ion-toolbar {
  text-align: right;
  margin: 0 auto;
  max-width: 40em;
}
ion-footer ion-toolbar button {
  padding: 0.5em;
  border: none;
  color: var(--ion-color-primary);
  font-size: large;
  background-color: var(--ion-color-primary-contrast);
}`, "",{"version":3,"sources":["webpack://./src/app/components/reflection-info-popover/reflection-info-popover.component.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;AACJ;;AAEA;EACI,WAAA;EACA,eAAA;EACA,cAAA;AACJ;AACI;EACI,kBAAA;AACR;;AAGA;;EAEI,gDAAA;AAAJ;;AAGA;EACI,WAAA;EACA,cAAA;EACA,eAAA;AAAJ;AAEI;EACI,+BAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;AAAR;AAGI;EACI,gBAAA;EACA,cAAA;AADR;AAII;EACI,mBAAA;AAFR;;AAMA;EACI,gBAAA;AAHJ;;AAOI;EACI,iBAAA;EACA,cAAA;EACA,eAAA;AAJR;AAMQ;EACI,cAAA;EACA,YAAA;EACA,+BAAA;EACA,gBAAA;EACA,mDAAA;AAJZ","sourcesContent":[":host {\n    padding: var(--app-page-margin);\n}\n\nsection {\n    margin: 1em;\n    max-width: 40em;\n    margin: 0 auto;\n\n    h1 {\n        font-size: x-large;\n    }\n}\n\nth,\ntd {\n    border-bottom: 1px solid var(--app-color-border);\n}\n\ntable {\n    width: 100%;\n    margin: 0 auto;\n    max-width: 40em;\n\n    th {\n        color: var(--ion-color-primary);\n        text-transform: uppercase;\n        font-size: smaller;\n        text-align: left;\n        padding: 0.5em;\n    }\n\n    td {\n        text-align: left;\n        padding: 0.5em;\n    }\n\n    td:first-of-type {\n        white-space: nowrap;\n    }\n}\n\nion-content {\n    max-height: 75vh;\n}\n\nion-footer {\n    ion-toolbar {\n        text-align: right;\n        margin: 0 auto;\n        max-width: 40em;\n\n        button {\n            padding: 0.5em;\n            border: none;\n            color: var(--ion-color-primary);\n            font-size: large;\n            background-color: var(--ion-color-primary-contrast);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
