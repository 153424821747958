import { Component, OnInit } from '@angular/core';

import { PopoverController } from '@ionic/angular';

import { ErrorService } from '@app/services/error.service';

import { PushService } from '@app/services/push.service';

@Component({
    selector: 'app-notifications-opt-in',
    templateUrl: './notifications-opt-in.page.html',
    styleUrls: ['./notifications-opt-in.page.scss'],
})
export class NotificationsOptInPage implements OnInit {
    constructor(
        private pushService: PushService,
        private errorService: ErrorService,
        private popoverCtrl: PopoverController
    ) {}

    ngOnInit() {}

    onTurnOn() {
        this.pushService.register().then(() => {
            return this.popoverCtrl.dismiss();
        });
    }

    onLater() {
        this.popoverCtrl.dismiss();
    }

    static async show(popoverCtrl: PopoverController) {
        const popover = await popoverCtrl.create({
            component: NotificationsOptInPage,
            cssClass: 'notifications-opt-in-popover',
            translucent: false,
        });
        await popover.present();
        await popover.onDidDismiss();
    }
}
