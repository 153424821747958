// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1em;
}

h1 {
  font-size: large;
  margin-bottom: 0;
}

.message-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 5em;
  padding: 1em;
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 1em;
}

ion-button {
  font-size: inherit;
}

p {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/try-again-popover/try-again-popover.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EAGA,eAAA;EACA,YAAA;AADJ;;AAIA;EACI,aAAA;EACA,8BAAA;EACA,QAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,SAAA;AADJ","sourcesContent":[":host {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    text-align: center;\n    padding: 1em;\n}\n\nh1 {\n    font-size: large;\n    margin-bottom: 0;\n}\n\n.message-area {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    // Setting min-height helps prevent the page jumping when switching between\n    // message text, and the spinner.\n    min-height: 5em;\n    padding: 1em;\n}\n\n.button-container {\n    display: flex;\n    justify-content: space-between;\n    gap: 1em;\n}\n\nion-button {\n    font-size: inherit;\n}\n\np {\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
