import { Subscription } from 'rxjs';

import { Input, Component, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { PopoverController } from '@ionic/angular';

import { NotificationsOptInPage } from '@app/pages/notifications-opt-in/notifications-opt-in.page';

import { PushService } from '@app/services/push.service';

@Component({
    selector: 'app-notifications-opt-in-banner',
    templateUrl: './notifications-opt-in-banner.component.html',
    styleUrls: ['./notifications-opt-in-banner.component.scss'],
})
export class NotificationsOptInBannerComponent implements OnInit, OnDestroy {
    visible: boolean = false;
    registeredSub: Subscription;
    declinedSub: Subscription;

    constructor(
        private pushService: PushService,
        private popoverCtrl: PopoverController,
        private activatedRoute: ActivatedRoute
    ) {
        this.registeredSub = this.pushService.registered.subscribe(() => {
            this.visible = false;
        });
        this.declinedSub = this.pushService.declined.subscribe(() => {
            this.visible = false;
        });
        this.activatedRoute.url.subscribe(async () => {
            try {
                const declined = await this.pushService.getDeclined();
                const shouldPrompt =
                    await this.pushService.getShouldPromptUser();
                this.visible = shouldPrompt;
                if (!shouldPrompt && !declined) {
                    // No prompting is needed - just register if necessary
                    const token = await this.pushService.getToken();
                    if (!token) {
                        await this.pushService.register();
                    }
                    return;
                }
            } catch (error) {
                console.error(
                    'NotificationsOptInBannerComponent: registration error:',
                    error
                );
            }
        });
    }

    ngOnInit() {}

    onClick() {
        NotificationsOptInPage.show(this.popoverCtrl);
    }

    ngOnDestroy() {
        this.registeredSub.unsubscribe();
        this.declinedSub.unsubscribe();
    }
}
