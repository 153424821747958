import {
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    AbstractControl,
} from '@angular/forms';

import { AlertController } from '@ionic/angular';

import { CustomValidator } from 'gogetfit-common/validators';

import { Capacitor } from '@capacitor/core';

import { Keyboard } from '@capacitor/keyboard';

export { CustomValidator };

/* Mark the form as disabled until the given promise chain is resolved. If
 * the promise resolves to true, the form is enabled otherwise it's left
 * disabled. If the promise is rejected the form is always enabled.
 *
 * This function is useful if a form needs to be disabled while data is
 * submitted. (or alternatively being fetched from the server)
 *
 * TODO - this should restore the previous form state and not just do the naive
 * thing and re-enable all controls
 */
export function blockForm(
    form: UntypedFormGroup,
    promise: Promise<boolean>
): Promise<void> {
    /* Note: if we emit events here, forms will see the control values "change"
     * when they actually don't. This could break forms that eg. clear errors
     * whenever a form value is changed. */
    const args = { emitEvent: false };
    form.disable(args);
    return promise
        .then((result) => {
            if (result) {
                form.enable(args);
            }
        })
        .catch(() => {
            form.enable(args);
        });
}

/* Form validator that checks if the two given controls (by name) match */
export function validateFieldsMatch(
    field1: string,
    field2: string
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const control1 = control.get(field1);
        const control2 = control.get(field2);
        if (!control1 || !control2) {
            return null;
        }
        if (control1.value != control2.value) {
            return { fieldsDontMatch: true };
        }
        return null;
    };
}

/* Returns a promise that resolves after the given duration (ms) */
export function wait(duration: number = 1): Promise<void> {
    return new Promise((resolve, reject) => setTimeout(resolve, duration));
}

/* Close the on-screen keyboard if any. This is always safe to call even if the
 * platform doesn't support on-screen keyboards. */
export function hideKeyboard(): Promise<void> {
    if (Capacitor.getPlatform() !== 'web') {
        // Only available on mobile platforms
        return Keyboard.hide();
    }
    return Promise.resolve();
}

/*
 * Whether the given form control (by name) is showing a given error.
 */
export function hasFormError(
    form: UntypedFormGroup,
    field: string,
    error: string
): boolean {
    if (!form) {
        return false;
    }
    const control = form.controls[field];
    return !!(control && control.touched && control.hasError(error));
}

export async function showAlertYesNo(
    alertCtrl: AlertController,
    header: string,
    message: string
): Promise<boolean> {
    const alert = await alertCtrl.create({
        header: header,
        message: message,
        buttons: [
            {
                id: 'alert-answer-no',
                text: 'No',
            },
            {
                id: 'alert-answer-yes',
                text: 'Yes',
                role: 'confirm',
            },
        ],
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    return result.role === 'confirm';
}

export async function showAlertOkay(
    alertCtrl: AlertController,
    header: string,
    message: string
): Promise<boolean> {
    const alert = await alertCtrl.create({
        header: header,
        message: message,
        buttons: [
            {
                id: 'alert-answer-okay',
                text: 'Okay',
                role: 'confirm',
            },
        ],
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    return result.role === 'confirm';
}
