import memoize from 'micro-memoize';

import {
    Component,
    forwardRef,
    Input,
    Provider,
    Output,
    EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AlertController, IonicSafeString } from '@ionic/angular';

const AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutoCompleteCitiesComponent),
    multi: true,
};

const DATABASE_ATTRIBUTION_HELP_TEXT =
    '<p>Contains information from Count-state-city database, which is made available here under the <a tabindex="0" href="https://opendatacommons.org/licenses/odbl/1-0/" target="_blank" rel="noopener noreferrer">Open Database License (ODbL).</a></p><p>For your convenience we use this database to automatically suggest cities based on what you type.</p>';

function searchForCities(cities: string[], search: string): string[] {
    if (!search) {
        return cities;
    }
    const found = cities.filter((value) => {
        return value.toLowerCase().startsWith(search.toLowerCase());
    });
    return found;
}

const searchForCitiesCached = memoize(searchForCities);

@Component({
    selector: 'app-auto-complete-cities',
    templateUrl: './auto-complete-cities.component.html',
    styleUrls: ['./auto-complete-cities.component.scss'],
    providers: [AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR],
})
export class AutoCompleteCitiesComponent implements ControlValueAccessor {
    @Input() cityList;
    showDropDown: boolean = false;
    private onChanged: Function;
    onTouched = () => {};
    touched = false;
    selected: string;
    disabled = false;

    @Output()
    focus: EventEmitter<void> = new EventEmitter();

    constructor(private alertController: AlertController) {}

    get searchResults(): string[] {
        return searchForCitiesCached(this.cityList, this.getSearchValue());
    }

    closeDropDown() {
        this.showDropDown = false;
    }

    openDropDown() {
        this.showDropDown = true;
    }

    getSearchValue() {
        return this.selected;
    }

    writeValue(value: string): void {
        this.selected = value;
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    selectValue(value) {
        this.onTouched();
        if (this.onChanged) this.onChanged(value);
        this.selected = value;
        this.showDropDown = false;
    }

    handleChange(change: any) {
        this.onTouched();
        this.selected = change;
        if (this.onChanged) this.onChanged(this.selected);
    }

    onFocus() {
        this.focus.emit();
    }

    async onDataSourceClick() {
        const msg = new IonicSafeString(DATABASE_ATTRIBUTION_HELP_TEXT);
        const alert = await this.alertController.create({
            cssClass: 'database-attribution-help-alert',
            header: 'Country-state-city Database',
            message: msg,
            buttons: ['OK'],
        });

        await alert.present();
        await alert.onDidDismiss();
    }
}
