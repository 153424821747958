import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import {
    PushService,
    PushServiceActionPerformed,
} from '@app/services/push.service';

import { getNotificationActionUrl } from './notification-model';

@Injectable({
    providedIn: 'root',
})
export class NotificationActionService {
    constructor(pushService: PushService, private router: Router) {
        // Handle tapping on a notification (when app is closed)
        pushService.actionPerformed.subscribe(
            (action: PushServiceActionPerformed) => {
                try {
                    const url = getNotificationActionUrl(
                        action.notificationActionData
                    );
                    if (url) {
                        this.router.navigateByUrl(url);
                    }
                } catch (error) {
                    console.log('NotificationActionService error:', error);
                }
            }
        );

        // Handle receiving a notification (when app is open)
        // TODO - this is probably a rare condition but we should implement
        // this behaviour
        // pushService.received.subscribe(() => {
        // });
    }
}
