import { Component, OnInit } from '@angular/core';

import { PopoverController } from '@ionic/angular';

import { ActivityService } from '@app/services/activity.service';

@Component({
    selector: 'app-reflection-info-popover',
    templateUrl: './reflection-info-popover.component.html',
    styleUrls: ['./reflection-info-popover.component.scss'],
})
export class ReflectionInfoPopoverComponent implements OnInit {
    labels: string[] = ActivityService.EFFORT_LABELS;
    descriptions: string[] = [
        'Can talk normally, breathing normally',
        'Takes some effort but can carry conversation, light breathing',
        'Struggling to talk, only one or two sentences at a time, breathing getting faster',
        'Broken sentences or words, heavy breathing',
        'Cannot talk, gasping for breath',
    ];

    constructor(private popoverCtrl: PopoverController) {}

    ngOnInit() {}

    onClose() {
        this.popoverCtrl.dismiss();
    }
}
