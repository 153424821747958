import { DateTime, Interval } from 'luxon';

import { toUTCISO } from '@app/date-utils';

export enum ActivityStatus {
    PENDING = 'PENDING',
    COMPLETE = 'COMPLETE',
    SKIPPED = 'SKIPPED',
}

export interface RecordedActivity {
    readonly mood: number;
    readonly effort: number;
    readonly actualDurationMinutes: number;
}

export interface GroupActivity {
    readonly createdISO: string;
    readonly id: number;
    readonly proID: number;
    readonly linkName: string;
    readonly linkUrl: string;
}

export interface Activity {
    readonly id?: number;
    readonly startTime: string;
    readonly durationMinutes: number;
    readonly details: string;
    readonly detailLines: readonly string[];
    readonly recorded: RecordedActivityRef;
    readonly interval: Interval;
    readonly groupActivity: GroupActivityRef;
    readonly status: ActivityStatus;
}

export type RecordedActivityRef = RecordedActivity | null;

export type ActivityRef = Activity | null;

export type GroupActivityRef = GroupActivity | null;

export function isGroupActivity(data: unknown): data is GroupActivity {
    const groupActivity = data as GroupActivity;
    return !!(
        groupActivity &&
        typeof groupActivity.id === 'number' &&
        typeof groupActivity.createdISO === 'string' &&
        typeof groupActivity.proID === 'number' &&
        typeof groupActivity.linkName === 'string' &&
        typeof groupActivity.linkUrl === 'string'
    );
}

export function isActivity(data: unknown): data is Activity {
    function isRecorded(rdata: unknown): rdata is RecordedActivity {
        const recorded = rdata as RecordedActivity;
        return !!(
            recorded &&
            typeof recorded.mood === 'number' &&
            typeof recorded.effort === 'number' &&
            typeof recorded.actualDurationMinutes === 'number'
        );
    }
    const activity = data as Activity;
    return !!(
        activity &&
        (typeof activity.id === 'number' || activity.id === undefined) &&
        typeof activity.startTime === 'string' &&
        typeof activity.durationMinutes === 'number' &&
        typeof activity.details === 'string' &&
        activity.interval instanceof Interval &&
        (activity.recorded === null || isRecorded(activity.recorded)) &&
        Array.isArray(activity.detailLines) &&
        activity.detailLines.every((line) => typeof line === 'string') &&
        (activity.groupActivity === null ||
            isGroupActivity(activity.groupActivity)) &&
        (activity.status === ActivityStatus.PENDING ||
            activity.status === ActivityStatus.COMPLETE ||
            activity.status === ActivityStatus.SKIPPED)
    );
}

export function getDetailLines(details?: string): string[] | null {
    if (details === null || details === undefined) {
        return null;
    }
    if (details === '') {
        return [];
    }
    return details.trim().split('\n');
}

export function asActivity(data: any): ActivityRef {
    if (!data) {
        return null;
    }
    let recorded = null;
    if (data.recorded) {
        const actualDurationMinutes = Math.floor(
            data.recorded.duration_seconds / 60
        );
        recorded = {
            mood: data.recorded.mood,
            effort: data.recorded.effort,
            actualDurationMinutes: actualDurationMinutes,
        };
    }
    let groupActivity = null;
    if (data.group_activity) {
        groupActivity = {
            createdISO: data.group_activity.created,
            id: data.group_activity.id,
            proID: data.group_activity.pro_id,
            linkName: data.group_activity.link_name,
            linkUrl: data.group_activity.link_url,
        };
    }
    const startDateTime = DateTime.fromISO(data.start_time);
    const durationMinutes = Math.floor(data.duration_seconds / 60);
    const activity = {
        id: data.id,
        startTime: toUTCISO(data.start_time),
        durationMinutes: durationMinutes,
        details: data.details,
        detailLines: getDetailLines(data.details),
        recorded: recorded,
        interval: Interval.fromDateTimes(
            startDateTime,
            startDateTime.plus({ minutes: durationMinutes })
        ),
        groupActivity: groupActivity,
        status: data.status,
    };
    if (!isActivity(activity)) {
        return null;
    }
    return activity;
}

export function asActivityArray(data: any): Activity[] | null {
    if (!Array.isArray(data)) {
        return null;
    }
    const activities = data.map(asActivity);
    if (activities.some((activity) => !activity)) {
        return null;
    }
    return activities;
}

export function sortedByStartTime(
    activities: readonly Activity[]
): readonly Activity[] {
    return activities.slice().sort((a1, a2) => {
        if (a1.startTime < a2.startTime) return -1;
        else if (a1.startTime > a2.startTime) return 1;
        return 0;
    });
}

/* Whether the given activity falls in the current week (Monday to Monday) */
export function isActivityThisWeek(activity: Activity): boolean {
    const now = DateTime.now();
    const start = now.startOf('week');
    const end = now.endOf('week');
    const date = DateTime.fromISO(activity.startTime);
    return date >= start && date <= end;
}

/*
 * Returns the activities that fall within the week containing the given date.
 * Note this function considers the week to start Monday at 00:00:00
 */
export function filterByWeek(
    activities: readonly Activity[],
    now: DateTime
): readonly Activity[] {
    const weekStart = now.startOf('week');
    const weekEnd = now.endOf('week');
    return activities.filter((activity) => {
        const date = DateTime.fromISO(activity.startTime);
        return date >= weekStart && date <= weekEnd;
    });
}

/* Returns the total number of completed minutes in the list of activities */
export function getCompletedMinutes(activities: readonly Activity[]): number {
    return activities
        .map((activity) => {
            if (activity.recorded) {
                return activity.recorded.actualDurationMinutes;
            }
            return 0;
        })
        .reduce((previous, current) => previous + current, 0);
}

/*
 * Returns the total number of scheduled minutes in the list of activities.
 * Note if an activity is marked as recorded, it still counts as being
 * scheduled for the purposes of this tally.
 */
export function getScheduledMinutes(activities: readonly Activity[]): number {
    return activities
        .map((activity) => {
            return activity.durationMinutes;
        })
        .reduce((previous, current) => previous + current, 0);
}

/* Returns list of completed activities */
export function getCompletedActivities(
    activities: readonly Activity[]
): readonly Activity[] {
    return activities.filter((activity) => !!activity.recorded);
}

/* Returns list of missed activities, according to the given date */
export function getMissedActivities(
    activities: readonly Activity[],
    now: DateTime
): readonly Activity[] {
    return activities.filter((activity) => {
        const date = DateTime.fromISO(activity.startTime);
        return !activity.recorded && date < now;
    });
}

/* Returns the next, unrecorded, activity starting after the given datetime */
export function getNextUnrecordedActivity(
    activities: readonly Activity[],
    now: DateTime
): ActivityRef {
    const lst = sortedByStartTime(activities).filter((activity) => {
        const date = DateTime.fromISO(activity.startTime);
        return !activity.recorded && date >= now;
    });
    if (lst.length === 0) {
        return null;
    }
    return lst[0];
}

/*
 * An activity is considered missed if it's been left pending for "too long"
 * and the scheduled end time is in the past.
 * TODO - an activity should be considered missed if it's pending but the
 * client can't take action on it because it's too old
 */
export function isActivityMissed(
    activity: ActivityRef,
    now: DateTime
): boolean {
    return (
        activity &&
        activity.status === ActivityStatus.PENDING &&
        activity.interval.end < now
    );
}

/*
 * In the context of the client app an activity is upcoming if it is pending
 * with a start time scheduled in the future.
 */
export function isActivityUpcoming(
    activity: ActivityRef,
    now: DateTime
): boolean {
    return !!(
        activity &&
        activity.status === ActivityStatus.PENDING &&
        activity.interval.start > now
    );
}

export function isActivityPending(activity: ActivityRef): boolean {
    return !!(activity && activity.status === ActivityStatus.PENDING);
}

export function isActivityCompletable(
    activity: ActivityRef,
    now: DateTime
): boolean {
    return !!(activity && activity.interval.start <= now.endOf('day'));
}

export function isActivityInProgress(
    activity: ActivityRef,
    now: DateTime
): boolean {
    return !!(
        activity &&
        activity.status === ActivityStatus.PENDING &&
        activity.interval.start <= now &&
        activity.interval.end >= now
    );
}

export function isActivityCompleted(activity: ActivityRef): boolean {
    return activity && activity.status === ActivityStatus.COMPLETE;
}

export function isActivitySkipped(activity: ActivityRef): boolean {
    return activity && activity.status === ActivityStatus.SKIPPED;
}

function average(values: number[]): number {
    if (values.length === 0) {
        return 0;
    }
    const total = values.reduce((a, b) => a + b);
    return total / values.length;
}

export function getAverageMood(activities: readonly Activity[]): number {
    return average(
        activities
            .map((activity) => activity.recorded?.mood ?? 0)
            .filter((value) => !!value)
    );
}

export function getAverageEffort(activities: readonly Activity[]): number {
    return average(
        activities
            .map((activity) => activity.recorded?.effort ?? 0)
            .filter((value) => !!value)
    );
}

export function getAdherence(
    activities: readonly Activity[],
    committedActivities: number,
    committedMinutes: number
): number {
    const completedActivities = getCompletedActivities(activities).length;
    const completedMinutes = getCompletedMinutes(activities);
    if (committedActivities === 0 || committedMinutes === 0) {
        return 0;
    }
    const p1 = completedActivities / committedActivities;
    const p2 = completedMinutes / committedMinutes;
    return Math.round(100 * ((p1 + p2) / 2));
}
