import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import { UntypedFormGroup } from '@angular/forms';

import { PopoverController } from '@ionic/angular';

import { TryAgainPopoverComponent } from '@app/components/components.module';

import { ToastService } from './toast.service';

import {
    APIError,
    getUserFacingMessage,
    INVALID_TOKEN_URL,
    isUnrecoverableError,
    UserFacingError,
} from './error';

import { blockForm } from '@app/form-utils';

export { UserFacingError };

export type TryAgainFunc<T> = () => Promise<T>;

export type ConfigToastFunc = (options: any) => void;

/* Manages displaying of a single error popover at a time. When a new popover
 * is created it automatically displaces the old one. */
@Injectable({
    providedIn: 'root',
})
export class TryAgainPopoverManager {
    popover: HTMLIonPopoverElement;

    constructor(private popoverCtrl: PopoverController) {}

    /* Create and show the "try-again" popover. This will replace any currently
     * open popover created by this class. */
    create<T>(error: any, func: TryAgainFunc<T>) {
        return TryAgainPopoverComponent.create(this.popoverCtrl, error, func)
            .then((popover) => {
                this.replace(popover);
                return popover.onDidDismiss();
            })
            .then((result) => {
                this.popover = null;
                return result.data;
            });
    }

    /* Dismiss the currently open popover (if any) created by this class.
     * Returns a promise that resolves when the popover is finally removed. */
    dismiss(): Promise<boolean> {
        if (this.popover) {
            const promise = this.popover.dismiss();
            this.popover = null;
            return promise;
        }
        return Promise.resolve(false);
    }

    private replace(popover: HTMLIonPopoverElement) {
        this.dismiss();
        this.popover = popover;
    }
}

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    constructor(
        private router: Router,
        private popoverMgr: TryAgainPopoverManager,
        private toastMgr: ToastService
    ) {}

    /*
     * Dismiss all currently open popovers/toasts created by this service.
     * Returns a promise that resolves when all artifiacts are removed.
     */
    dismissAll(): Promise<void> {
        return Promise.all([
            this.popoverMgr.dismiss(),
            this.toastMgr.dismiss(),
        ]).then(() => {});
    }

    /*
     * Call the given function and provide the user an option to retry on
     * failure. (via popover)
     *
     * If func returns a resolved promise, it's considered to be success and
     * the value is passed back by this function. Otherwise if func returns a
     * rejected promise it's considered to be a failure and the user is
     * presented with an option to retry.
     */
    tryAgainPopover<T>(func: TryAgainFunc<T>): Promise<T> {
        const startingUrl = this.router.url;
        return func().catch((error) => {
            if (this.router.url !== startingUrl) {
                /* If we get here that means (likely) the user landed on a
                 * page, data started to load, and the user hit the back
                 * button then the request failed. (eg request timed out
                 * and user got impatient) So we skip showing the try-again
                 * popover in this case. */
                return Promise.resolve();
            }

            if (isUnrecoverableError(error)) {
                this.router.navigateByUrl(INVALID_TOKEN_URL);
                return Promise.resolve();
            }
            return this.popoverMgr.create(error, func);
        });
    }

    /*
     * Attempt to execute func once and display a toast notification on error.
     * The error is passed back via rejected promise. Otherwise on success the
     * promise returned by func is returned back.
     */
    tryOnceToast<T>(
        func: TryAgainFunc<T>,
        configToastFunc: ConfigToastFunc = () => {}
    ): Promise<T> {
        const startingUrl = this.router.url;
        return func().catch((error) => {
            if (this.router.url !== startingUrl) {
                /* If we get here that means (likely) the user landed on a
                 * page, data started to load, and the user hit the back
                 * button then the request failed. (eg request timed out
                 * and user got impatient) So we skip showing the toast. */
                throw APIError(error);
            }

            if (isUnrecoverableError(error)) {
                this.router.navigateByUrl(INVALID_TOKEN_URL);
                throw error;
            }

            return this.toastMgr
                .createFailure(getUserFacingMessage(error))
                .then(() => {
                    throw error;
                });
        });
    }

    /*
     * Attempt to submit the form using the given callback. The form is disabled
     * before the callback function is called. If the callback returns a
     * resolved promise the form is left disabled. (presumably the user would
     * be redirected at this point) Otherwise if the callback returns a
     * rejected promise the form is re-enabled and an error toast is shown.
     *
     * Returns the value returned by the callback function. (via promise)
     */
    trySubmitForm<T>(
        form: UntypedFormGroup,
        func: TryAgainFunc<T>
    ): Promise<T> {
        // The callback result needs to be tracked out of band because the
        // inner promise needs to resolve to a boolean indicating whether to
        // keep the form disabled.
        let result = null;
        return blockForm(
            form,
            this.tryOnceToast(() => {
                return func().then((value) => {
                    /* Use the return value to determine whether the form should
                     * be enabled again. Typically on success the caller returns
                     * the fetched object (truthy) in which case we want the
                     * form to stay disabled. (presumably they then navigate
                     * off page) */
                    result = value;
                    return !value;
                });
            })
        ).then(() => {
            return result;
        });
    }

    /*
     * Attempt to populate the form with content provided by the callback
     * function. The form is disabled until the callback returns a resolved
     * promise. If the callback returns a rejected promise the user is shown
     * a popover with an error message and the option to try again.
     *
     * Returns the value returned by the callback function. (via promise)
     */
    tryPopulateForm<T>(
        form: UntypedFormGroup,
        func: TryAgainFunc<T>
    ): Promise<T> {
        // The callback result needs to be tracked out of band because the
        // inner promise needs to resolve to a boolean indicating whether to
        // keep the form disabled.
        let result = null;
        return blockForm(
            form,
            this.tryAgainPopover(() => {
                return func().then((value) => {
                    result = value;
                    // This re-enables the form on success
                    return true;
                });
            })
        ).then(() => {
            return result;
        });
    }
}
