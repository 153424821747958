import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import { AlertController } from '@ionic/angular';

import { Preferences } from '@capacitor/preferences';

import { AuthStorageService } from '@app/services/auth-storage.service';

import { ProfileService } from '@app/services/profile.service';

import { CommitmentService } from '@app/services/commitment.service';

import {
    ConnectionService,
    isNoClientResponse,
} from '@app/services/connection.service';

import { ActivityService } from '@app/services/activity.service';

import { RecommendationService } from '@app/services/recommendation.service';

import { SummaryService } from '@app/services/summary.service';

import { MessageCacheService } from '@app/services/message-cache.service';

import { DeviceService } from './device.service';

import { TermsService } from './terms.service';

import { PushService } from './push.service';

import { INVALID_TOKEN_URL, isUnrecoverableError } from './error';

import { ErrorService } from './error.service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    constructor(
        private router: Router,
        private authStorage: AuthStorageService,
        private profileService: ProfileService,
        private commitmentService: CommitmentService,
        private connectionService: ConnectionService,
        private activityService: ActivityService,
        private recommendationService: RecommendationService,
        private summaryService: SummaryService,
        private messageService: MessageCacheService,
        private alertController: AlertController,
        private deviceService: DeviceService,
        private termsService: TermsService,
        private pushService: PushService
    ) {}

    /*
     * Navigate to the next page based on what resource/action the user needs
     * to take next. The ultimate goal of this function is to bring the user
     * to the home page so they can start using the app. Returns a promise
     * that resolves when the navigation has begun.
     *
     * For example, if the user is missing their profile calling this function
     * will bring them to the create profile page.
     */
    navigateNext(): Promise<void> {
        return this.getNavigateNextUrl().then((url) => {
            this.router.navigate([url]);
        });
    }

    /* Helper function for getNavigateNextUrl */
    private async _getNavigateNextUrl(target: string): Promise<string> {
        const profile = await this.profileService.fetch();
        if (!profile || !profile.city || !profile.province) {
            // Missing their profile so they must be registering
            return '/create-profile/registration';
        }
        if (!profile.hasAcceptedTerms) {
            return '/accept-terms/registered';
        }
        // User has a profile so now we can tell the user about this device
        await this.deviceService.createOrUpdateOnce();
        // Now we know we have a profile, we can fetch profile-related
        // resources without having to worry about 400s.
        const [overview, connections] = await Promise.all([
            this.commitmentService.fetchOverview(),
            this.connectionService.fetch(),
        ]);
        if (!(overview && overview.current)) {
            // The user has no commitment, so it must be registration.
            // But first visit the connection consent page if there's
            // anything they need to review/approve.
            const pending = connections.filter(isNoClientResponse);
            if (pending.length > 0) {
                return '/connection-consent/registration';
            }
            // Otherwise they should create their first commitment
            return '/edit-commitment/registration';
        }
        return target;
    }

    /*
     * The companion function to navigateNext, but instead just returns the URL
     * to navigate to rather than doing the actual navigation. If the user
     * passes all the app "registration gates" this function returns the given
     * target URL. Otherwise the URL of the next "gate" is returned.
     */
    getNavigateNextUrl(target: string = '/tabs/home'): Promise<string> {
        return this._getNavigateNextUrl(target).catch((error) => {
            if (isUnrecoverableError(error)) {
                console.log('AppService: logged out:', error);
                return INVALID_TOKEN_URL;
            }

            // Handle all other errors via a generic try-again page
            console.log('AppService:', error);
            return '/try-again';
        });
    }

    /*
     * Logout the user by purging all stored records and directing them to
     * the splash screen. Returns a promise that resolves when complete.
     */
    logoutLocal(): Promise<void> {
        localStorage.clear();
        const cleared = [
            this.authStorage.clear(),
            this.profileService.clear(),
            this.commitmentService.clear(),
            this.connectionService.clear(),
            this.activityService.clear(),
            this.recommendationService.clear(),
            this.summaryService.clear(),
            this.messageService.clear(),
            this.deviceService.clear(),
            this.termsService.clear(),
            this.pushService.clear(),
            Preferences.clear(),
        ];
        return Promise.all(cleared).then(() => {
            this.router.navigate(['/']);
        });
    }

    /*
     * Logout the user by purging all stored records and directing them to
     * the splash screen. Returns a promise that resolves when complete. This
     * also deletes the device record (if any) on the server.
     */
    logout(): Promise<void> {
        return this.deviceService.delete().then(() => {
            return this.logoutLocal();
        });
    }
}
