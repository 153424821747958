import { environment } from '@app/../environments/environment';

import { isPlatformWeb } from '@app/services/device.service';

const GOOGLE_SIGN_IN_BUTTON_WIDTH = 250; // Note this should match the SCSS

const GSI_SCRIPT_URL = 'https://accounts.google.com/gsi/client';

function getGoogleAPI(): any {
    // TODO - fix type
    return window['google'];
}

/*
 * Dynamically load a JS script into the document given the URL. Note this
 * function will modify the DOM by adding a new SCRIPT tag into the HEAD.
 * Returns a promise that resolves on success or failure.
 */
export function loadScript(url: string): Promise<void> {
    let script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.async = true;
    script.charset = 'utf-8';
    document.head.appendChild(script);

    return new Promise((resolve, reject) => {
        script.onload = () => {
            resolve();
        };
        script.onerror = (args) => {
            reject();
        };
    });
}

/*
 * Import and initialize the google identity services script
 */
export function initGoogleSignIn(handleCredentialResponse: any): Promise<void> {
    return loadScript(GSI_SCRIPT_URL).then(() => {
        const google = getGoogleAPI();
        if (!google) {
            return;
        }
        google.accounts.id.initialize({
            client_id: environment.googleOAuthClientID,
            callback: handleCredentialResponse,
        });
    });
}

/*
 * Adds the "sign in with google" button to the DOM inside the given element.
 * Note the containing element must be empty otherwise this function does
 * nothing. It's safe to call the function multiple times.
 */
export function renderGoogleSignInButton(parentID: string) {
    const google = getGoogleAPI();
    if (!google) {
        return;
    }
    const parent = document.getElementById(parentID);
    if (!parent || parent.childNodes.length > 0) {
        return;
    }
    google.accounts.id.renderButton(parent, {
        width: GOOGLE_SIGN_IN_BUTTON_WIDTH,
    });
}

/*
 * Whether this platform supports google sign in.
 */
export function isGoogleSignIsSupported(): boolean {
    return !!(environment.googleOAuthClientID && isPlatformWeb());
}
