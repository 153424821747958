import { Injectable, NgZone } from '@angular/core';

import {
    ActionPerformed,
    PermissionStatus,
    PushNotificationSchema,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications';

import { Capacitor } from '@capacitor/core';

import { StorageService } from './storage.service';

import { PushService } from './push.service';

import { asNotificationActionData } from './notification-model';

/*
 * Push service implementation for native platforms (android + ios). This uses capacitorjs
 * to interact with the native firebase installation.
 */
@Injectable({
    providedIn: 'root',
})
export class NativePushService extends PushService {
    constructor(storage: StorageService, private ngZone: NgZone) {
        super(storage);
        if (this.isAvailable) {
            this.removeEventListeners();
            this.addEventListeners();
        }
    }

    getDeclined(): Promise<boolean> {
        if (!this.isAvailable) {
            return Promise.resolve(false);
        }
        return NativePushService.pluginCheckPermissions().then((result) => {
            return result.receive === 'denied';
        });
    }

    async getShouldPromptUser(): Promise<boolean> {
        if (this.isAvailable) {
            const status = await PushNotifications.checkPermissions();
            return (
                status.receive === 'prompt' ||
                status.receive === 'prompt-with-rationale'
            );
        }
        return false;
    }

    async getIsAvailable(): Promise<boolean> {
        return this.isAvailable;
    }

    /* TODO - refactor service to use getIsAvailable because that's required
     * by the parent class. */
    get isAvailable(): boolean {
        return Capacitor.getPlatform() !== 'web';
    }

    async register(): Promise<boolean> {
        if (!this.isAvailable) {
            return false;
        }
        const declined = await this.getDeclined();
        if (declined) {
            return false;
        }
        const current = await NativePushService.pluginCheckPermissions();
        if (current.receive === 'granted') {
            await NativePushService.pluginRegister();
            return true;
        }
        const result = await NativePushService.pluginRequestPermissions();
        if (result.receive === 'granted') {
            await NativePushService.pluginRegister();
            return true;
        }
        this.declined.emit();
        return false;
    }

    private addEventListeners() {
        // Triggered on registration success
        PushNotifications.addListener('registration', (token: Token) => {
            console.info('NativePushService: registration success');
            this.ngZone.run(() => {
                this.registered.emit(token.value);
            });
        });

        // Triggered on registration failure
        PushNotifications.addListener('registrationError', (error: any) => {
            console.error(
                'NativePushService: registration error:',
                JSON.stringify(error)
            );
            this.ngZone.run(() => {
                this.registrationError.emit(error);
            });
        });

        // Triggered when notification is received and app is open
        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                this.ngZone.run(() => {
                    this.received.emit(notification);
                });
            }
        );

        // Triggered when a notification is tapped
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (action: ActionPerformed) => {
                const actionData = asNotificationActionData(
                    action.notification.data
                );
                this.ngZone.run(() => {
                    this.actionPerformed.emit({
                        notificationActionData: actionData,
                    });
                });
            }
        );
    }

    private removeEventListeners() {
        PushNotifications.removeAllListeners();
    }

    /*
     * Wrappers for accessing capacitor PushNotification API methods. These are
     * needed to provide a way of mocking the API during test. There's a problem
     * directly spying on methods of PushNotification, where it looks like each
     * module that imports PushNotification gets a reference to a new object.
     * (bottom line is the mocks create in spec are never called here)
     */

    static pluginRequestPermissions(): Promise<PermissionStatus> {
        return PushNotifications.requestPermissions();
    }

    static pluginCheckPermissions(): Promise<PermissionStatus> {
        return PushNotifications.checkPermissions();
    }

    static pluginRegister(): Promise<void> {
        return PushNotifications.register();
    }
}
