/*
 * Code for configuring firebase messaging for JS, as well as the app service worker needed
 * to process push notifications in the background.
 *
 * Note firebase messaging for the native platform (ie Android and iOS) is handled for us
 * by capacitor. This is for the web release of the app only.
 */

import {
    Messaging,
    getMessaging,
    onMessage,
    getToken,
    isSupported,
    MessagePayload,
} from 'firebase/messaging';

import { initializeApp } from 'firebase/app';

import { Build } from '@app/../environments/build';

const FIREBASE_SERVICE_WORKER_URL = '/firebase-messaging-sw.js';
const FIREBASE_SERVICE_WORKER_SCOPE = '/firebase-cloud-messaging-push-scope';

export interface FirebaseConfigurationResult {
    messaging: Messaging;
    serviceWorker: ServiceWorkerRegistration;
}

export class FirebaseMessagingConfigurator {
    messaging: Messaging = null;
    serviceWorker: ServiceWorkerRegistration = null;
    // The list of (promise) callbacks from concurrent calls made to 'configure'
    configureListeners = null;

    constructor(private messageCallback: any) {}

    async isAvailable(): Promise<boolean> {
        return !!(
            Build.SECRETS.FIREBASE_WEB_CONFIG.apiKey && (await isSupported())
        );
    }

    /*
     * Configure firebase messaging and the associated service worker. Note it's safe to
     * call this function multiple times, and concurrently.
     */
    async configure(): Promise<FirebaseConfigurationResult> {
        if (!(await this.isAvailable())) {
            return null;
        }
        if (this.messaging && this.serviceWorker) {
            return {
                messaging: this.messaging,
                serviceWorker: this.serviceWorker,
            };
        }
        if (this.configureListeners) {
            /*
             * Configuration is happening right now so let's add ourselves as a listener.
             * This ensures we only configure firebase and the service worker once.
             */
            return new Promise((accept, reject) => {
                this.configureListeners.push(accept);
            });
        }
        this.configureListeners = [];

        this.serviceWorker = await navigator.serviceWorker.register(
            FIREBASE_SERVICE_WORKER_URL,
            {
                // Note: we can't register in the root scope because ngsw wants that
                scope: FIREBASE_SERVICE_WORKER_SCOPE,
            }
        );
        await this.serviceWorker.update();

        /* See https://firebase.google.com/docs/reference/js */
        initializeApp(Build.SECRETS.FIREBASE_WEB_CONFIG);
        this.messaging = getMessaging();
        // Called when the app is open and has focus
        onMessage(this.messaging, (messagePayload: MessagePayload) => {
            if (this.messageCallback) {
                this.messageCallback(messagePayload);
            }
        });
        const result = {
            messaging: this.messaging,
            serviceWorker: this.serviceWorker,
        };
        // Let everybody who's waiting know that configuration is finished
        this.configureListeners.forEach((accept) => {
            accept(result);
        });
        this.configureListeners = null;
        return result;
    }
}
