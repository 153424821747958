import memoize from 'micro-memoize';

import { Component, OnInit, Input } from '@angular/core';

interface DurationParts {
    minutesPart: number;
    hoursPart: number;
}

/* Converts a number of minutes into the equivalent hours and minutes suitable
 * to be rendered as: HH hours MM minutes */
export function getDurationParts(minutes: number): DurationParts {
    const minutesPart = minutes % 60;
    // Note: using parseInt makes this work with negative values (needed by
    // the duration popover component)
    const hoursPart = parseInt('' + minutes / 60);
    return {
        minutesPart,
        hoursPart,
    };
}

/* Returns a (short) descriptive duration string like: 5h 30m */
export function getDurationShortDesc(
    minutes: number,
    after: string = ''
): string {
    // See https://www.pivotaltracker.com/story/show/184512615/comments/235595632
    if (isNaN(minutes)) {
        return '';
    }
    const { minutesPart, hoursPart } = getDurationParts(minutes);
    const post = after ? ' ' + after : '';

    if (hoursPart === 0) {
        return `${minutesPart}m${post}`;
    }
    if (minutesPart === 0) {
        return `${hoursPart}h${post}`;
    }
    return `${hoursPart}h ${minutesPart}m${post}`;
}

/* Returns a longer form duration string like: 5 hours 30 minutes */
export function getDurationLongDesc(
    minutes: number,
    after: string = ''
): string {
    // See https://www.pivotaltracker.com/story/show/184512615/comments/235595632
    if (isNaN(minutes)) {
        return '';
    }
    const { minutesPart, hoursPart } = getDurationParts(minutes);
    const minutesToken = Math.abs(minutesPart) > 1 ? 'minutes' : 'minutes';
    const hoursToken = Math.abs(hoursPart) > 1 ? 'hours' : 'hour';
    const post = after ? ' ' + after : '';

    if (hoursPart === 0) {
        return `${minutesPart} ${minutesToken}${post}`;
    }
    if (minutesPart === 0) {
        return `${hoursPart} ${hoursToken}${post}`;
    }
    return `${hoursPart} ${hoursToken} ${minutesPart} ${minutesToken}${post}`;
}

@Component({
    selector: 'app-duration',
    templateUrl: './duration.component.html',
    styleUrls: ['./duration.component.scss'],
})
export class DurationComponent implements OnInit {
    @Input()
    minutes: number = 0;

    @Input()
    after: string = '';

    // Note: we cache these per-component instance, since there could be
    // multiple duration descriptions on a page.
    getDurationShortDescCached = memoize(getDurationShortDesc);
    getDurationLongDescCached = memoize(getDurationLongDesc);

    constructor() {}

    get shortDesc(): string {
        return this.getDurationShortDescCached(this.minutes, this.after);
    }

    get longDesc(): string {
        return this.getDurationLongDescCached(this.minutes, this.after);
    }

    ngOnInit() {}
}
