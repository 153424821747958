import memoize from 'micro-memoize';

import { Injectable } from '@angular/core';

import { DateTime, Interval } from 'luxon';

import {
    WeeklySummary,
    WeeklySummaryRef,
    sortedByStartTime,
    makeWeeklySummaryFromExisting,
} from './summary-model';

import {
    Activity,
    ActivityWeeklyCacheService,
} from './activity-weekly-cache.service';

import { getWeekStart, getWeekEnd } from '@app/date-utils';

export type WeeklySummaryByWeek = { [weekStartISO: string]: WeeklySummary };

/*
 * Returns a list of summaries that overlap with a given year.
 */
function filterSummariesByYear(
    summaries: readonly WeeklySummary[],
    date: DateTime
): readonly WeeklySummary[] {
    const year = date.year;
    const summariesByYear = summaries.filter(
        (summary) =>
            summary.interval.start.year === year ||
            summary.interval.end.year === year
    );
    return summariesByYear;
}

/*
 * Flattens a weekly summary map and returns the list sorted by week date in
 * descending order.
 */
function getSummariesFromMap(
    summariesByWeek: WeeklySummaryByWeek
): readonly WeeklySummary[] {
    return sortedByStartTime(Object.values(summariesByWeek)).slice().reverse();
}

const filterSummariesByYearCached = memoize(filterSummariesByYear);
const getSummariesFromMapCached = memoize(getSummariesFromMap);

/*
 * Manages a cache of WeeklySummary objects, keyed by week (start)
 */
@Injectable({
    providedIn: 'root',
})
export class WeeklySummaryCacheService {
    private summariesByWeek: WeeklySummaryByWeek = {};
    isValid: boolean = false;

    constructor(private activityCache: ActivityWeeklyCacheService) {
        this.activityCache.cacheUpdate.subscribe((weekStart: DateTime) => {
            const activities = this.activityCache.getWeek(weekStart);
            const iso = weekStart.toISODate();
            const existing = this.summariesByWeek[iso];
            if (existing) {
                // Create a whole new cache object so we don't break the
                // summaries getter below.
                this.summariesByWeek = {
                    ...this.summariesByWeek,
                };
                this.summariesByWeek[iso] = makeWeeklySummaryFromExisting(
                    existing,
                    activities
                );
            }
        });
    }

    /*
     * The list of cached WeeklySummary objects, sorted by start date in
     * descending order.
     */
    get summaries(): readonly WeeklySummary[] {
        return getSummariesFromMapCached(this.summariesByWeek);
    }

    /*
     * Replace the contents of this cache
     */
    set(summaries: readonly WeeklySummary[]): readonly WeeklySummary[] {
        this.summariesByWeek = {};
        summaries.forEach((summary) => {
            this.summariesByWeek[summary.weekStartISO] = summary;
        });
        this.isValid = true;
        return this.summaries;
    }

    /*
     * Returns the WeeklySummary that starts on the given date, or null
     */
    getWeek(weekStart: DateTime): WeeklySummaryRef {
        const iso = weekStart.toISODate();
        return this.summariesByWeek[iso] || null;
    }

    /*
     * Returns the WeeklySummaries for a specific year
     */
    getYear(date: DateTime): readonly WeeklySummary[] {
        return filterSummariesByYearCached(this.summaries, date);
    }

    /*
     * Clear and invalidate the contents of this cache
     */
    clear(): Promise<void> {
        this.summariesByWeek = {};
        this.isValid = false;
        return Promise.resolve();
    }
}
