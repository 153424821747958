import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { UrlService, CannotBuildUrl } from './url.service';

import {
    APIError,
    InvalidServerResponse,
    isNoProfileError,
    getAPIError,
} from './error';

export interface Commitment {
    readonly id: number;
    readonly goalStatement: string;
    // Note: total minutes (not per activity)
    readonly minutes: number;
    readonly activities: number;
    // Note: these are dates, not date-times
    readonly startDateISO: string;
    readonly endDateISO: string;
    readonly isOngoing: boolean;
}

export interface CommitmentDetails {
    readonly goalStatement: string;
    readonly minutes: number;
    readonly activities: number;
}

export interface CommitmentOverview {
    readonly current: CommitmentRef;
    readonly next: CommitmentRef;
}

export type CommitmentRef = Commitment | null;
export type CommitmentOverviewRef = CommitmentOverview | null;

export function isCommitment(data: unknown): data is Commitment {
    const commitment = data as Commitment;
    return !!(
        commitment &&
        (commitment.id === undefined || typeof commitment.id === 'number') &&
        typeof commitment.goalStatement === 'string' &&
        typeof commitment.minutes === 'number' &&
        typeof commitment.activities === 'number' &&
        typeof commitment.startDateISO === 'string' &&
        typeof commitment.endDateISO === 'string' &&
        typeof commitment.isOngoing === 'boolean'
    );
}

/* Attempt to interpret data as returned by the API server as a commitment
 * object, otherwise returns null. */
export function asCommitment(data: any): CommitmentRef {
    if (!data) {
        return null;
    }
    const commitment = {
        id: data.id,
        goalStatement: data.goal_statement,
        minutes: data.minutes,
        activities: data.activities,
        startDateISO: data.start_date,
        endDateISO: data.end_date,
        isOngoing: data.is_ongoing,
    };
    if (!isCommitment(commitment)) {
        return null;
    }
    return commitment;
}

/* Attempt to interpret the data, as returned by the API server, as a commitment
 * overview structure. (an array of exactly two elements, either element null
 * or a server commitment object) */
export function asCommitmentOverview(data: any): CommitmentOverviewRef {
    if (!Array.isArray(data)) {
        return null;
    }
    if (data.length !== 2) {
        return null;
    }
    const first = data[0];
    const second = data[1];
    if ((first && !asCommitment(first)) || (second && !asCommitment(second))) {
        return null;
    }
    return {
        current: asCommitment(first),
        next: asCommitment(second),
    };
}

export class CommitmentCache {
    overview: CommitmentOverviewRef = null;

    get isValid(): boolean {
        return !!this.overview;
    }

    replace(overview: CommitmentOverview) {
        this.overview = overview;
    }

    created(commitment: Commitment) {
        // TODO - cache if creating the first commitment
        this.clear();
    }

    updated(commimtment: Commitment) {
        // TODO - cache if updating the next commitment
        this.clear();
    }

    clear(): Promise<void> {
        this.overview = null;
        return Promise.resolve();
    }
}

@Injectable({
    providedIn: 'root',
})
export class CommitmentService {
    cache: CommitmentCache = new CommitmentCache();

    temporaryCommitment: CommitmentDetails;

    constructor(private urlService: UrlService, private http: HttpClient) {}

    /*
     * Methods for storing the temporary commitment for sharing within pages
     * of the app before it is turned into a real commitment and submitted
     * to the server.
     */

    get tempCommitment(): CommitmentDetails {
        return this.temporaryCommitment;
    }

    set tempCommitment(value: CommitmentDetails) {
        this.temporaryCommitment = value;
    }

    /*
     * Creates a commitment on the server and returns the new object via
     * promise. This will create the current commitment if the client doesn't
     * already have one, or the next commitment (takes effect on the following
     * Monday) if the client doesn't have a next commitment.
     */
    async create(commitment: CommitmentDetails): Promise<Commitment> {
        const url = await this.urlService.getNextCommitmentUrl();
        const body = {
            goal_statement: commitment.goalStatement,
            minutes: commitment.minutes,
            activities: commitment.activities,
        };
        return this.http
            .post(url, body)
            .toPromise()
            .then((payload) => {
                const newCommitment = asCommitment(payload);
                if (!newCommitment) {
                    throw InvalidServerResponse(payload);
                }
                return newCommitment;
            })
            .catch((error) => {
                throw getAPIError(error);
            })
            .then((commitment) => {
                this.cache.created(commitment);
                return commitment;
            });
    }

    /*
     * Fetch the currently active commitment. Note: this caches the result for
     * future lookups.
     */
    fetchCurrent(): Promise<CommitmentRef> {
        return this.fetchOverview().then((overview) => {
            return overview.current;
        });
    }

    /*
     * Fetches the current and next commitments and returns them via promise.
     * Note: this caches the result for future lookups.
     */
    async fetchOverview(): Promise<CommitmentOverview> {
        if (this.cache.isValid) {
            return Promise.resolve(this.cache.overview);
        }
        const url = await this.urlService.getCommitmentOverviewUrl();
        return this.http
            .get(url)
            .toPromise()
            .then((payload) => {
                const overview = asCommitmentOverview(payload);
                if (!overview) {
                    throw InvalidServerResponse(payload);
                }
                return overview;
            })
            .catch((response) => {
                const apiError = getAPIError(response);
                /* If the user has the client role, but hasn't created their
                 * profile this endpoint will return 400 (BAD DATA). So we'll
                 * be nice and tell the caller no commitments rather than
                 * asking them to deal with the error. */
                if (isNoProfileError(apiError)) {
                    return {
                        current: null,
                        next: null,
                    };
                }
                throw apiError;
            })
            .then((overview) => {
                this.cache.replace(overview);
                return overview;
            });
    }

    /* Updates the commitment record in place and returns the new object via
     * promise. */
    async update(id: number, data: CommitmentDetails): Promise<Commitment> {
        const url = await this.urlService.getCommitmentUrl(id);
        const body = {
            id: id,
            goal_statement: data.goalStatement,
            minutes: data.minutes,
            activities: data.activities,
        };
        return this.http
            .put(url, body)
            .toPromise()
            .then((payload) => {
                const commitment = asCommitment(payload);
                if (!commitment) {
                    throw InvalidServerResponse(payload);
                }
                return commitment;
            })
            .catch((error) => {
                throw getAPIError(error);
            })
            .then((commitment) => {
                this.cache.updated(commitment);
                return commitment;
            });
    }

    /* Called to clear this service of any cached data */
    clear(): Promise<void> {
        return this.cache.clear();
    }

    /* Returns the cached current commitment, if any */
    getCachedCurrent(): CommitmentRef {
        if (this.cache.isValid && this.cache.overview) {
            return this.cache.overview.current;
        }
        return null;
    }

    /* Returns the cached upcoming commitment, if any */
    getCachedNext(): CommitmentRef {
        if (this.cache.isValid && this.cache.overview) {
            return this.cache.overview.next;
        }
        return null;
    }

    /* Returns the cached commitment overview, if any */
    getCachedOverview(): CommitmentOverviewRef {
        return this.cache.overview;
    }
}
