// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Selects the component itself */
:host {
  display: inline-block;
  padding: 1em;
}

button {
  padding: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/widget/widget.component.scss"],"names":[],"mappings":"AAAA,iCAAA;AACA;EACI,qBAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":["/* Selects the component itself */\n:host {\n    display: inline-block;\n    padding: 1em;\n}\n\nbutton {\n    padding: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
