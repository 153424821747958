import { DateTime, Settings as LuxonSettings } from 'luxon';

import { Component, NgZone } from '@angular/core';

import { Router } from '@angular/router';

import { NotificationActionService } from '@app/services/notification-action.service';

import { PushService } from './services/push.service';

import { getCurrentLocale } from '@app/date-utils';

import { LoggedOutPage } from './pages/logged-out/logged-out.page';

import { environment } from '@app/../environments/environment';

/*
 * Override console.warn and capture ionic warnings about legacy form elements,
 * but allow the first warning to log. (and silences the rest) Note these
 * warnings are disabled in production.
 * See https://www.pivotaltracker.com/story/show/184970727/comments/236443255
 */
function handleIonicLegacyFormWarnings() {
    const oldWarn = console.warn;
    let warnedAboutLegacyFormElements = false;
    console.warn = (msg, ...params) => {
        if (
            msg.startsWith &&
            msg.startsWith('[Ionic Warning]:') &&
            msg.indexOf('now requires providing a label with either the') !== -1
        ) {
            if (warnedAboutLegacyFormElements || environment.production) {
                return;
            }
            warnedAboutLegacyFormElements = true;
        }
        oldWarn(msg, ...params);
    };
}

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    /* Note we include the notification action service here, though this
     * component doesn't actually need it, so the service can setup event
     * listeners etc and respond to notification-related events */
    constructor(
        private actionService: NotificationActionService,
        private pushService: PushService,
        ngZone: NgZone,
        router: Router
    ) {
        /*
         * Prevent browsers from automatically suggesting the user install
         * the app, so we can better control the install experience.
         * See https://www.pivotaltracker.com/story/show/186042588/comments/238709250
         */
        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
        });

        /*
         * Sigh. There's a bug in chrome/chromium where the locale en-CA is
         * converted into en-GB. The bug was opened in 2018 and never fixed,
         * see https://bugs.chromium.org/p/chromium/issues/detail?id=874225
         * So we'll use this hack for now which will at least preserve other
         * locale preferences.
         *
         * Updated: the above issue was actually closed in 2020, but this bug
         * report references the fact that system locale en-CA is still being
         * translated into en-GB in chrome linux:
         * https://bugs.chromium.org/p/chromium/issues/detail?id=1072911
         */
        if (getCurrentLocale() === 'en-GB') {
            LuxonSettings.defaultLocale = 'en-CA';
        }

        /*
         * Another big sigh. Ionic v7 introduced a new way of handling inputs
         * and labels that's very incompatible with our CSS. I've enabled
         * legacy mode but that results in warning spam which this reduces.
         */
        handleIonicLegacyFormWarnings();

        /*
         * Configure the push service if it looks like push was previously enabled.
         * Otherwise it gets auto-configured later when/if the user enables push.
         */
        this.pushService
            .getToken()
            .then((token) => {
                if (token) {
                    return this.pushService.configure();
                }
            })
            .catch((error) => {
                console.error(
                    'AppComponent: error registering push service:',
                    error
                );
            });

        /*
         * Setup some infrastructure for our integration tests.
         */
        if (!environment.production) {
            /* Used to mock the current datetime for some integration tests
             * (see PageObject.mockDateTime) */
            window['appSetDateTime'] = function (nowISO) {
                DateTime.now = () => DateTime.fromISO(nowISO);
            };
            /* This exposes the router navigation machinery to our integration
             * tests. It's used to navigate directly to pages within the app
             * without triggering a page reload.
             * (see https://www.pivotaltracker.com/story/show/184187271) */
            window['appNavigateByUrl'] = function (path) {
                ngZone.run(() => {
                    router.navigateByUrl(path);
                });
            };
            /* Set the email to use when running the oauth2 sign in demo. This
             * is used by tests that want to generate an invite for a specific
             * email address and have that address be used in the oauth demo.
             * (as opposed to having the demo code pick a random email) */
            window['appSetDemoOAuthEmail'] = LoggedOutPage.setDemoOAuthEmail;
        }
    }
}
