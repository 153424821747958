/*
 * The pro or client response to a connection request.
 * Note: these values must be synchronized with the API endpoint
 */
export enum ConnectionResponse {
    ACCEPT = 'ACCEPT',
    DECLINE = 'DECLINE',
    NO_RESPONSE = 'NONE',
}

export enum ConnectionContextType {
    SHARE = 'SHARE',
    TRANSFER = 'TRANSFER',
}

export interface ConnectionProDetails {
    id: number;
    name: string;
    localProProfileID: number;
    // Whether the pro is support pro
    isSupportPro: boolean;
}

export interface ConnectionContext {
    type: string;
    pro: ConnectionProDetails;
}

export type ConnectionContextRef = ConnectionContext | null;

export interface Connection {
    id: number;
    pro: ConnectionProDetails;
    createdISO: string;
    // When the connection was established, or empty string
    connectedISO: string;
    disconnectedISO: string;
    proResponse: ConnectionResponse;
    clientResponse: ConnectionResponse;
    // Whether both pro and client have accepted
    isConnected: boolean;
    // Whether the pro has accepted and the client hasn't responded
    isWaitingOnClient: boolean;
    // Whether the client has accepted and the pro hasn't yet responded
    isWaitingOnPro: boolean;
    context: ConnectionContextRef;
    isPendingDisconnection: boolean;
    pendingDisconnectionISO: string;
}

export type ConnectionRef = Connection | null;

export function isConnectionProDetails(
    data: unknown
): data is ConnectionProDetails {
    const details = data as ConnectionProDetails;
    return !!(
        details &&
        typeof details.id === 'number' &&
        typeof details.name === 'string' &&
        typeof details.localProProfileID === 'number' &&
        typeof details.isSupportPro === 'boolean'
    );
}

export function isConnectionContext(data: unknown): data is ConnectionContext {
    const context = data as ConnectionContext;
    return !!(
        context &&
        typeof context.type === 'string' &&
        isConnectionProDetails(context.pro)
    );
}

export function isConnection(data: unknown): data is Connection {
    const connection = data as Connection;
    return !!(
        connection &&
        isConnectionProDetails(connection.pro) &&
        typeof connection.id === 'number' &&
        typeof connection.createdISO === 'string' &&
        typeof connection.proResponse === 'string' &&
        typeof connection.clientResponse === 'string' &&
        typeof connection.isConnected === 'boolean' &&
        typeof connection.isWaitingOnClient === 'boolean' &&
        typeof connection.isWaitingOnPro === 'boolean' &&
        typeof connection.isPendingDisconnection === 'boolean' &&
        typeof connection.connectedISO === 'string' &&
        typeof connection.disconnectedISO === 'string' &&
        typeof connection.pendingDisconnectionISO === 'string' &&
        (connection.context === null || isConnectionContext(connection.context))
    );
}

export function isConnectionArray(data: unknown): data is Connection[] {
    return !!(data && Array.isArray(data) && data.every(isConnection));
}

export function asConnection(data: any): ConnectionRef {
    function makeProDetails(data) {
        return {
            id: data.id,
            name: data.name,
            localProProfileID: data.local_pro_profile_id ?? 0,
            isSupportPro: data.is_ggf_support_pro,
        };
    }

    if (!data) {
        return null;
    }
    if (!data.pro) {
        return null;
    }
    const pro = makeProDetails(data.pro);
    let context = null;
    if (data.context) {
        context = {
            type: data.context.type,
            pro: makeProDetails(data.context.pro),
        };
    }
    const connection = {
        id: data.id,
        pro: pro,
        createdISO: data.created,
        proResponse: data.pro_response,
        clientResponse: data.client_response,
        isConnected: !!(data.connected_iso && !data.disconnected_iso),
        isWaitingOnClient:
            data.pro_response === ConnectionResponse.ACCEPT &&
            data.client_response === ConnectionResponse.NO_RESPONSE,
        isWaitingOnPro:
            data.pro_response === ConnectionResponse.NO_RESPONSE &&
            data.client_response === ConnectionResponse.ACCEPT,
        isPendingDisconnection: data.is_pending_disconnection,
        pendingDisconnectionISO: data.pending_disconnection_iso,
        connectedISO: data.connected_iso,
        disconnectedISO: data.disconnected_iso,
        context: context,
    };
    if (!isConnection(connection)) {
        return null;
    }
    return connection;
}

export function asConnectionArray(data: any): Connection[] | null {
    if (!Array.isArray(data)) {
        return null;
    }
    const connections = data.map(asConnection);
    if (!isConnectionArray(connections)) {
        return null;
    }
    return connections;
}

/*
 * Whether both parties have consented to be connected to each other
 */
export function isConnectionComplete(connection: Connection): boolean {
    return (
        connection.clientResponse == ConnectionResponse.ACCEPT &&
        connection.proResponse == ConnectionResponse.ACCEPT
    );
}

/*
 * Whether the client is connected to a pro given one or multiple connection
 * objects. If proID is given, this function checks if the client is connected
 * to the given pro.
 */
export function isConnected(
    connection: Connection | readonly Connection[],
    proID?: number
): boolean {
    let connections = [];
    if (Array.isArray(connection)) {
        connections = connection;
    } else {
        connections = [connection];
    }
    if (proID !== undefined) {
        const found = connections.find(
            (connection) => proID === connection.pro.id
        );
        if (!found) {
            return false;
        }
        return isConnectionComplete(found);
    }
    return connections.some(isConnectionComplete);
}

export function isNoClientResponse(connection: Connection): boolean {
    return connection.clientResponse === ConnectionResponse.NO_RESPONSE;
}

/*
 * Returns a mapping of pro ID to connection object.
 */
export function getConnectionMap(
    connections: readonly Connection[]
): Map<number, Connection> {
    const m = new Map();
    connections.forEach((connection) => {
        m.set(connection.pro.id, connection);
    });
    return m;
}

export function getConnectionContextDescription(
    connection: Connection
): string {
    const targetName = connection.pro.name;
    if (connection.context) {
        const contextName = connection.context.pro.name;
        if (connection.context.type === ConnectionContextType.SHARE) {
            return `${contextName} would like to connect you with the Pro ${targetName} to better support your needs and goals.`;
        }
        if (connection.context.type === ConnectionContextType.TRANSFER) {
            return `${contextName} would like to transfer your account to the Pro ${targetName} to better support your needs and goals.`;
        }
    }
    return `${targetName} would like to connect with you to support your needs and goals.`;
}

/*
 * Whether the given list of connection requests has one that is waiting on the
 * client to accept for a particular pro.
 */
export function hasWaitingOnClientConnection(
    connections: readonly Connection[],
    proID: number
): boolean {
    const connection = connections.find(
        (connection) =>
            connection.pro.id === proID && connection.isWaitingOnClient
    );
    return !!connection;
}
