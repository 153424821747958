import { Environment } from './type';

export const environment: Environment = {
    production: false,
    serverUrls: {
        CA: 'https://apistaging.goget.fit/',
        US: 'https://apistaging.goget.fit/',
        AUS: 'https://apistaging.goget.fit/',
    },
    googleOAuthClientID:
        '787677219477-9reoc7hsnkgsa4p7669c93crpk53cnni.apps.googleusercontent.com',
    demoOAuthSSOTokenPrefix: 'demo-oauth-sso-token-',
    demoMagicSignInCode: 'STAGES',
    firebaseVapidKey:
        'BIM5YTeccrUluDJay6glZz8feJd27FuNQsUtMD9xHAzx4s-PQykaY6SL6QH1ILfEuo6doAjzyAk7sdwMN42mWsw',
};
