import { Injectable, EventEmitter } from '@angular/core';

import { StorageService, StorageKey } from './storage.service';

import { NotificationActionData } from './notification-model';

export interface PushServiceActionPerformed {
    // Additional data that was included in the push payload
    notificationActionData: NotificationActionData;
}

export class PushService {
    // Triggered when registration is successful. (subclasses should emit this event)
    registered: EventEmitter<string> = new EventEmitter();
    // Triggered when attempting a registration that subsequently fails
    registrationError: EventEmitter<any> = new EventEmitter();
    // Triggered when a notification is received while the app is open
    received: EventEmitter<any> = new EventEmitter();
    // Triggered when a notification is tapped (eg in the mobile notification area)
    actionPerformed: EventEmitter<PushServiceActionPerformed> =
        new EventEmitter();
    // Triggered when the user declines permission to receive notifications
    declined: EventEmitter<void> = new EventEmitter();

    /* The cached firebase token */
    protected cachedToken: string = '';
    /* Whether the cached data is valid */
    protected isCacheValid: boolean = false;

    constructor(private storage: StorageService) {
        this.registered.subscribe((token: string) => {
            this.saveToken(token);
        });
    }

    async getIsAvailable(): Promise<boolean> {
        return false;
    }

    /*
     * Called after the app has intialized
     */
    async configure(): Promise<void> {}

    /*
     * Loads the push token from storage and returns via promise. (caching the
     * token if present) If no token is stored returns an empty string in it's
     * place.
     */
    getToken(): Promise<string> {
        if (this.isCacheValid) {
            return Promise.resolve(this.cachedToken);
        }
        return this.storage.load(StorageKey.PUSH).then((token) => {
            this.cachedToken = token;
            this.isCacheValid = true;
            return token;
        });
    }

    /*
     * Whether push notifications were previously declined. Note if this is
     * false it doesn't necessarily mean the user has granted permission.
     */
    getDeclined(): Promise<boolean> {
        return Promise.resolve(false);
    }

    /*
     * Whether the user needs to be prompted to grant permission to receive push
     * notifications. If this function returns true that means when the app
     * requests permission to receive notifications the OS will prompt the user
     * to confirm. So this function should be used to present an in-app banner
     * asking users to opt-into notifications.
     */
    async getShouldPromptUser(): Promise<boolean> {
        return false;
    }

    /*
     * Saves a push token to storage. Returns a promise that resolves when
     * complete.
     */
    protected saveToken(token: string): Promise<void> {
        this.cachedToken = token;
        return this.storage.save(StorageKey.PUSH, token).then(() => {
            this.isCacheValid = true;
        });
    }

    /*
     * Request permission to receive push notifications, and return the result
     * via promise. If push is unavailable, or the user has previously declined
     * permission then this always returns false. (via promise)
     */
    async register(): Promise<boolean> {
        return false;
    }

    async clear(): Promise<void> {
        this.isCacheValid = false;
        this.cachedToken = '';
        return this.storage.remove(StorageKey.PUSH);
    }
}
