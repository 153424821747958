export const EVENT_TYPE_NOTIFICATION_ACTION = 'ggf-notification-action';

/*
 * The "action" payload sent from the API server. This determines what happens when the
 * user taps on a notification.
 *
 * For android and ios notifications this is also the FCM notification "data" payload
 * object. For web push notifications this is part of the "data" payload object. (along
 * with title, body)
 */
export interface NotificationActionData {
    // This indicates what "state" the app should move to when actioning a
    // push notification. (eg. "tab.messages") The name "state" is from the
    // legacy app that used angular/uirouter to manage route states.
    stateName: string;
    // Additional state parameters for context
    stateParams: any;
    // The message (ID) of interest, if not zero
    messageId: number;
    // The activity (ID) of interest, if not zero
    workoutId: number;
}

/* Parse out the server payload from a push notification */
export function asNotificationActionData(data: any): NotificationActionData {
    function asInt(value: any) {
        if (parseInt(value)) {
            return value;
        }
        return 0;
    }
    const stateName = data?.state_name ?? '';
    const stateParams = parseStateParams(data?.state_params);
    const messageId = asInt(stateParams['messageId']);
    const workoutId = asInt(stateParams['workoutId']);
    return {
        stateName: stateName,
        stateParams: stateParams,
        messageId: messageId,
        workoutId: workoutId,
    };
}

export function parseStateParams(data: any): any {
    try {
        return JSON.parse(data) ?? {};
    } catch {
        return {};
    }
}

/*
 * Returns the URL (parts) that should be visited given the notification
 * action performed. (eg tapping a support message notification)
 */
export function getNotificationActionUrl(
    actionData: NotificationActionData
): string {
    if (!actionData) {
        console.error('getNotificationActionUrl: actionData is null');
        return '/tabs/schedule';
    }
    if (actionData.stateParams && actionData.messageId) {
        // Navigate directly to the message
        return `/tabs/messages/${actionData.messageId}`;
    } else if (actionData.stateName === 'tab.messages') {
        // Notification about a message, but we don't have an ID
        return '/tabs/messages';
    } else if (actionData.stateParams && actionData.workoutId) {
        // Notification regarding a particular workout (aka activity)
        // TODO - check for current week
        return `/activity/${actionData.workoutId}`;
    }
    // Otherwise probably something to do with the schedule
    return '/tabs/schedule';
}
