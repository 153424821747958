// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover-body {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
}
.popover-body div.popover-column {
  display: flex;
  flex-direction: column;
  width: 33.3%;
  margin: 0 auto;
}
.popover-body div.popover-column ion-button {
  text-align: center;
  font-size: 1em;
  margin: 0.2em;
  height: unset;
  --padding-start: 0px;
  --padding-end: 0px;
}
.popover-body div.popover-column p {
  font-size: 1.3em;
  font-family: var(--app-bold-font);
  text-align: center;
  padding: 0.3em;
}

section ion-button {
  font-size: 1em;
}

.duration-note {
  display: block;
  margin: auto;
  margin: 0em 0.8em 0.2em 0.8em;
  font-size: smaller;
}`, "",{"version":3,"sources":["webpack://./src/app/components/duration-popover/duration-popover.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,cAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;AACR;AACQ;EACI,kBAAA;EACA,cAAA;EACA,aAAA;EACA,aAAA;EACA,oBAAA;EACA,kBAAA;AACZ;AACQ;EACI,gBAAA;EACA,iCAAA;EACA,kBAAA;EACA,cAAA;AACZ;;AAKI;EACI,cAAA;AAFR;;AAMA;EACI,cAAA;EACA,YAAA;EACA,6BAAA;EACA,kBAAA;AAHJ","sourcesContent":[".popover-body {\n    display: flex;\n    flex-direction: row;\n    padding: 0.5em;\n\n    div.popover-column {\n        display: flex;\n        flex-direction: column;\n        width: 33.3%;\n        margin: 0 auto;\n\n        ion-button {\n            text-align: center;\n            font-size: 1em;\n            margin: 0.2em;\n            height: unset;\n            --padding-start: 0px;\n            --padding-end: 0px;\n        }\n        p {\n            font-size: 1.3em;\n            font-family: var(--app-bold-font);\n            text-align: center;\n            padding: 0.3em;\n        }\n    }\n}\n\nsection {\n    ion-button {\n        font-size: 1em;\n    }\n}\n\n.duration-note {\n    display: block;\n    margin: auto;\n    margin: 0em 0.8em 0.2em 0.8em;\n    font-size: smaller;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
