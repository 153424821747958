import { Injectable } from '@angular/core';

import REGIONS_DATA from '../../assets/regions.json';

export const DEFAULT_COUNTRY_CODE = 'CA';

export interface Province {
    code: string;
    name: string;
    cities: string[];
}

export interface Country {
    code: string;
    name: string;
    provinceIsCalled: string;
    provinces: Province[];
}

export type CountryRef = Country | null;
export type ProvinceRef = Province | null;

type CountryMap = { [code: string]: Country };
type ProvinceMap = { [code: string]: Province };
type CityMap = { [code: string]: string[] };

function makeProvinceMapKey(country: string, province: string): string {
    return country + '-' + province;
}

@Injectable({
    providedIn: 'root',
})
export class RegionService {
    private countryList: Country[] = REGIONS_DATA;
    private countryByCode: CountryMap = {};
    private provinceByKey: ProvinceMap = {};
    private cityList: CityMap = {};

    constructor() {
        for (let country of this.getAppSupportedCountries()) {
            this.countryByCode[country.code] = country;
            for (let province of this.getProvinces(country.code)) {
                const key = makeProvinceMapKey(country.code, province.code);
                this.provinceByKey[key] = province;
            }
        }
    }

    /* Returns a list of countries where the user may register with GGF */
    getAppSupportedCountries(): Country[] {
        return this.countryList;
    }

    /* Whether or not the given country code is supported by this app */
    isAppSupportedCountry(countryCode: string) {
        return this.countryList.some((country) => country.code === countryCode);
    }

    getCountry(code: string): CountryRef {
        const country = this.countryByCode[code];
        if (!country) {
            return null;
        }
        return country;
    }

    getProvinces(code: string): Province[] {
        const region = this.getCountry(code);
        if (!region) {
            return [];
        }
        return region.provinces;
    }

    getProvince(country: string, province: string): ProvinceRef {
        const key = makeProvinceMapKey(country, province);
        const provinceObj = this.provinceByKey[key];
        return provinceObj || null;
    }

    getCitiesByCountry(countryCode: string): string[] {
        if (!countryCode) {
            return [];
        }
        if (!this.cityList[countryCode]) {
            const country = this.getCountry(countryCode);
            if (!country) {
                return [];
            }
            const cities = country.provinces.map((province) => province.cities);
            const list = [].concat(...cities);
            this.cityList[countryCode] = list;
            return list;
        }
        return this.cityList[countryCode];
    }

    getProvinceCodeByCity(city: string, countryCode: string): string {
        const country = this.getCountry(countryCode);
        if (!country) {
            return '';
        }
        const province = country.provinces.find((province) => {
            return province.cities.includes(city);
        });
        if (!province) {
            return '';
        }
        return province.code;
    }

    /*
     * Attempt to normalize the given string as a city by finding an exact city
     * name match, but ignoring the character case.
     */
    getNormalizedCityName(countryCode: string, text: string): string {
        const found = this.getCitiesByCountry(countryCode).find((city) => {
            return city.toLowerCase() === text.toLowerCase();
        });
        return found || text;
    }
}
