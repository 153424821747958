import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-post-activity-score',
    templateUrl: './post-activity-score.component.html',
    styleUrls: ['./post-activity-score.component.scss'],
})
export class PostActivityScoreComponent implements OnInit {
    @Input() labels: string[];
    @Input() value: number;
    constructor() {}

    get avgValue(): number {
        return (this.value * 100) / 5;
    }
    ngOnInit() {}
}
