import { Observable } from 'rxjs';

import { timeout } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpEventType,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';

// How long (default) to wait until a network request resolves before giving up
const DEFAULT_TIMEOUT = 10000;

/* Automatically cancels network requests if they take too long */
@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
    constructor() {}

    /* Returns the request timeout (in ms) for the given HTTP request */
    getTimeoutFor(request: HttpRequest<any>) {
        // Note: we can check request.url if we need to deviate from the default
        return DEFAULT_TIMEOUT;
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(timeout(this.getTimeoutFor(request)));
    }
}
