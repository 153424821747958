/*
 * Manages pro-client-connection objects on the server. A connection can either
 * be full, or partial. A full connection means that both the pro and client
 * have consented to the connection. (the client agrees to share their data
 * with the pro, and the pro agrees to help manage the client) A partial
 * connection means only one of the parties has consented.
 */

import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { UrlService, CannotBuildUrl } from './url.service';

import { APIError, InvalidServerResponse, getAPIError } from './error';

import { ConnectionCache } from './connection-cache';

import {
    ConnectionResponse,
    Connection,
    ConnectionRef,
    isConnection,
    asConnection,
    isConnectionArray,
    asConnectionArray,
    isConnectionComplete,
    isConnected,
    isNoClientResponse,
    getConnectionMap,
    hasWaitingOnClientConnection,
} from './connection-model';

export {
    ConnectionResponse,
    Connection,
    ConnectionRef,
    isConnection,
    asConnection,
    isConnectionArray,
    asConnectionArray,
    isConnectionComplete,
    isConnected,
    isNoClientResponse,
    getConnectionMap,
    hasWaitingOnClientConnection,
};

@Injectable({
    providedIn: 'root',
})
export class ConnectionService {
    constructor(
        private http: HttpClient,
        private urlService: UrlService,
        private cache: ConnectionCache
    ) {}

    /*
     * Create a connection between the client and the given pro. The connection
     * request is automatically marked as accepted by the client.
     */
    async create(proID: number): Promise<Connection> {
        const url = await this.urlService.getConnectionUrl();
        return this.http
            .post(url, {
                pro: {
                    id: proID,
                },
            })
            .toPromise()
            .then((payload) => {
                const connection = asConnection(payload);
                if (!connection) {
                    throw InvalidServerResponse(payload);
                }
                this.cache.add(connection);
                return connection;
            })
            .catch((response: HttpErrorResponse) => {
                throw getAPIError(response);
            });
    }

    /*
     * Fetch a list of all active connection objects from the API server. Note
     * the connections may be either full or partial. This function will cache
     * the result and return it on subsequent calls.
     */
    async fetch(): Promise<readonly Connection[]> {
        if (this.cache.isValid) {
            return Promise.resolve(this.cache.connections);
        }
        const url = await this.urlService.getConnectionUrl();
        return this.http
            .get(url)
            .toPromise()
            .then((payload) => {
                const connections = asConnectionArray(payload);
                if (!connections) {
                    throw InvalidServerResponse(payload);
                }
                this.cache.replaceAll(connections);
                return connections;
            })
            .catch((response: HttpErrorResponse) => {
                throw getAPIError(response);
            });
    }

    /*
     * Marks the connection (by pro ID) as accepted. Returns the new connection
     * object via promise.
     */
    async accept(proID: number): Promise<Connection> {
        const url = await this.urlService.getConnectionUrl(proID);
        return this.http
            .put(url, {
                client_response: ConnectionResponse.ACCEPT,
            })
            .toPromise()
            .then((payload) => {
                const connection = asConnection(payload);
                if (!connection) {
                    throw InvalidServerResponse(payload);
                }
                this.cache.replace(connection);
                return connection;
            })
            .catch((response: HttpErrorResponse) => {
                throw getAPIError(response);
            });
    }

    /*
     * Marks the connection (by pro ID) as declined. Returns the new connection
     * object via promise.
     */
    async decline(proID: number): Promise<Connection> {
        const url = await this.urlService.getConnectionUrl(proID);
        return this.http
            .put(url, {
                client_response: ConnectionResponse.DECLINE,
            })
            .toPromise()
            .then((payload) => {
                const connection = asConnection(payload);
                if (!connection) {
                    throw InvalidServerResponse(payload);
                }
                this.cache.replace(connection);
                return connection;
            })
            .catch((response: HttpErrorResponse) => {
                throw getAPIError(response);
            });
    }

    /*
     * Returns the connection objects cached by this service, or empty list
     * if the cache is empty.
     */
    getCached(): readonly Connection[] {
        return this.cache.connections;
    }

    /* Called to clear this service of any cached data */
    clear(): Promise<void> {
        return this.cache.clear();
    }
}
