import memoize from 'micro-memoize';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    ConnectionProDetails,
    ConnectionResponse,
    getConnectionContextDescription,
} from '@app/services/connection-model';
import {
    Connection,
    ConnectionService,
} from '@app/services/connection.service';
import {
    LocalProProfileRef,
    LocalProService,
} from '@app/services/local-pro.service';
import { ProfileService, ProfileRef } from '@app/services/profile.service';

interface PromptToDisplay {
    message: string;
    connectProID: number;
    connectProName: string;
    linkText: string;
    linkElementID: string;
    isError: boolean;
}

type PromptToDisplayRef = PromptToDisplay | null;

function getPromptToDisplay(
    profile: ProfileRef,
    supportProfile: LocalProProfileRef,
    connections: readonly Connection[]
): PromptToDisplay {
    function hasLocalProsTab(): boolean {
        return profile && profile.hasFeatureLocalPros;
    }

    function getPendingConnection(): Connection {
        const connection = connections.find(
            (connection) =>
                connection.clientResponse === ConnectionResponse.NO_RESPONSE
        );
        return connection ?? null;
    }

    function isNoProFindAPro(): boolean {
        return connections.length === 0 && hasLocalProsTab();
    }

    function isNoProConnectToSupportPro(): boolean {
        return (
            connections.length === 0 && !hasLocalProsTab() && !!supportProfile
        );
    }

    function hasPro(): boolean {
        return connections.some((connection) => connection.isConnected);
    }

    function isNoProPendingConnection(): boolean {
        return (
            connections.length > 0 &&
            connections.every(
                (connection) =>
                    connection.clientResponse === ConnectionResponse.NO_RESPONSE
            )
        );
    }

    function isHasPendingConnection(): boolean {
        return (
            connections.length > 0 &&
            connections.some(
                (connection) =>
                    connection.clientResponse === ConnectionResponse.NO_RESPONSE
            )
        );
    }

    function isConnectedToOnlySupportProAndHasTab(): boolean {
        return (
            hasLocalProsTab() &&
            connections.length === 1 &&
            connections.some((connection) => connection.pro.isSupportPro)
        );
    }

    if (isNoProFindAPro()) {
        return {
            message:
                'You are not currently connected to a Pro. Support is important to your success.',
            connectProID: 0,
            connectProName: '',
            linkText: 'Find a Pro',
            linkElementID: 'prompt-no-pro-find-a-pro',
            isError: true,
        };
    }
    if (isNoProConnectToSupportPro()) {
        return {
            message:
                'You currently have no Pro. Please connect to the GoGet.Fit Support Pro who will support your needs and goals.',
            connectProID: supportProfile.proID,
            connectProName: supportProfile.proName,
            linkText: 'Connect Now',
            linkElementID: 'prompt-no-pro-connect-to-support',
            isError: true,
        };
    }
    if (isHasPendingConnection()) {
        const connection = getPendingConnection();
        return {
            message:
                (hasPro() ? '' : 'You are not currently connected to a Pro. ') +
                getConnectionContextDescription(connection),
            connectProID: connection.pro.id,
            connectProName: connection.pro.name,
            linkText: 'View Request',
            linkElementID: 'half-connection-consent',
            isError: !hasPro(),
        };
    }
    if (isConnectedToOnlySupportProAndHasTab()) {
        return {
            message:
                'You are currently connected to our Support Pro. You can connect with a Pro who will support your specific needs and goals.',
            connectProID: 0,
            connectProName: '',
            linkText: 'Find a Pro',
            linkElementID: 'prompt-support-find-a-pro',
            isError: false,
        };
    }
    return null;
}

const getPromptToDisplayCached = memoize(getPromptToDisplay);

@Component({
    selector: 'app-prompt-clients',
    templateUrl: './prompt-clients.component.html',
    styleUrls: ['./prompt-clients.component.scss'],
})
export class PromptClientsComponent implements OnInit {
    profile: LocalProProfileRef;

    constructor(
        private connectionService: ConnectionService,
        private profileService: ProfileService,
        private router: Router,
        private localProService: LocalProService
    ) {}

    ngOnInit() {
        if (this.connections.length === 0 && !this.hasLocalProsTab) {
            this.fetchSupportPro();
        }
    }

    get hasLocalProsTab(): boolean {
        const profile = this.profileService.getCached();
        return profile && profile.hasFeatureLocalPros;
    }

    get connections(): readonly Connection[] {
        return this.connectionService.getCached();
    }

    get prompt(): PromptToDisplayRef {
        const profile = this.profileService.getCached();
        return getPromptToDisplayCached(
            profile,
            this.profile,
            this.connections
        );
    }

    async fetchSupportPro() {
        try {
            let proProfile = await this.localProService.search({ support: 1 });
            this.profile = proProfile && proProfile[0];
            return null;
        } catch (error) {
            console.error(
                'An error occurred while loading support pro details:',
                error
            );
        }
    }

    onPromptClick() {
        if (this.prompt.connectProID) {
            this.router.navigateByUrl('/connection-consent', {
                state: {
                    proID: this.prompt.connectProID,
                    proName: this.prompt.connectProName,
                },
            });
        } else {
            this.router.navigateByUrl('/tabs/find-a-pro');
        }
    }
}
