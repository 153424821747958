// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-container {
  background-color: var(--app-color-border);
  border-radius: 7px 7px 7px 7px;
}

.progress-bar {
  height: 8px;
  width: 0;
  max-width: 100%;
  border-radius: 11px 11px 11px 11px;
}
.progress-bar.completed {
  background: var(--ion-color-primary);
}

div {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/activity-summary-bar/activity-summary-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;EACA,8BAAA;AACJ;;AAEA;EACI,WAAA;EACA,QAAA;EACA,eAAA;EACA,kCAAA;AACJ;AACI;EACI,oCAAA;AACR;;AAGA;EACI,UAAA;AAAJ","sourcesContent":[".progress-bar-container {\n    background-color: var(--app-color-border);\n    border-radius: 7px 7px 7px 7px;\n}\n\n.progress-bar {\n    height: 8px;\n    width: 0;\n    max-width: 100%;\n    border-radius: 11px 11px 11px 11px;\n\n    &.completed {\n        background: var(--ion-color-primary);\n    }\n}\n\ndiv {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
