import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { WidgetComponent } from './widget/widget.component';

import { DurationPopoverComponent } from './duration-popover/duration-popover.component';

import { DateUtilsModule } from '@app/date-utils.module';

import { DateSelectorComponent } from './date-selector/date-selector.component';

import { TimeSelectorComponent } from './time-selector/time-selector.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { DurationButtonComponent } from './duration-button/duration-button.component';

import { TryAgainPopoverComponent } from './try-again-popover/try-again-popover.component';

import { ReflectionInfoPopoverComponent } from './reflection-info-popover/reflection-info-popover.component';

import { SegmentedBarComponent } from './segmented-bar/segmented-bar.component';

import { ActivitySummaryBarComponent } from './activity-summary-bar/activity-summary-bar.component';

import { ProgressRingComponent } from './progress-ring/progress-ring.component';

import { PostActivityScoreComponent } from './post-activity-score/post-activity-score.component';

import { WeeklyProgressComponent } from './weekly-progress/weekly-progress.component';

import { NotificationsOptInBannerComponent } from './notifications-opt-in-banner/notifications-opt-in-banner.component';

import { MakePasswordComponent } from './make-password/make-password.component';

import { AutoCompleteCitiesComponent } from './auto-complete-cities/auto-complete-cities.component';

import { ClickOutsideDirective } from './auto-complete-cities/clickoutside.directive';

import { DurationComponent } from './duration/duration.component';

import { MinutesProgressComponent } from './minutes-progress/minutes-progress.component';

import { PromptClientsComponent } from './prompt-clients/prompt-clients.component';

export {
    WidgetComponent,
    DateSelectorComponent,
    TimeSelectorComponent,
    DurationButtonComponent,
    TryAgainPopoverComponent,
    ReflectionInfoPopoverComponent,
    ProgressRingComponent,
    NotificationsOptInBannerComponent,
    DurationComponent,
    MinutesProgressComponent,
    PromptClientsComponent,
};

@NgModule({
    declarations: [
        WidgetComponent,
        DateSelectorComponent,
        TimeSelectorComponent,
        DurationPopoverComponent,
        DurationButtonComponent,
        TryAgainPopoverComponent,
        ReflectionInfoPopoverComponent,
        ActivitySummaryBarComponent,
        ProgressRingComponent,
        SegmentedBarComponent,
        PostActivityScoreComponent,
        WeeklyProgressComponent,
        NotificationsOptInBannerComponent,
        AutoCompleteCitiesComponent,
        ClickOutsideDirective,
        MakePasswordComponent,
        DurationComponent,
        MinutesProgressComponent,
        PromptClientsComponent,
    ],
    exports: [
        WidgetComponent,
        DateSelectorComponent,
        TimeSelectorComponent,
        DurationButtonComponent,
        TryAgainPopoverComponent,
        ReflectionInfoPopoverComponent,
        ActivitySummaryBarComponent,
        ProgressRingComponent,
        SegmentedBarComponent,
        PostActivityScoreComponent,
        WeeklyProgressComponent,
        NotificationsOptInBannerComponent,
        AutoCompleteCitiesComponent,
        MakePasswordComponent,
        DurationComponent,
        MinutesProgressComponent,
        PromptClientsComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        DateUtilsModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
    ],
    providers: [],
})
export class ComponentsModule {}
