// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  aspect-ratio: 1/1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/progress-ring/progress-ring.component.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;AAAJ","sourcesContent":["// force height to match width so page doesnt jump on load when height is zero for svg\ndiv {\n    aspect-ratio: 1/1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
