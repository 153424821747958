import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'activity-summary-bar',
    templateUrl: './activity-summary-bar.component.html',
    styleUrls: ['./activity-summary-bar.component.scss'],
})
export class ActivitySummaryBarComponent implements OnInit {
    @Input() numCompleted: number;
    @Input() numCommitted: number;
    constructor() {}

    get activityProgressPercentage(): number {
        if (!this.numCommitted) {
            return 0;
        }
        return (this.numCompleted / this.numCommitted) * 100;
    }

    ngOnInit() {}
}
