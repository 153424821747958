import { Injectable, EventEmitter } from '@angular/core';

import { Connection } from './connection-model';

@Injectable({
    providedIn: 'root',
})
export class ConnectionCache {
    connections: readonly Connection[] = [];

    isValid: boolean = false;

    // Notify subscribers whenever the cache changes
    cacheUpdate: EventEmitter<void> = new EventEmitter();

    // Called when a connection object is created (via API)
    add(connection: Connection) {
        if (this.isValid) {
            this.connections = this.connections.slice().concat([connection]);
            this.cacheUpdate.emit();
        }
    }

    // Replace all connection objects in this cache
    replaceAll(connections: Connection[]) {
        this.connections = connections;
        this.isValid = true;
        this.cacheUpdate.emit();
    }

    // Replace the given connection object in this cache
    replace(connection: Connection) {
        this.connections = this.connections.map((existing) => {
            if (existing.id === connection.id) {
                return connection;
            }
            return existing;
        });
        this.cacheUpdate.emit();
    }

    // Clear this cache
    clear(): Promise<void> {
        this.connections = [];
        this.isValid = false;
        this.cacheUpdate.emit();
        return Promise.resolve();
    }
}
