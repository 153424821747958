import memoize from 'micro-memoize';

import { Injectable } from '@angular/core';

import {
    MessageService,
    ClientMessage,
    ClientMessageRef,
    sortedNewestFirst,
} from './message.service';

import { APIError } from './error';

import { DateTime } from 'luxon';

export { ClientMessage };

const CHECK_NEW_MESSAGES_INTERVAL_SECONDS = 5 * 60;

// The callback used in OldMessageCache (see below)
type OldMessageCacheCallback = (days: number) => Promise<ClientMessage[]>;

/*
 * Build a key used to validate/invalidate the cache. The format
 * doesn't matter as long as it changes as a result of either:
 * 1) the number of days change
 * 2) the current date changes
 */
function makeCacheKey(days): string {
    const now = DateTime.utc().toISODate();
    return now + ',' + days;
}

/*
 * This class manages a list of messages of the form "older than X days". This
 * cache relies on the fact that old messages almost never change. If they do
 * they change as a result of client-side behaviour (eg user reads a message)
 * and we control when that happens.
 */
export class OldMessageCache {
    // The message cache
    messages: ClientMessage[];
    // The cache key used to invalidate the cache. The key is a combination of
    // the current date and the "days old" cutoff parameter.
    lastCheck: string;

    // The callback function is called when "old messages" need to be fetched
    // from the server.
    constructor(private callback: OldMessageCacheCallback) {
        this.messages = [];
    }

    // Invalidate the cache
    invalidate() {
        this.lastCheck = '';
        this.messages = [];
    }

    replace(replacement: ClientMessage) {
        this.messages = this.messages.map((message) => {
            if (replacement.id === message.id) {
                return replacement;
            }
            return message;
        });
    }

    /* Update this cache if necessary. Returns a promise resolving to the list
     * of messages that are older than the given number of days. */
    update(days: number): Promise<ClientMessage[]> {
        const key = makeCacheKey(days);
        if (this.lastCheck === key) {
            return Promise.resolve(this.messages);
        }

        return this.callback(days).then((messages) => {
            this.lastCheck = key;
            this.messages = sortedNewestFirst(messages);
            return this.messages;
        });
    }
}

/* Manages a cache of messages of the form "newer than X days". This class is
 * smart enough to know how much data to request from the server in order to
 * keep the cache up to date. */
export class NewMessageCache {
    // The cached messages
    messages: ClientMessage[];
    lastCheck: string = '';
    nextCheckNewMessagesTime: number = 0;

    constructor(private callback: OldMessageCacheCallback) {
        this.messages = [];
    }

    // Invalidates the cache
    invalidate() {
        this.nextCheckNewMessagesTime = 0;
        this.lastCheck = '';
        this.messages = [];
    }

    replace(replacement: ClientMessage) {
        this.messages = this.messages.map((message) => {
            if (replacement.id === message.id) {
                return replacement;
            }
            return message;
        });
    }

    /* Returns a promise resolving to the list of messages that are newer than
     * the given number of days. Updates the cache as appropriate. */
    update(days: number): Promise<ClientMessage[]> {
        const key = makeCacheKey(days);
        if (this.lastCheck === key) {
            // We only need to check for messages delivered today, then merge
            // that into the cache.
            return this.callback(1).then((newMessages) => {
                this.messages = sortedNewestFirst(
                    mergeMessageLists(this.messages, newMessages)
                );
                return this.messages;
            });
        }
        // Otherwise fetch a fresh copy of all recent messages
        return this.callback(days).then((messages) => {
            this.lastCheck = key;
            this.messages = sortedNewestFirst(messages);
            return this.messages;
        });
    }

    async checkNewMessages(): Promise<void> {
        const now = DateTime.now().toSeconds();
        if (now > this.nextCheckNewMessagesTime) {
            // Note: the cooldown timer is set first, so if the request fails
            // and this function is called repeatedly, we won't potentially
            // create a bunch of failing network requests.
            const interval = CHECK_NEW_MESSAGES_INTERVAL_SECONDS;
            this.nextCheckNewMessagesTime = now + interval;
            await this.update(MessageCacheService.archiveCutoffDays);
        }
    }
}

/* Return a new list of messages that contain those in original and newMessages
 * without duplicates. Messages are considered the same if their ids match. */
export function mergeMessageLists(
    original: ClientMessage[],
    newMessages: ClientMessage[]
): ClientMessage[] {
    // Note we always return a new list and never mutate the function params
    const resultList = original.slice();
    const originalIDs = original.map((msg) => msg.id);
    newMessages.forEach((msg) => {
        const i = originalIDs.indexOf(msg.id);
        if (i === -1) {
            // New message
            resultList.push(msg);
        } else {
            // Replace existing with a new message (possibly in a new state)
            resultList[i] = msg;
        }
    });
    return resultList;
}

/* Returns a list of unread messages from a given list */
export function filterUnreadMessages(
    messages: ClientMessage[]
): ClientMessage[] {
    return messages.filter((msg) => !msg.isRead);
}

export const filterUnreadMessagesCached = memoize(filterUnreadMessages);

@Injectable({
    providedIn: 'root',
})
export class MessageCacheService {
    newMessageCache: NewMessageCache;
    oldMessageCache: OldMessageCache;

    /* How many "days worth" of recent messages to show */
    static archiveCutoffDays: number = 90;

    constructor(private messageService: MessageService) {
        this.newMessageCache = new NewMessageCache((days: number) => {
            return this.messageService.fetch({ newerThan: days });
        });
        this.oldMessageCache = new OldMessageCache((days: number) => {
            return this.messageService.fetch({ olderThan: days });
        });
    }

    get numUnreadMessages(): number {
        return filterUnreadMessagesCached(this.cachedNewMessages).length;
    }

    get cachedOldMessages(): ClientMessage[] {
        return this.oldMessageCache.messages;
    }

    get cachedNewMessages(): ClientMessage[] {
        return this.newMessageCache.messages;
    }

    /*
     * Similar to getNewMessages but rate-limits the actual network requests to
     * one per defined time interval. (eg. once every 60 seconds)
     */
    async checkNewMessages(): Promise<void> {
        await this.newMessageCache.checkNewMessages();
    }

    /*
     * Returns a list of messages newer than the given date. (via promise)
     * This function is smart about managing a local message cache and only
     * pulls what it needs from the server. Note: this function will always
     * hit the server to either:
     *
     * 1. Fetch messages sent in the past day (if the cache is up-to-date)
     * 2. Fetch all recent messages since the given number of days (if the
     * cache is empty or outdated)
     */
    getNewMessages(
        days: number = MessageCacheService.archiveCutoffDays
    ): Promise<ClientMessage[]> {
        return this.newMessageCache.update(days);
    }

    /*
     * Returns a list of messages older than the given number of days, sorted
     * newest first. (the messages are returned via promise) This function
     * first pulls from the cache, then the server if the cache is out of date.
     */
    getOldMessages(
        days: number = MessageCacheService.archiveCutoffDays
    ): Promise<ClientMessage[]> {
        return this.oldMessageCache.update(days);
    }

    /*
     * Returns the message with the matching ID. Returns null if no such
     * message could be found.
     */
    async getMessage(id: number): Promise<ClientMessageRef> {
        let messages;
        let selectedMessage: ClientMessageRef;

        messages = await this.getNewMessages();
        //find will return undefined if message is not found
        selectedMessage = messages.find((msg) => msg.id === id);

        //if not found in new messages search old messages
        if (selectedMessage === undefined) {
            messages = await this.getOldMessages();
            selectedMessage = messages.find((msg) => msg.id === id);
        }
        //if not found in new or old messages, send errror
        if (selectedMessage === undefined) {
            return null;
        }
        return Promise.resolve(selectedMessage);
    }

    /*
     * Mark the given message as read. Returns a promise that resolves to the
     * state of the new message.
     */
    markAsRead(message: ClientMessage): Promise<ClientMessage> {
        return this.messageService.markAsRead(message).then((message) => {
            this.oldMessageCache.replace(message);
            this.newMessageCache.replace(message);
            return message;
        });
    }

    /* Clears any cached data by this service */
    clear(): Promise<void> {
        this.oldMessageCache.invalidate();
        this.newMessageCache.invalidate();
        return Promise.resolve();
    }
}
