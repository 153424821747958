import { DateTime } from 'luxon';

import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { UrlService, CannotBuildUrl } from './url.service';

import { APIError, InvalidServerResponse, getAPIError } from './error';

import { ConnectionCache } from './connection-cache';

export interface RecommendationLine {
    readonly activities: number;
    readonly durationMinutes: number;
    readonly details: string;
}

export interface RecommendationProDetails {
    readonly id: number;
    readonly name: string;
}

export interface Recommendation {
    readonly id: number;
    readonly pro: RecommendationProDetails;
    readonly startDateISO: string;
    readonly endDateISO: string;
    readonly lines: RecommendationLine[];
}

export type RecommendationRef = Recommendation | null;

function isRecommendationLine(data: unknown): data is RecommendationLine {
    const line = data as RecommendationLine;
    return !!(
        line &&
        typeof line.activities === 'number' &&
        typeof line.durationMinutes === 'number' &&
        typeof line.details === 'string'
    );
}

function isRecommendationProDetails(
    data: unknown
): data is RecommendationProDetails {
    const details = data as RecommendationProDetails;
    return !!(
        details &&
        typeof details.id === 'number' &&
        typeof details.name === 'string'
    );
}

export function isRecommendation(data: unknown): data is Recommendation {
    const recommendation = data as Recommendation;
    return !!(
        recommendation &&
        typeof recommendation.id === 'number' &&
        isRecommendationProDetails(recommendation.pro) &&
        typeof recommendation.startDateISO === 'string' &&
        typeof recommendation.endDateISO === 'string' &&
        Array.isArray(recommendation.lines) &&
        recommendation.lines.every(isRecommendationLine)
    );
}

export function isRecommendationList(data: unknown): data is Recommendation[] {
    return !!(Array.isArray(data) && data.every(isRecommendation));
}

export function asRecommendation(data: any): RecommendationRef {
    if (!data) {
        return null;
    }
    if (!Array.isArray(data.lines)) {
        return null;
    }
    if (!data.pro) {
        return null;
    }
    const lines = data.lines.map((el) => {
        return {
            activities: el.activities,
            durationMinutes: el.duration_minutes,
            details: el.details,
        };
    });
    const recommendation = {
        id: data.id,
        pro: {
            id: data.pro.id,
            name: data.pro.name,
        },
        startDateISO: data.start_date,
        endDateISO: data.end_date,
        lines: lines,
    };
    if (!isRecommendation(recommendation)) {
        return null;
    }
    return recommendation;
}

export function asRecommendationList(data: any): Recommendation[] | null {
    if (!Array.isArray(data)) {
        return null;
    }
    const lst = data.map(asRecommendation);
    if (!isRecommendationList(lst)) {
        return null;
    }
    return lst;
}

/* Whether the given recommendation is currently active */
export function isRecommendationActive(rec: Recommendation): boolean {
    const nowISO = DateTime.utc().toISO();
    return rec.startDateISO <= nowISO && rec.endDateISO > nowISO;
}

export class RecommendationCache {
    // All recommendations
    recommendations: Recommendation[] | null = null;
    // Active recommendations only
    activeRecommendations: Recommendation[] | null = null;

    get isValid(): boolean {
        return this.recommendations !== null;
    }

    replace(recommendations: Recommendation[]) {
        this.recommendations = recommendations;
        this.activeRecommendations = recommendations.filter(
            isRecommendationActive
        );
    }

    clear(): Promise<void> {
        this.recommendations = null;
        this.activeRecommendations = null;
        return Promise.resolve();
    }
}

@Injectable({
    providedIn: 'root',
})
export class RecommendationService {
    cache: RecommendationCache = new RecommendationCache();
    readonly EMPTY_CACHE = [];

    constructor(
        private http: HttpClient,
        private urlService: UrlService,
        private connectionCache: ConnectionCache
    ) {
        // Clear the recommendation cache whenever the connection state changes
        // (eg connected to a new pro)
        this.connectionCache.cacheUpdate.subscribe(() => {
            this.clear();
        });
    }

    /* Fetches all active and pending recommendations from the API server */
    async fetch(): Promise<Recommendation[]> {
        if (this.cache.isValid) {
            return Promise.resolve(this.cache.recommendations);
        }
        const url = await this.urlService.getRecommendationUrl();
        return this.http
            .get(url)
            .toPromise()
            .then((payload) => {
                const recommendations = asRecommendationList(payload);
                if (!recommendations) {
                    throw InvalidServerResponse(payload);
                }
                return recommendations;
            })
            .catch((error) => {
                throw getAPIError(error);
            })
            .then((recommendations) => {
                this.cache.replace(recommendations);
                return recommendations;
            });
    }

    getCachedActive(): readonly Recommendation[] {
        if (this.cache.isValid) {
            return this.cache.activeRecommendations;
        }
        return this.EMPTY_CACHE;
    }

    clear(): Promise<void> {
        return this.cache.clear();
    }
}
