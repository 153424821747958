// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  align-items: center;
  padding: 1em;
  margin: 0.75em 0 0 0;
  background: white;
}

ion-icon {
  flex-shrink: 0;
  width: 3em;
  height: 3em;
  margin-right: 0.5em;
  color: var(--ion-color-danger);
}

button {
  font-size: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  color: var(--ion-color-primary);
}

p {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/notifications-opt-in-banner/notifications-opt-in-banner.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,oBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,cAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;EACA,8BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,+BAAA;AACJ;;AAEA;EACI,SAAA;AACJ","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    padding: 1em;\n    margin: 0.75em 0 0 0;\n    background: white;\n}\n\nion-icon {\n    flex-shrink: 0;\n    width: 3em;\n    height: 3em;\n    margin-right: 0.5em;\n    color: var(--ion-color-danger);\n}\n\nbutton {\n    font-size: inherit;\n    background: none;\n    border: none;\n    margin: 0;\n    padding: 0;\n    color: var(--ion-color-primary);\n}\n\np {\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
