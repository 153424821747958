// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-progress-panel {
  background-color: var(--ion-color-primary-contrast);
  padding: 1% 2% 2%;
}

.activity-progress-segment {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
}

.activity-progress-segment .progress-bar {
  width: 100%;
  height: 16px;
  margin-right: 1px;
}
.activity-progress-segment .progress-bar.missed {
  background: #999999;
}
.activity-progress-segment .progress-bar.completed {
  background: var(--ion-color-primary);
}
.activity-progress-segment .progress-bar.pending {
  background: var(--app-color-border);
}
.activity-progress-segment .progress-bar.unscheduled {
  background: var(--ion-color-primary-contrast);
  box-sizing: border-box;
  border: solid 1px var(--app-color-border);
}
.activity-progress-segment .progress-bar:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.activity-progress-segment .progress-bar:last-child {
  border: 1px solid var(--app-color-border);
  box-sizing: border-box;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.activity-progress-bottom {
  display: flex;
  flex-direction: row;
}

.activity-progress-bottom .progress {
  margin-top: 3%;
  flex-grow: 1;
}

.activity-progress-bottom .commitment {
  margin-top: 3%;
}

.activity-progress-bottom .unscheduled {
  margin-top: 3%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/segmented-bar/segmented-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,mDAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AACJ;AAAI;EACI,mBAAA;AAER;AACI;EACI,oCAAA;AACR;AAEI;EACI,mCAAA;AAAR;AAGI;EACI,6CAAA;EACA,sBAAA;EACA,yCAAA;AADR;AAII;EACI,+BAAA;EACA,4BAAA;AAFR;AAKI;EACI,yCAAA;EACA,sBAAA;EACA,gCAAA;EACA,6BAAA;AAHR;;AAOA;EACI,aAAA;EACA,mBAAA;AAJJ;;AAOA;EACI,cAAA;EACA,YAAA;AAJJ;;AAOA;EACI,cAAA;AAJJ;;AAOA;EACI,cAAA;AAJJ","sourcesContent":[".activity-progress-panel {\n    background-color: var(--ion-color-primary-contrast);\n    padding: 1% 2% 2%;\n}\n\n.activity-progress-segment {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 2%;\n}\n\n.activity-progress-segment .progress-bar {\n    width: 100%;\n    height: 16px;\n    margin-right: 1px;\n    &.missed {\n        background: #999999;\n    }\n\n    &.completed {\n        background: var(--ion-color-primary);\n    }\n\n    &.pending {\n        background: var(--app-color-border);\n    }\n\n    &.unscheduled {\n        background: var(--ion-color-primary-contrast);\n        box-sizing: border-box;\n        border: solid 1px var(--app-color-border);\n    }\n\n    &:first-child {\n        border-bottom-left-radius: 10px;\n        border-top-left-radius: 10px;\n    }\n\n    &:last-child {\n        border: 1px solid var(--app-color-border);\n        box-sizing: border-box;\n        border-bottom-right-radius: 10px;\n        border-top-right-radius: 10px;\n    }\n}\n\n.activity-progress-bottom {\n    display: flex;\n    flex-direction: row;\n}\n\n.activity-progress-bottom .progress {\n    margin-top: 3%;\n    flex-grow: 1;\n}\n\n.activity-progress-bottom .commitment {\n    margin-top: 3%;\n}\n\n.activity-progress-bottom .unscheduled {\n    margin-top: 3%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
