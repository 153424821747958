import { Injectable } from '@angular/core';

import {
    HttpClient,
    HttpErrorResponse,
    HttpStatusCode,
} from '@angular/common/http';

import { UrlService } from './url.service';

import { APIError, getAPIError, InvalidServerResponse } from './error';

/*
 * Note: the enum values *must* match those returned by the server.
 */
export enum FeatureFlags {
    LOCAL_PROS = 'local-pros',
}

export interface Profile {
    readonly createdISO: string;
    readonly id?: number;
    readonly fullName: string;
    readonly chosenName: string;
    readonly email: string;
    readonly city: string;
    readonly country: string;
    readonly province: string;
    readonly wantsEmailUpdates: boolean;
    readonly hasFeatureLocalPros: boolean;
    readonly pastEditWindowWeeks: number;
    readonly hasSSO: boolean;
    readonly hasPassword: boolean;
    readonly hasAcceptedTerms: boolean;
}

/* Used when creating/updating an existing profile */
export interface ProfileDetails {
    readonly fullName?: string;
    readonly chosenName?: string;
    readonly city?: string;
    readonly country?: string;
    readonly province?: string;
    readonly wantsEmailUpdates?: boolean;
    readonly hasAcceptedTerms?: boolean;
}

export type ProfileRef = Profile | null;

export function isProfile(data: unknown): data is Profile {
    const profile = data as Profile;
    return !!(
        profile &&
        (typeof profile.id === 'number' || profile.id === undefined) &&
        typeof profile.createdISO === 'string' &&
        typeof profile.fullName === 'string' &&
        typeof profile.chosenName === 'string' &&
        typeof profile.email === 'string' &&
        typeof profile.city === 'string' &&
        typeof profile.country === 'string' &&
        typeof profile.province === 'string' &&
        typeof profile.wantsEmailUpdates === 'boolean' &&
        typeof profile.hasFeatureLocalPros === 'boolean' &&
        typeof profile.pastEditWindowWeeks === 'number' &&
        typeof profile.hasSSO === 'boolean' &&
        typeof profile.hasPassword === 'boolean' &&
        typeof profile.hasAcceptedTerms === 'boolean'
    );
}

export function asProfile(data: any): ProfileRef {
    if (!data) {
        return null;
    }
    const flags = data.feature_flags ?? [];
    const hasFeatureLocalPros = flags.indexOf(FeatureFlags.LOCAL_PROS) !== -1;
    const profile = {
        id: data.id,
        createdISO: data.created,
        fullName: data.full_name,
        chosenName: data.chosen_name,
        email: data.email,
        city: data.city,
        country: data.country,
        province: data.province,
        wantsEmailUpdates: !!data.wants_emails,
        hasFeatureLocalPros: hasFeatureLocalPros,
        pastEditWindowWeeks: data.past_edit_window_weeks,
        hasSSO: data.has_sso,
        hasPassword: data.has_password,
        hasAcceptedTerms: data.has_accepted_terms ?? true,
    };
    if (isProfile(profile)) {
        return profile;
    }
    return null;
}

export function getDefaultChosenName(fullName: string): string {
    function getFromLastFirst(): string {
        const parts = fullName.trim().split(',');
        if (parts.length > 1) {
            return parts[1].trim().split(' ')[0];
        }
        return '';
    }
    function getFromFirstLast(): string {
        const parts = fullName.trim().split(' ');
        return parts[0];
    }
    return getFromLastFirst() || getFromFirstLast();
}

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    cachedProfile: ProfileRef = null;

    constructor(private urlService: UrlService, private http: HttpClient) {}

    /* Creates a new profile on the server. Returns the new profile as returned
     * by the server. (via promise) */
    async create(profile: ProfileDetails): Promise<Profile> {
        const url = await this.urlService.getProfileUrl();
        const body = {
            full_name: profile.fullName,
            chosen_name: profile.chosenName,
            city: profile.city,
            country: profile.country,
            province: profile.province,
            wants_emails: profile.wantsEmailUpdates,
        };
        return this.http
            .post(url, body)
            .toPromise()
            .catch((response: HttpErrorResponse) => {
                throw getAPIError(response);
            })
            .then((payload: any) => {
                const profile = asProfile(payload);
                if (!isProfile(profile)) {
                    throw InvalidServerResponse(payload);
                }
                this.cachedProfile = profile;
                return profile;
            });
    }

    /* Update this clients profile on the server. Returns the new profile
     * as returned by the server. (via promise) */
    async update(profile: ProfileDetails): Promise<Profile> {
        const url = await this.urlService.getProfileUrl();
        const body = {
            full_name: profile.fullName,
            chosen_name: profile.chosenName,
            city: profile.city,
            country: profile.country,
            province: profile.province,
            wants_emails: profile.wantsEmailUpdates,
            has_accepted_terms: profile.hasAcceptedTerms,
        };
        // Drop fields not specified in profile object
        Object.keys(body).forEach((key) => {
            if (body[key] === undefined) {
                delete body[key];
            }
        });
        return this.http
            .put(url, body)
            .toPromise()
            .catch((response: HttpErrorResponse) => {
                throw getAPIError(response);
            })
            .then((payload: any) => {
                const profile = asProfile(payload);
                if (!isProfile(profile)) {
                    throw InvalidServerResponse(payload);
                }
                this.cachedProfile = profile;
                return profile;
            });
    }

    /*
     * Attempts to update an existing profile, or if one doesn't exist creates
     * a new profile. Returns the new object via promise.
     */
    updateOrCreate(profile: ProfileDetails): Promise<Profile> {
        return this.update(profile).catch((error: APIError) => {
            if (error.code === HttpStatusCode.NotFound) {
                return this.create(profile);
            }
            throw error;
        });
    }

    /*
     * Fetch the profile for the logged in client and update the cached profile
     * object. If the client has no profile null is returned instead. Note this
     * function pulls from the cache first if available.
     */
    async fetch(): Promise<ProfileRef> {
        if (this.cachedProfile) {
            return Promise.resolve(this.cachedProfile);
        }
        const url = await this.urlService.getProfileUrl();
        return this.http
            .get(url)
            .toPromise()
            .catch((response: HttpErrorResponse) => {
                // Convert 404 into null profile object
                if (response.status === 404) {
                    return null;
                }
                throw getAPIError(response);
            })
            .then((payload: any) => {
                if (payload === null) {
                    this.cachedProfile = null;
                    return null;
                }
                const profile = asProfile(payload);
                if (!isProfile(profile)) {
                    throw InvalidServerResponse(payload);
                }
                this.cachedProfile = profile;
                return profile;
            });
    }

    // TODO - use ProfileService.fetch instead of this function
    getCachedOrFetch(): Promise<ProfileRef> {
        return this.fetch();
    }

    clear(): Promise<void> {
        this.cachedProfile = null;
        return Promise.resolve();
    }

    getCached(): ProfileRef {
        return this.cachedProfile;
    }
}
