import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {
    /* An example of how to bind inputs and outputs in a component */
    @Input() message: string = 'default value';
    @Output() tick: EventEmitter<number> = new EventEmitter();

    counter = 0;

    constructor() {}

    ngOnInit() {}

    handleClick() {
        this.counter++;
        this.tick.emit(this.counter);
    }
}
