import { DateTime } from 'luxon';

import { Injectable } from '@angular/core';

import {
    AuthStorageService,
    getServerUrlForCountry,
} from './auth-storage.service';

import { APIError } from '@app/services/error';

import { getCurrentTimezone } from '@app/date-utils';

/* Used to indicate that the url service wasn't able to build an API URL
 * because the country code isn't defined. */
export function CannotBuildUrl(): APIError {
    return APIError('cannot build API url (missing country code)');
}

/* Helper function to build a URL given the base name (eg "http://example.com")
 * path and query parameters. (as a object map) */
function makeUrl(baseUrl: string, path: string, params: any = {}): string {
    if (!baseUrl) {
        throw Error('baseUrl cannot be empty string');
    }
    const url = new URL(path, baseUrl + '/');
    for (let key in params) {
        url.searchParams.set(key, params[key]);
    }
    return url.href;
}

export interface MessageListGetParams {
    newerThan?: number;
    olderThan?: number;
}

export interface ActivityListUrlOptions {
    readonly after?: DateTime;
    readonly before?: DateTime;
    readonly updatedAfter?: DateTime;
}

export interface LocalProSearchParams {
    country?: string;
    province?: string;
    city?: string;
    visibility?: string;
    ids?: number[];
    support?: number;
}

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    constructor(public authStorage: AuthStorageService) {}

    getCountry(): Promise<string> {
        return this.authStorage.getTokenDetails().then((details) => {
            return details.country;
        });
    }

    /*
     * Returns the server URL based on country code (via promise), or an empty
     * string if the country code is not set.
     */
    getServerUrl(): Promise<string> {
        return this.authStorage.getTokenDetails().then((details) => {
            return details.serverUrl;
        });
    }

    /*
     * Constructs and returns an API url given the path and query parameters.
     * The path is concatenated to the server URL, resolved using the stored
     * country code. The fallback country (code) can provided in the event
     * the country code isn't stored in auth storage.
     *
     * Raises CannotBuildUrl if the country code isn't set.
     */
    makeUrl(
        path: string,
        params: any = {},
        fallbackCountry: string = ''
    ): Promise<string> {
        return this.getServerUrl().then((serverUrl) => {
            if (!serverUrl) {
                if (fallbackCountry) {
                    // Just use the fallback
                    const baseUrl = getServerUrlForCountry(fallbackCountry);
                    return makeUrl(baseUrl, path, params);
                }
                // Otherwise fatal error - we can't build the URL if we don't
                // know the country.
                throw CannotBuildUrl();
            }
            return makeUrl(serverUrl, path, params);
        });
    }

    /* Returns the API server URL for the given country. Returns empty string if
     * there is no matching URL */
    getAuthUrl(country: string): string {
        const baseUrl = getServerUrlForCountry(country);
        if (!baseUrl) {
            return '';
        }
        return makeUrl(baseUrl, '/v2/auth/');
    }

    getAuthSSOUrl(country: string): string {
        const baseUrl = getServerUrlForCountry(country);
        if (!baseUrl) {
            return '';
        }
        return makeUrl(baseUrl, '/v2/auth/sso/');
    }

    /* Returns the URL for the API registration endpoint. Returns empty string
     * if there is no matching country code. */
    getRegistrationUrl(country: string): string {
        const baseUrl = getServerUrlForCountry(country);
        if (!baseUrl) {
            return '';
        }
        return makeUrl(baseUrl, '/v2/account/');
    }

    /* Returns the URL for the API registration endpoint. Returns empty string
     * if there is no matching country code. */
    getRegistrationUsingMagicCodeUrl(country: string): string {
        const baseUrl = getServerUrlForCountry(country);
        if (!baseUrl) {
            return '';
        }
        return makeUrl(baseUrl, '/v2/account/magic/');
    }

    getAccountUrl(): Promise<string> {
        return this.makeUrl('/v2/account/me/');
    }

    getAccountRoleUrl(): Promise<string> {
        return this.makeUrl('/v2/account/role/');
    }

    getFeedbackUrl(): Promise<string> {
        return this.makeUrl('/v2/feedback/client/');
    }

    getMessageListUrl(params: MessageListGetParams = {}): Promise<string> {
        const urlParams = {};
        if (params.newerThan !== undefined) {
            urlParams['newer_than'] = params.newerThan;
        }
        if (params.olderThan !== undefined) {
            urlParams['older_than'] = params.olderThan;
        }
        return this.makeUrl('/api/v1/received_message/', urlParams);
    }

    getMessageUrl(messageId: number): Promise<string> {
        return this.makeUrl('/api/v1/received_message/' + messageId + '/');
    }

    getProfileUrl(): Promise<string> {
        return this.makeUrl('/v2/client/profile/me/');
    }

    getCommitmentOverviewUrl(): Promise<string> {
        return this.makeUrl('/v2/client/commitment/overview/me/');
    }

    getNextCommitmentUrl(): Promise<string> {
        return this.makeUrl('/v2/client/commitment/next/me/');
    }

    getCommitmentUrl(id: number): Promise<string> {
        return this.makeUrl('/v2/client/commitment/' + id + '/');
    }

    getSettingsUrl(): Promise<string> {
        return this.makeUrl('/v2/client/notification-settings/me/');
    }

    /* The url for creating or fetching single activity object */
    getActivityUrl(id?: number): Promise<string> {
        if (id === undefined) {
            return this.makeUrl('/v2/client/activity/me/');
        }
        return this.makeUrl('/v2/client/activity/' + id + '/');
    }

    /* The url for fetching a list of activity objects */
    getActivityListUrl(options: ActivityListUrlOptions = {}): Promise<string> {
        let params = {};
        if (options.before) {
            params['before'] = options.before.toISO();
        }
        if (options.after) {
            params['after'] = options.after.toISO();
        }
        if (options.updatedAfter) {
            params['updated_after'] = options.updatedAfter.toISO();
        }
        return this.makeUrl('/v2/client/activity/me/', params);
    }

    getCreateActivityUrl(): Promise<string> {
        return this.makeUrl('/v2/client/activity/me/');
    }

    getRecommendationUrl(): Promise<string> {
        return this.makeUrl('/v2/client/recommendation/me/', {
            include_inactive: 0,
        });
    }

    getConnectionUrl(proID?: number): Promise<string> {
        let path = '/v2/client/connection/me/';
        if (proID) {
            path = `/v2/client/connection/me/pro/${proID}/`;
        }
        return this.makeUrl(path);
    }

    getWeeklySummaryUrl(): Promise<string> {
        return this.makeUrl('/v2/client/summary/weekly/me/');
    }

    getResetPasswordUrl(country: string): string {
        const baseUrl = getServerUrlForCountry(country);
        if (!baseUrl) {
            return '';
        }
        return makeUrl(baseUrl, '/api/v1/user/request_password_reset/');
    }

    getConnectionRequestFormURL(): Promise<string> {
        return this.makeUrl('/connection-request/');
    }

    getDeviceUrl(uuid: string): Promise<string> {
        return this.makeUrl(`/api/v1/device/${uuid}/`);
    }

    getLocalProSearchUrl(params: LocalProSearchParams): Promise<string> {
        const args = {};
        if (params.country) {
            args['country'] = params.country;
        }
        if (params.province) {
            args['province'] = params.province;
        }
        if (params.city) {
            args['city'] = params.city;
        }
        if (params.visibility) {
            args['visibility'] = params.visibility;
        }
        if (params.support) {
            args['support'] = params.support;
        }
        if (params.ids) {
            args['ids'] = params.ids.map((id) => '' + id).join(',');
        }
        return this.makeUrl('/v2/client/local-pros/search/', args);
    }

    getLocalProLocationsUrl(): Promise<string> {
        return this.makeUrl('/v2/client/local-pros/locations/?show_remote=1');
    }

    /*
     * Note: the Terms API is always accessible and doens't require that the
     * user be authenticated. If they are, this function returns the endpoint
     * corresponding to their country. Otherwise CA is used as a fallback.
     */
    getTermsUrl(): Promise<string> {
        return this.makeUrl('/v2/terms/', {}, 'CA');
    }

    getChangePasswordUrl(): Promise<string> {
        return this.makeUrl('/v2/password/me/');
    }

    getDeleteRequestUrl(): Promise<string> {
        return this.makeUrl('/v2/client/delete-request/me/');
    }

    getInviteUrl(): Promise<string> {
        return this.makeUrl('/v2/client/invite/me/');
    }

    async getMagicSignInUrl(country: string): Promise<string> {
        const baseUrl = getServerUrlForCountry(country);
        return await makeUrl(baseUrl, '/v2/magic-sign-in/');
    }

    async getAuthMagicSignInUrl(country: string): Promise<string> {
        const baseUrl = getServerUrlForCountry(country);
        return await makeUrl(baseUrl, '/v2/auth/magic/');
    }
}
