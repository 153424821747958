import memoize from 'micro-memoize';

import { DateTime } from 'luxon';

import {
    Activity,
    ActivityRef,
    filterByWeek,
    sortedByStartTime,
} from './activity-model';

const filterByWeekCached = memoize(filterByWeek);

/*
 * Base class for managing a cache of activities.
 */
export class ActivityCache {
    activities: readonly Activity[] = [];
    // Whether the activities array refers to actually cached data
    isValid: boolean = false;
    lastPopulated: DateTime | null = null;

    constructor(activities: readonly Activity[] = []) {
        this.activities = activities;
    }

    /* Insert a newly created activity into this cache */
    created(activity: Activity) {
        const newActivities = this.activities.slice();
        newActivities.push(activity);
        this.activities = sortedByStartTime(newActivities);
    }

    /* Replace an activity in this cache */
    updated(replacement: Activity) {
        this.activities = sortedByStartTime(
            this.activities.map((activity) => {
                if (replacement.id === activity.id) {
                    return replacement;
                }
                return activity;
            })
        );
    }

    /* Search for an activity matching the given id */
    find(id: number): ActivityRef {
        const activity = this.activities.find((activity) => activity.id === id);
        if (!activity) {
            return null;
        }
        return activity;
    }

    /* Removes an activity from this cache (by ID) */
    deleted(id: number) {
        this.activities = this.activities.filter(
            (activity) => activity.id !== id
        );
    }

    /* Replace the contents of this cache */
    set(activities: readonly Activity[]): readonly Activity[] {
        this.lastPopulated = DateTime.now();
        this.activities = sortedByStartTime(activities);
        this.isValid = true;
        return this.activities;
    }

    /*
     * Returns the age of this cache, measured from when it was last populated
     * by the server, in milliseconds.
     */
    get populatedAgeMS(): number {
        return DateTime.now() - this.lastPopulated;
    }

    /* Merge the given activities into this cache, replacing it's contents */
    merge(updated: readonly Activity[]) {
        this.lastPopulated = DateTime.now();
        if (updated.length > 0) {
            updated.forEach((activity) => {
                if (this.find(activity.id)) {
                    this.updated(activity);
                } else {
                    this.created(activity);
                }
            });
        }
    }

    /* Empty this cache */
    clear(): Promise<void> {
        this.activities = [];
        this.isValid = false;
        return Promise.resolve();
    }
}

/*
 * Manages "active" activities - this week, and the next.
 */
export class ActivityActiveCache extends ActivityCache {
    /*
     * Returns the activities from this cache that fall into this week. Note
     * this function considers Monday at 00:00:00 to start the week.
     */
    get activitiesThisWeek(): readonly Activity[] {
        return filterByWeekCached(this.activities, DateTime.now());
    }

    /*
     * Returns the activities from this cache that fall into next week. Note
     * this function considers Monday at 00:00:00 to start the week.
     */
    get activitiesNextWeek(): readonly Activity[] {
        const nextWeek = DateTime.now().plus({ weeks: 1 });
        return filterByWeekCached(this.activities, nextWeek);
    }
}
