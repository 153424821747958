// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  background: var(--ion-color-primary-contrast);
}

.container {
  display: flex;
  padding: 1em;
  margin-top: 0.75em;
}
.container a {
  cursor: pointer;
}
.container p {
  margin: 0;
}
.container i {
  flex-shrink: 0;
  margin-right: 0.5em;
  font-size: 2.25em;
  color: var(--ion-color-primary);
}
.container .error {
  color: var(--ion-color-danger);
}`, "",{"version":3,"sources":["webpack://./src/app/components/prompt-clients/prompt-clients.component.scss"],"names":[],"mappings":"AAAA;EACI,6CAAA;AACJ;;AAEA;EACI,aAAA;EACA,YAAA;EACA,kBAAA;AACJ;AACI;EACI,eAAA;AACR;AAEI;EACI,SAAA;AAAR;AAGI;EACI,cAAA;EACA,mBAAA;EACA,iBAAA;EACA,+BAAA;AADR;AAII;EACI,8BAAA;AAFR","sourcesContent":["section {\n    background: var(--ion-color-primary-contrast);\n}\n\n.container {\n    display: flex;\n    padding: 1em;\n    margin-top: 0.75em;\n\n    a {\n        cursor: pointer;\n    }\n\n    p {\n        margin: 0;\n    }\n\n    i {\n        flex-shrink: 0;\n        margin-right: 0.5em;\n        font-size: 2.25em;\n        color: var(--ion-color-primary);\n    }\n\n    .error {\n        color: var(--ion-color-danger);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
