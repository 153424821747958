import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';

import { PopoverController } from '@ionic/angular';

import {
    InviteService,
    isInvite,
    InviteRef,
} from '@app/services/invite.service';

import { SmartLocationService } from '@app/services/smart-location.service';

import { RegistrationService } from '@app/services/registration.service';

import { ErrorService } from '@app/services/error.service';

import { AppService } from '@app/services/app.service';

import {
    ProfileService,
    getDefaultChosenName,
} from '@app/services/profile.service';

import {
    AuthenticationService,
    ROLE_CLIENT,
} from '@app/services/authentication.service';

import { CustomValidator, hasFormError } from '@app/form-utils';

export interface RegistrationParams {
    countryCode: string;
    email: string;
    name: string;
    ssoToken?: string;
    magicCode?: string;
    invite: InviteRef;
    // In case they've already accepted the terms as part of the magic code
    // registration flow.
    hideAcceptTerms?: boolean;
    // Whether the user is known to be on the student registration path
    isStudent?: boolean;
}

function isRegistrationParams(data: unknown): data is RegistrationParams {
    const params = data as RegistrationParams;
    return !!(
        params &&
        typeof params.countryCode === 'string' &&
        typeof params.email === 'string' &&
        typeof params.name === 'string' &&
        (typeof params.magicCode === 'string' ||
            params.magicCode === undefined) &&
        (typeof params.ssoToken === 'string' ||
            params.ssoToken === undefined) &&
        (typeof params.hideAcceptTerms === 'boolean' ||
            params.hideAcceptTerms === undefined) &&
        (isInvite(params.invite) || params.invite === null)
    );
}

type RegistrationParamsRef = RegistrationParams | null;

function getDefaultProvinceCity(invite: InviteRef) {
    if (invite && invite.connectPro && invite.isStudent) {
        // Students have their city and province defaulted to their teacher
        return {
            city: invite.connectPro.city,
            province: invite.connectPro.province,
        };
    }
    return {
        city: '',
        province: '',
    };
}

function getRegistrationParams(): RegistrationParamsRef {
    if (!history.state) {
        return null;
    }
    const params = history.state['params'];
    if (isRegistrationParams(params)) {
        return params;
    }
    return null;
}

/*
 * NOTE: this is called the sso registration page, but it handles both SSO and
 * magic code based registrations. It should be called "email registration"
 * or something like that - since it's used for registering using a verified
 * email address.
 */
@Component({
    selector: 'app-sso-registration',
    templateUrl: './sso-registration.page.html',
    styleUrls: ['./sso-registration.page.scss'],
})
export class SsoRegistrationPage implements OnInit {
    params: RegistrationParamsRef = null;
    form: FormGroup;
    invalidAccessCode: CustomValidator = new CustomValidator(
        'accessCode',
        'invalid',
        () => this.form
    );

    constructor(
        private formBuilder: FormBuilder,
        private locationService: SmartLocationService,
        private registrationService: RegistrationService,
        private appService: AppService,
        private popoverCtrl: PopoverController,
        private errorService: ErrorService,
        private profileService: ProfileService,
        private authService: AuthenticationService,
        private inviteService: InviteService,
        private router: Router
    ) {
        this.form = this.formBuilder.group({
            accessCode: [
                '',
                [Validators.required, this.invalidAccessCode.makeValidator()],
            ],
            fullName: ['', Validators.required],
            chosenName: ['', Validators.required],
            acceptTerms: [false, Validators.required],
            wantsEmailUpdates: [false],
        });
    }

    get canSubmit(): boolean {
        return (
            !!this.form &&
            this.form.valid &&
            (this.form.value.acceptTerms || !this.showAcceptTerms)
        );
    }

    get email(): string {
        return this.params?.email ?? '';
    }

    get proName(): string {
        return this.params?.invite?.connectPro?.name ?? '';
    }

    get isStudent(): boolean {
        return !!this.params?.isStudent;
    }

    get hasInvite(): boolean {
        return !!this.params?.invite;
    }

    get showAcceptTerms(): boolean {
        return !this.params?.hideAcceptTerms;
    }

    /*
     * The access code to use when attempting to register. This is either pulled
     * from the form (when the user is manually entering the code) or from the
     * pre-registration invite via route params.
     */
    get accessCode(): string {
        return (
            this.params.invite?.accessCode ??
            this.form.value.accessCode.trim().toUpperCase()
        );
    }

    ngOnInit() {}

    ionViewWillEnter() {
        this.params = getRegistrationParams();
        if (
            !this.params ||
            (this.params.invite &&
                this.params.invite.providesRole != ROLE_CLIENT)
        ) {
            this.locationService.back();
            return;
        }
        this.form.reset({
            /* If a pre-registration invite exists then we don't include the
             * access code input on the page. So we use dummy data to bypass
             * form validation in this case. (note we can't plug in the invite
             * access code here because pre-registration invites have very
             * long codes which breaks validation) */
            /* TODO - this is a hack, we should implement conditional field
             * validation (see https://medium.com/ngx/3-ways-to-implement-conditional-validation-of-reactive-forms-c59ed6fc3325) */
            accessCode: this.hasInvite ? 'ZZZZ' : '',
            fullName: this.params.name,
            chosenName: getDefaultChosenName(this.params.name),
            acceptTerms: false,
            wantsEmailUpdates: false,
        });
    }

    async lookupInvite(): Promise<InviteRef> {
        if (this.params.invite) {
            return this.params.invite;
        }
        const invites = await this.inviteService.fetch();
        const invite = invites.find(
            (invite) => invite.accessCode === this.form.value.accessCode
        );
        return invite ?? null;
    }

    async registerAndLogin() {
        const countryCode = this.params.countryCode;
        const fullName = this.form.value.fullName;
        const chosenName = this.form.value.chosenName;
        const wantsEmailUpdates = this.form.value.wantsEmailUpdates;
        const accessCode = this.accessCode;

        try {
            if (this.params.ssoToken) {
                await this.registrationService.registerAndLoginSSO({
                    accessCode: accessCode,
                    ssoToken: this.params.ssoToken,
                    country: countryCode,
                });
            } else {
                await this.registrationService.registerUsingMagicCodeAndLogin({
                    country: countryCode,
                    name: fullName,
                    email: this.email,
                    code: this.params.magicCode,
                    accessCode: accessCode,
                });
            }
        } catch (error) {
            if (error.code === 404) {
                this.invalidAccessCode.set(true);
                return;
            }
            throw error;
        }

        const invite = await this.lookupInvite();
        const { city, province } = getDefaultProvinceCity(invite);
        try {
            await this.profileService.create({
                fullName: fullName,
                chosenName: chosenName,
                city: city,
                country: countryCode,
                province: province,
                wantsEmailUpdates: wantsEmailUpdates,
            });
        } catch (error) {
            if (error.code !== 409) {
                throw error;
            }
            // The profile already exists so nothing to do.
            // TODO - should we update the existing profile?
        }
        return true;
    }

    async onSignUp() {
        if (!this.canSubmit) {
            return;
        }
        this.invalidAccessCode.set(false);
        await this.errorService.trySubmitForm(this.form, async () => {
            if (await this.registerAndLogin()) {
                await this.appService.navigateNext();
                return true;
            }
            return false;
        });
    }

    hasError(field: string, error: string): boolean {
        return hasFormError(this.form, field, error);
    }

    onCancel() {
        this.router.navigateByUrl('/');
    }

    onFullNameInput() {
        if (!this.form.controls.chosenName.dirty) {
            this.form.controls.chosenName.setValue(
                getDefaultChosenName(this.form.value.fullName)
            );
        }
    }

    onAccessCodeInput() {
        this.invalidAccessCode.set(false);
    }

    static navigate(router: Router, params: RegistrationParams) {
        router.navigate(['/', 'email-registration'], {
            state: {
                params: params,
            },
        });
    }
}
