import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    SimpleChanges,
} from '@angular/core';

import { NgControl } from '@angular/forms';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { APIError } from '@app/services/error';

const MIN_LENGTH = 8;

@Component({
    selector: 'app-make-password',
    templateUrl: './make-password.component.html',
    styleUrls: ['./make-password.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MakePasswordComponent,
        },
    ],
})
export class MakePasswordComponent implements ControlValueAccessor, OnInit {
    @Output() showPasswordEvent = new EventEmitter<boolean>();

    @Output() passwordUpdateEvent = new EventEmitter<boolean>();

    @Input() error: string = '';

    password: string;
    confirmPassword: string;

    onChange: any = () => {};

    onTouched = () => {};

    disabled = false;

    isDirty: boolean = false;

    isConfirmDirty: boolean = false;

    showPassword: boolean = false;

    /*
     * Note: this string should read okay in two forms:
     * 1) Password (X)
     * 2) "Your password X"
     */
    passwordSpecs: string = `must be at least ${MIN_LENGTH} characters`;

    constructor() {}

    get passwordMatch() {
        return this.password === this.confirmPassword;
    }

    get hasStrengthError(): boolean {
        if (this.password && this.password.length < MIN_LENGTH) {
            return true;
        }
        return false;
    }

    /*
    / Only if the 2 password checks pass does the component emit the value to the form
    / if either fail blank is emitted to form to overwrite any previous control values
    / to invalidate the form.
    */

    onConfirmPassword() {
        if (this.passwordMatch && !this.hasStrengthError) {
            this.onChange(this.password);
        } else {
            this.onChange();
        }
    }
    //if user changes password after form is valid
    // this will call onChange in onConfirmPassword to invalidate
    onPassword() {
        this.passwordUpdateEvent.emit(true);
        this.onConfirmPassword();
    }

    onConfirmBlur() {
        this.isConfirmDirty = true;
    }

    onPasswordBlur() {
        this.isDirty = true;
    }

    ngOnInit() {}

    toggleShow() {
        this.showPassword = !this.showPassword;
        this.showPasswordEvent.emit(this.showPassword);
    }

    writeValue(value: any): void {
        this.password = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
