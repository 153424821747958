import { Component, OnInit, Input } from '@angular/core';

import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';

import { PopoverController } from '@ionic/angular';

import { AppService } from '@app/services/app.service';

import { ErrorService } from '@app/services/error.service';

import { RegistrationService } from '@app/services/registration.service';

import {
    AuthenticationService,
    isLoginDetails,
} from '@app/services/authentication.service';

import {
    ScopeDetails,
    LoginDetails,
    SSOLoginDetails,
} from '@app/services/authentication.service';

import { getAPIError, APIError } from '@app/services/error';

@Component({
    selector: 'app-pro-registration',
    templateUrl: './pro-registration.page.html',
    styleUrls: ['./pro-registration.page.scss'],
})
export class ProRegistrationPage implements OnInit {
    @Input()
    loginDetails: LoginDetails | SSOLoginDetails;

    form: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private registrationService: RegistrationService,
        private appService: AppService,
        private errorService: ErrorService,
        private authenticationService: AuthenticationService,
        private popoverCtrl: PopoverController
    ) {}

    get canSubmit(): boolean {
        return !!(this.form && this.form.valid);
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            accessCode: ['', Validators.required],
            termsAcceptance: [false, Validators.requiredTrue],
        });
    }

    ionViewWillEnter() {}

    onCancel() {
        this.popoverCtrl.dismiss();
    }

    /*
     * Handles authentication either via email/password or SSO token.
     */
    async authenticate(scope: ScopeDetails = {}): Promise<void> {
        if (isLoginDetails(this.loginDetails)) {
            await this.authenticationService.authenticate(
                this.loginDetails,
                scope
            );
        } else {
            await this.authenticationService.authenticateSSO(
                this.loginDetails,
                scope
            );
        }
    }

    private async createRole(invite: string): Promise<boolean> {
        await this.authenticate({ role: 'account' });
        try {
            await this.registrationService.createClientRole(invite);
        } catch (error) {
            const apiError = getAPIError(error);
            if (apiError.code === 404) {
                throw 'That access code could not be found.';
            }
            if (apiError.code === 409) {
                /* What probably happened is a previous attempt was partially
                 * successful, but failed at eg. re-authentication. */
            } else {
                throw apiError;
            }
        }
        /* Now we should be able to re-authenticate with full client scope */
        await this.authenticate();
        await this.appService.navigateNext();
        await this.popoverCtrl.dismiss();
        return true;
    }

    onSubmit() {
        if (!this.canSubmit) {
            return;
        }
        const accessCode = this.form.value.accessCode.trim().toUpperCase();

        this.errorService.trySubmitForm(this.form, () => {
            return this.createRole(accessCode);
        });
    }

    static async show(
        popoverCtrl: PopoverController,
        details: LoginDetails | SSOLoginDetails
    ) {
        const popover = await popoverCtrl.create({
            component: ProRegistrationPage,
            cssClass: 'pro-registration-popover',
            translucent: false,
            componentProps: {
                loginDetails: details,
            },
        });
        await popover.present();
        await popover.onDidDismiss();
    }
}
