import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { APIError, getAPIError, InvalidServerResponse } from './error';

import { UrlService } from './url.service';

import { AuthStorageService } from './auth-storage.service';

import { Invite, asInviteArray, isInvite } from './invite-model';

export const ROLE_CLIENT = 'client';
export const MODE_FULL = 'full';
export const MODE_DELETE = 'delete';

export interface LoginDetails {
    readonly country: string;
    readonly email: string;
    readonly password: string;
}

export interface SSOLoginDetails {
    readonly country: string;
    readonly ssoToken: string;
}

export interface MagicSignInDetails {
    readonly country: string;
    readonly email: string;
    readonly code: string;
}

export interface CreateMagicSignInDetails {
    readonly country: string;
    readonly email: string;
}

export interface SSOIdentity {
    readonly name: string;
    readonly email: string;
}

export interface ScopeDetails {
    readonly role?: string;
    readonly mode?: string;
}

export interface MagicSignIn {
    readonly uuid: string;
}

export interface AccountNotFoundError extends APIError {
    invites: Invite[];
}

export type SSOIdentityRef = SSOIdentity | null;
export type MagicSignInRef = MagicSignIn | null;
export type AccountNotFoundErrorRef = AccountNotFoundError | null;

export interface AuthResult {
    token: string;
}

export function isLoginDetails(data: unknown): data is LoginDetails {
    const details = data as LoginDetails;
    return !!(
        details &&
        typeof details.country === 'string' &&
        typeof details.email === 'string' &&
        typeof details.password === 'string'
    );
}

export function isSSOLoginDetails(data: unknown): data is LoginDetails {
    const details = data as SSOLoginDetails;
    return !!(
        details &&
        typeof details.country === 'string' &&
        typeof details.ssoToken === 'string'
    );
}

export function isAuthResult(payload: unknown): payload is AuthResult {
    const data = payload as AuthResult;
    return data && typeof data.token == 'string';
}

export function isSSOIdentity(data: unknown): data is SSOIdentity {
    const identity = data as SSOIdentity;
    return !!(
        data &&
        typeof identity.name === 'string' &&
        typeof identity.email === 'string'
    );
}

export function asSSOIdentity(data: any): SSOIdentityRef {
    if (!data) {
        return null;
    }
    const identity = {
        name: data['name'],
        email: data['email'],
    };
    if (isSSOIdentity(identity)) {
        return identity;
    }
    return null;
}

export function isMagicSignIn(data: unknown): data is MagicSignIn {
    const magic = data as MagicSignIn;
    return !!(magic && typeof magic.uuid === 'string');
}

export function asMagicSignIn(data: any): MagicSignInRef {
    if (!data) {
        return null;
    }
    const magic = {
        uuid: data['uuid'],
    };
    if (!isMagicSignIn(magic)) {
        return null;
    }
    return magic;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(
        private urlService: UrlService,
        private authStorage: AuthStorageService,
        private http: HttpClient
    ) {}

    /* Authenticates a user given the email, password and country code. The
     * country determines which API server URL to use when authenticating.
     * Returns a promise that resolves on authentication success. Otherwise
     * the promise rejects with an APIError. On success the token and country
     * are saved and used in subsequent authenticated requests. */
    async authenticate(
        login: LoginDetails,
        scope: ScopeDetails = {}
    ): Promise<void> {
        const token = await this.authenticateOnly(login, scope);
        await this.authStorage.saveToken(token, login.country);
    }

    /* Same as the authenticate call but returns the token and doesn't save it
     * into storage. */
    async authenticateOnly(
        login: LoginDetails,
        scope: ScopeDetails = {}
    ): Promise<string> {
        const url = this.urlService.getAuthUrl(login.country);
        if (!url) {
            return Promise.reject(
                APIError('unsupported country: ' + login.country)
            );
        }
        const body = {
            username: login.email,
            password: login.password,
            role: scope.role ?? ROLE_CLIENT,
            mode: scope.mode ?? MODE_FULL,
        };
        try {
            const payload = await this.http.post(url, body).toPromise();
            if (!isAuthResult(payload)) {
                throw APIError('invalid auth result payload');
            }
            return payload['token'];
        } catch (error) {
            throw getAPIError(error);
        }
    }

    authenticateSSO(
        details: SSOLoginDetails,
        scope: ScopeDetails = {}
    ): Promise<void> {
        return this.authenticateSSOOnly(details, scope).then((token) => {
            return this.authStorage.saveToken(token, details.country);
        });
    }

    authenticateSSOOnly(
        details: SSOLoginDetails,
        scope: ScopeDetails = {}
    ): Promise<string> {
        const url = this.urlService.getAuthSSOUrl(details.country);
        if (!url) {
            return Promise.reject(
                APIError('unsupported country: ' + details.country)
            );
        }
        const body = {
            sso_token: details.ssoToken,
            role: scope.role ?? ROLE_CLIENT,
            mode: scope.mode ?? MODE_FULL,
        };
        return this.http
            .post(url, body)
            .toPromise()
            .then((payload: any) => {
                if (!isAuthResult(payload)) {
                    throw APIError('invalid auth result payload');
                }
                const token = payload['token'];
                return token;
            })
            .catch((response: HttpErrorResponse) => {
                throw getAPIError(response);
            });
    }

    async createMagicSignIn(
        details: CreateMagicSignInDetails
    ): Promise<MagicSignIn> {
        const url = await this.urlService.getMagicSignInUrl(details.country);
        try {
            const payload = await this.http
                .post(url, {
                    email: details.email,
                })
                .toPromise();

            const magic = asMagicSignIn(payload);
            if (!magic) {
                throw InvalidServerResponse();
            }
            return magic;
        } catch (error) {
            throw getAPIError(error);
        }
    }

    async authenticateMagicSignInOnly(
        details: MagicSignInDetails
    ): Promise<string> {
        const body = {
            email: details.email,
            magic_code: details.code,
            scope_list: ['account:full', 'client:full'],
        };
        const url = await this.urlService.getAuthMagicSignInUrl(
            details.country
        );
        try {
            const response = await this.http.post(url, body).toPromise();
            return response['token'];
        } catch (error) {
            throw getAPIError(error);
        }
    }

    async authenticateMagicSignIn(details: MagicSignInDetails): Promise<void> {
        const token = await this.authenticateMagicSignInOnly(details);
        await this.authStorage.saveToken(token, details.country);
    }
}

export { APIError };
