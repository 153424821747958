import { DateTime } from 'luxon';

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { UrlService } from './url.service';

import { InvalidServerResponse, getAPIError } from './error';

export interface Terms {
    publicationDate: DateTime;
    privacyPolicy: string;
    termsAndConditions: string;
    changeNotes: string;
}

export type TermsRef = Terms | null;

export function isTerms(data: unknown): data is Terms {
    const terms = data as Terms;
    return !!(
        terms &&
        terms.publicationDate instanceof DateTime &&
        !terms.publicationDate.invalid &&
        typeof terms.privacyPolicy === 'string' &&
        typeof terms.termsAndConditions === 'string' &&
        typeof terms.changeNotes === 'string'
    );
}

export function asTerms(data: any): TermsRef {
    if (!data) {
        return null;
    }
    const terms = {
        publicationDate: DateTime.fromISO(data['publication_date']),
        privacyPolicy: data['privacy_policy'],
        termsAndConditions: data['terms_and_conditions'],
        changeNotes: data['changes'],
    };
    if (!isTerms(terms)) {
        return null;
    }
    return terms;
}

@Injectable({
    providedIn: 'root',
})
export class TermsService {
    cached: TermsRef = null;

    constructor(private http: HttpClient, private urlService: UrlService) {}

    /*
     * Fetch and return the most recently published Terms. Note this caches
     * the response (on success) and uses it for future calls.
     */
    async fetch(): Promise<Terms> {
        if (this.cached) {
            return this.cached;
        }

        const url = await this.urlService.getTermsUrl();
        try {
            const payload = await this.http.get(url).toPromise();
            const terms = asTerms(payload);
            if (!terms) {
                throw InvalidServerResponse(payload);
            }
            this.cached = terms;
            return terms;
        } catch (error) {
            throw getAPIError(error);
        }
    }

    getCached(): TermsRef {
        return this.cached;
    }

    async clear(): Promise<void> {
        this.cached = null;
    }
}
