import { Component, OnInit } from '@angular/core';
import { ActivityService } from '@app/services/activity.service';
import { NavParams } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { Duration } from 'luxon';

const MINUTES_PER_HOUR = 60;
@Component({
    selector: 'app-duration-popover',
    templateUrl: './duration-popover.component.html',
    styleUrls: ['./duration-popover.component.scss'],
})
export class DurationPopoverComponent implements OnInit {
    duration: number;

    constructor(
        private popoverCtrl: PopoverController,
        public navParams: NavParams
    ) {}

    //creates duration popover, returns duration on save and -1 on cancel
    static async show(
        event: Event,
        activityDuration: number,
        popoverController: PopoverController
    ): Promise<number> {
        const popover = await popoverController.create({
            event,
            component: DurationPopoverComponent,
            cssClass: 'duration-popover',
            showBackdrop: false,
            mode: 'md', // Otherwise it blends into background on ios
            componentProps: { duration: activityDuration },
        });

        await popover.present();
        const modelData = await popover.onDidDismiss();
        if (modelData.data !== undefined) {
            return modelData.data;
        }
        return 0;
    }

    addDuration(durationIncrement: number) {
        this.duration = this.duration + durationIncrement;
    }

    durationChecker(durationIncrement: number): boolean {
        return this.duration + durationIncrement < 0;
    }

    maxDurationChecker(durationIncrement: number): boolean {
        return (
            this.duration + durationIncrement >
            ActivityService.MAX_DURATION_PER_ACTIVITY_IN_MINUTES
        );
    }

    durationNote(): boolean {
        return (
            this.duration >=
            ActivityService.MAX_DURATION_PER_ACTIVITY_IN_MINUTES
        );
    }

    ngOnInit() {
        this.duration = +this.navParams.get('duration') || 0;
    }

    onSubmitPopover() {
        this.closePopover();
    }

    onCancelPopover() {
        this.popoverCtrl.dismiss();
    }

    closePopover() {
        this.popoverCtrl.dismiss(this.duration);
    }
}
