// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content > p {
  margin-left: var(--app-page-margin);
  margin-right: var(--app-page-margin);
}

form {
  margin-left: var(--app-page-margin);
  margin-right: var(--app-page-margin);
}

label {
  margin-top: 1em;
}

.stacked-page-action-area {
  margin-top: 1em;
}

app-accept-terms-input {
  margin: 1.5em 0;
}

.validation-error {
  margin: 0.5em 0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/sso-registration/sso-registration.page.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;EACA,oCAAA;AACJ;;AAEA;EACI,mCAAA;EACA,oCAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":["ion-content > p {\n    margin-left: var(--app-page-margin);\n    margin-right: var(--app-page-margin);\n}\n\nform {\n    margin-left: var(--app-page-margin);\n    margin-right: var(--app-page-margin);\n}\n\nlabel {\n    margin-top: 1em;\n}\n\n.stacked-page-action-area {\n    margin-top: 1em;\n}\n\napp-accept-terms-input {\n    margin: 1.5em 0;\n}\n\n.validation-error {\n    margin: 0.5em 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
