import { Injectable } from '@angular/core';

import { StorageService, StorageKey } from './storage.service';

import { environment } from '@app/../environments/environment';

// Legacy keys for accessing auth data in local storage
const TOKEN_STORAGE_KEY = 'ggf_token';
const COUNTRY_STORAGE_KEY = 'ggf_country';

export interface TokenDetails {
    token: string;
    country: string;
    serverUrl: string;
}

export interface Auth {
    token: string;
    country: string;
}

/* Returns the URL base for the API server designated for the country code,
 * or an empty string if no URL could be found. */
export function getServerUrlForCountry(country: string): string {
    return environment.serverUrls[country] || '';
}

/*
 * Used to access auth data stored by the legacy app (pre 2.0.0 release)
 */
class LegacyStorage {
    /* Returns the JWT token as stored by the legacy app (or empty string) */
    static loadToken(): string {
        const loggedInStr = localStorage.getItem('logged_in');
        if (loggedInStr) {
            try {
                const loggedIn = JSON.parse(loggedInStr);
                const token = loggedIn['token'];
                if (token) {
                    return token;
                }
            } catch (error) {
                console.log('error loading legacy token', error);
            }
        }
        return '';
    }

    /* Returns the country code as stored by the legacy app (or empty string) */
    static loadCountry(): string {
        const serverStr = localStorage.getItem('currentServer');
        if (serverStr) {
            try {
                const server = JSON.parse(serverStr);
                const country = server['iso_code'];
                if (country) {
                    return country;
                }
            } catch (error) {
                console.log('error loading legacy country code', error);
            }
        }
        return '';
    }

    /* Removes any local storage data associated with the legacy client app */
    static clear() {
        localStorage.removeItem('logged_in');
        localStorage.removeItem('currentServer');
        localStorage.removeItem('user_settings');
    }
}

/*
 * Used to access auth data stored by the new app (version 2.0.0+) in local
 * storage.
 */
class AuthLocalStorage {
    /*
     * Saves the token and country code to local storage.
     */
    static saveAuth(token: string, country: string) {
        localStorage.setItem(TOKEN_STORAGE_KEY, token);
        localStorage.setItem(COUNTRY_STORAGE_KEY, country);
    }

    /*
     * Load the auth token from local storage. Note this function will first
     * migrate legacy data. (if any)
     */
    static loadToken(): string {
        this.migrate();
        return localStorage.getItem(TOKEN_STORAGE_KEY) || '';
    }

    /*
     * Load the country code from local storage. Note this function will first
     * migrate legacy data. (if any)
     */
    static loadCountry(): string {
        this.migrate();
        return localStorage.getItem(COUNTRY_STORAGE_KEY) || '';
    }

    static clear() {
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        localStorage.removeItem(COUNTRY_STORAGE_KEY);
    }

    /*
     * Converts legacy auth data (version 1.7 and under) into the new local
     * storage format. As well, this function also expunges any legacy data.
     */
    static migrate() {
        const token = LegacyStorage.loadToken();
        const country = LegacyStorage.loadCountry();
        if (token && country) {
            this.saveAuth(token, country);
        }
        LegacyStorage.clear();
    }
}

/*
 * Provides storage for the auth token and corresponding country code. Makes
 * use of promises to allow for use of an async backend like ionic storage.
 * See https://github.com/ionic-team/ionic-storage
 */
@Injectable({
    providedIn: 'root',
})
export class AuthStorageService {
    // Cached token and country
    private token: string = '';
    private country: string = '';

    constructor(private storage: StorageService) {}

    /*
     * Migrate legacy auth data into the latest storage format.
     */
    private migrate(): Promise<void> {
        AuthLocalStorage.migrate();
        return Promise.resolve();
    }

    /*
     * Loads the token from persistent storage and returns via promise. Returns
     * an empty string if there is no stored token.
     */
    private async loadAuth(): Promise<Auth> {
        // Attempt to migrate any legacy local storage
        await this.migrate();
        const token = await this.storage.load(StorageKey.TOKEN);
        const country = await this.storage.load(StorageKey.COUNTRY);
        return {
            token: token,
            country: country,
        };
    }

    /*
     * Saves the token and country code to local storage. Returns a promise that
     * resolves when the save is complete.
     */
    saveAuth(token: string, country: string): Promise<void> {
        return Promise.all([
            this.storage.save(StorageKey.TOKEN, token),
            this.storage.save(StorageKey.COUNTRY, country),
        ]).then(() => {
            // Cache the result
            this.token = token;
            this.country = country;
        });
    }

    // Deprecated: use saveAuth instead
    saveToken(token: string, country: string): Promise<void> {
        return this.saveAuth(token, country);
    }

    /* Removes the token (and country code) from storage and cache. Returns
     * a promise that resolves when the removal is complete. */
    clear(): Promise<void> {
        return Promise.all([
            this.storage.remove(StorageKey.TOKEN),
            this.storage.remove(StorageKey.COUNTRY),
        ]).then(() => {
            this.token = '';
            this.country = '';
        });
    }

    /*
     * Load, or pull from cache, the stored auth token.
     */
    getToken(): Promise<string> {
        return this.getAuth().then((auth) => auth.token);
    }

    /*
     * Loads and returns the country code from local storage. This function
     * first checks if the country code is cached and returns that instead.
     */
    getCountry(): Promise<string> {
        return this.getAuth().then((auth) => auth.country);
    }

    /*
     * Load, or pull from cache, stored auth data and return via promise.
     */
    getAuth(): Promise<Auth> {
        if (this.token) {
            return Promise.resolve({
                token: this.token,
                country: this.country,
            });
        }
        return this.loadAuth().then((auth) => {
            this.token = auth.token;
            this.country = auth.country;
            return auth;
        });
    }

    /*
     * Load, or pull from cache, the stored TokenDetails.
     */
    getTokenDetails(): Promise<TokenDetails> {
        return this.getAuth().then((auth) => {
            return {
                token: auth.token,
                country: auth.country,
                serverUrl: getServerUrlForCountry(auth.country),
            };
        });
    }

    /* Verifies there is a stored auth token and country code. (returns check
     * via promise) */
    checkAuthToken(): Promise<boolean> {
        return this.getTokenDetails().then((details) => {
            return !!(details.token && details.country);
        });
    }
}
