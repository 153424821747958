import { Subscription } from 'rxjs';

import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { PopoverController, NavParams } from '@ionic/angular';

import { TryAgainFunc } from '@app/services/error.service';

import { getUserFacingMessage, isInvalidAuthToken } from '@app/services/error';

import { wait } from '@app/form-utils';

@Component({
    selector: 'app-try-again-popover',
    templateUrl: './try-again-popover.component.html',
    styleUrls: ['./try-again-popover.component.scss'],
})
export class TryAgainPopoverComponent implements OnInit {
    buttonEnabled: boolean = true;

    tryAgainFunc: TryAgainFunc<any> = null;

    details: string = '';

    // Subscription for router events
    routerEventsSub: Subscription;

    constructor(
        private popoverCtrl: PopoverController,
        private navParams: NavParams,
        private router: Router
    ) {
        // Have this popover automatically dismiss itself if the user navigates
        // away from the starting route.
        const startingUrl = this.router.url;
        this.routerEventsSub = this.router.events.subscribe((event) => {
            if (startingUrl && this.router.url !== startingUrl) {
                this.popoverCtrl.dismiss();
            }
        });
    }

    ngOnInit() {
        this.tryAgainFunc = this.navParams.get('callback') as TryAgainFunc<any>;
        const error = this.navParams.get('error');
        this.showErrorDetails(error);
    }

    ngOnDestroy() {
        // Avoids memory leaks
        this.routerEventsSub.unsubscribe();
    }

    showErrorDetails(error) {
        this.details = getUserFacingMessage(error);
    }

    onCancelClick() {
        this.popoverCtrl.dismiss();
    }

    onButtonClick() {
        if (!this.tryAgainFunc) {
            return;
        }
        this.buttonEnabled = false;
        /* Wait a little before trying again. This gives the user a chance to
         * see the spinner which affirms something is happening. Also, in the
         * case where the failure is on the server, this delay prevents the
         * user from rapidly spamming the server with failing requests. The
         * delay is short enough that it shouldn't be too noticeable to the
         * user either. (or cause tangible inconvenience) */
        wait(500)
            .then(this.tryAgainFunc)
            .then((result) => {
                this.popoverCtrl.dismiss(result);
                this.buttonEnabled = true;
            })
            .catch((error) => {
                if (isInvalidAuthToken(error)) {
                    /* The token interceptor displays an alert in this case, so
                     * we dismiss this popover so the user can see the alert. */
                    this.popoverCtrl.dismiss();
                    return;
                }
                this.showErrorDetails(error);
                this.buttonEnabled = true;
            });
    }

    /* Shows the "try-again" popover displaying the given error (usually an
     * APIError) along with a button to try the operation again. Clicking the
     * button calls "func" which should indicate success/fail by returning a
     * resolved/rejected promise, respectively. */
    static create<T>(
        popoverCtrl: PopoverController,
        error: any,
        func: TryAgainFunc<T>
    ): Promise<HTMLIonPopoverElement> {
        return popoverCtrl
            .create({
                component: TryAgainPopoverComponent,
                cssClass: 'try-again-popover',
                showBackdrop: true,
                backdropDismiss: true, // clicking the background dismisses the popover
                componentProps: {
                    callback: func,
                    error: error,
                },
            })
            .then((popover) => {
                popover.present();
                return popover;
            });
    }
}
