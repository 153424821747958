import memoize from 'micro-memoize';

import {
    Component,
    Input,
    Output,
    EventEmitter,
    forwardRef,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DateTime } from 'luxon';

const DATE_FORMAT_EN = "ccc LLL d'{st}'";

export function generateAllowedDays(
    dateTimeISO: string,
    numDays: number
): string[] {
    const dateTime = DateTime.fromISO(dateTimeISO);
    const weekStart = dateTime.startOf('week');
    const days: string[] = [];

    for (let dayCount = 0; dayCount < numDays; dayCount++) {
        days.push(weekStart.plus({ days: dayCount }).toISODate());
    }
    return days;
}

const generateAllowedDaysCached = memoize(generateAllowedDays);

@Component({
    selector: 'app-date-selector',
    templateUrl: './date-selector.component.html',
    styleUrls: ['./date-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DateSelectorComponent),
        },
    ],
})
export class DateSelectorComponent implements ControlValueAccessor {
    @Input()
    weekOf: string = '';

    @Input()
    numDays: number = 14;

    @Input()
    readonly: boolean = false;

    @Output()
    change: EventEmitter<string> = new EventEmitter();

    selected: string;

    onChange: any = () => {};

    onTouched = () => {};

    touched = false;

    disabled = false;

    constructor() {}

    get days(): string[] {
        const dateTimeISO = this.weekOf || DateTime.now().toISO();
        return generateAllowedDaysCached(dateTimeISO, this.numDays);
    }

    get dateFormat(): string {
        return DATE_FORMAT_EN;
    }

    onDateChange() {
        this.onChange(this.selected);
        this.change.emit(this.selected);
    }

    onFocus() {
        this.touched = true;
        if (this.onTouched) {
            this.onTouched();
        }
    }

    writeValue(value: any): void {
        this.selected = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
