import memoize from 'micro-memoize';

import { Component, OnInit, Input } from '@angular/core';

import {
    getDurationParts,
    getDurationShortDesc,
    getDurationLongDesc,
} from '@app/components/duration/duration.component';

/*
 * Given a number of minutes and the commitment, returns a string describing
 * the progress like: 5 minutes of 1 hour 25 minutes
 */
function getProgressLongDesc(
    minutes: number,
    committed: number,
    after: string
): string {
    return (
        getDurationLongDesc(minutes) +
        ' of ' +
        getDurationLongDesc(committed, after)
    );
}

/*
 * Given a number of minutes and the commitment, returns a formatted string
 * describing progress like: 5m / 1h 25m
 */
function getProgressShortDesc(
    minutes: number,
    committed: number,
    after: string
): string {
    return (
        getDurationShortDesc(minutes) +
        ' / ' +
        getDurationShortDesc(committed, after)
    );
}

/*
 * Describes the minutes of activity progress given the committed number.
 * Example: "1h / 1h 45m"
 */
@Component({
    selector: 'app-minutes-progress',
    templateUrl: './minutes-progress.component.html',
    styleUrls: ['./minutes-progress.component.scss'],
})
export class MinutesProgressComponent implements OnInit {
    // How many minutes of progress were made (eg. scheduled or recorded)
    @Input()
    minutes: number = 0;

    @Input()
    committed: number = 0;

    @Input()
    after: string = '';

    // Note: per-component caching because there can be many progress
    // components per page.
    getProgressLongDescCached = memoize(getProgressLongDesc);
    getProgressShortDescCached = memoize(getProgressShortDesc);

    constructor() {}

    get longDesc(): string {
        return this.getProgressLongDescCached(
            this.minutes,
            this.committed,
            this.after
        );
    }

    get shortDesc(): string {
        return this.getProgressShortDescCached(
            this.minutes,
            this.committed,
            this.after
        );
    }

    ngOnInit() {}
}
