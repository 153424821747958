import { DateTime, Duration } from 'luxon';

import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    NgZone,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Capacitor } from '@capacitor/core';

import {
    getLocalTimePart,
    getTimePartHoursMinutes,
    formatLocalTimePart,
} from '@app/date-utils';

import { environment } from '@app/../environments/environment';

const TIME_FORMAT_EN = 'h:mm a';

/*
 * Generates a list of time parts (eg. "01:30") in 15 minute intervals.
 */
export function generateAllowedTimes(): string[] {
    function format(value: number): string {
        return ('0' + value).slice(-2);
    }

    let times: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const timePart = `${format(hour)}:${format(minute)}:00.000`;
            times.push(timePart);
        }
    }
    return times;
}

/*
 * Rounds a time to the nearest 15 minute interval to match allowed times for selector
 * if the time is past 23:45 round down to not roll over into new day.
 */
export function getRoundedTime(
    timePart: string,
    options: any = {
        toQuarterHour: true,
        toMinute: false,
    }
): string {
    const toQuarterHour = options['toQuarterHour'] ?? false;
    const toMinute = options['toMinute'] ?? false;
    const duration = Duration.fromISOTime(timePart);
    if (duration.invalid) {
        return '';
    }
    let minutes = 0;
    let seconds = 0;
    if (toQuarterHour) {
        minutes = 15 * Math.round(duration.minutes / 15);
        seconds = 0;
    } else if (toMinute) {
        minutes = duration.minutes;
        seconds = 60 * Math.round(duration.seconds / 60);
    } else {
        // No rounding to do
        return timePart;
    }
    const rounded = Duration.fromObject({
        hours: duration.hours,
        minutes: minutes,
        seconds: seconds,
    }).normalize();

    if (rounded.hours >= 24) {
        if (toQuarterHour) {
            return '23:45:00.000';
        }
        return '23:59:00.000';
    }
    return rounded.toISOTime();
}

@Component({
    selector: 'app-time-selector',
    templateUrl: './time-selector.component.html',
    styleUrls: ['./time-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TimeSelectorComponent,
        },
    ],
})
export class TimeSelectorComponent implements ControlValueAccessor, OnInit {
    time: string = '';

    @Input()
    readonly: boolean = false;

    @Output()
    change: EventEmitter<string> = new EventEmitter();

    onChange: any = () => {};

    onTouched = () => {};

    touched = false;

    disabled = false;

    times: string[] = generateAllowedTimes();

    constructor(private ngZone: NgZone) {}

    get timeFormat(): string {
        return TIME_FORMAT_EN;
    }

    get isNative(): boolean {
        return (
            environment.forceNativeTimePicker || Capacitor.isNativePlatform()
        );
    }

    get isIOS(): boolean {
        return Capacitor.getPlatform() === 'ios';
    }

    formatTimePart(timePart: string): string {
        return formatLocalTimePart(timePart, this.timeFormat).toLowerCase();
    }

    ngOnInit() {}

    onTimeChange() {
        this.touched = true;
        this.onTouched();

        this.onChange(this.time);
        this.change.emit(this.time);
    }

    onFocus() {
        this.touched = true;
        if (this.onTouched) {
            this.onTouched();
        }
    }

    writeValue(value: string): void {
        const oldTime = this.time;
        if (this.isNative) {
            // The native time picker only shows hours and minutes
            this.time = getTimePartHoursMinutes(
                getRoundedTime(value, { toMinute: true })
            );
        } else {
            // The non-native picker (ie using ion-select) only supports
            // times in increments of 15 minutes off the hour.
            this.time = getRoundedTime(value);
        }
        if (this.time && value && this.time !== value) {
            /* Looks like we've rounded the time. Let the form know about that.
             * Not sure if this should happen with a delay or not, but it can't
             * hurt and seems like the right thing. */
            setTimeout(() => {
                this.ngZone.run(() => {
                    this.onChange(this.time);
                });
            }, 1);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
