import { v4 as uuidv4 } from 'uuid';

import { Capacitor } from '@capacitor/core';

import { Injectable } from '@angular/core';

import { Device } from '@capacitor/device';

import { HttpClient } from '@angular/common/http';

import { UrlService, CannotBuildUrl } from './url.service';

import { PushService } from './push.service';

import { StorageService, StorageKey } from './storage.service';

import { getAPIError } from './error';

import {
    getCurrentLocale,
    getCurrentTimezone,
    getCurrentTimezoneOffset,
} from '@app/date-utils';

import { Build } from '@app/../environments/build';

interface DeviceDetails {
    osVersion: string;
    manufacturer: string;
    model: string;
}

/* Returns the current platform as a string (browser, ios, android) */
function getPlatform(): string {
    const platform = Capacitor.getPlatform();
    if (platform === 'web') {
        // Historically GGF calls this "browser"
        return 'browser';
    }
    return platform;
}

export function isPlatformWeb(): boolean {
    const platform = Capacitor.getPlatform();
    return platform === 'web';
}

function getWindowOuterSize() {
    return window.outerWidth + 'x' + window.outerHeight;
}

function getWindowInnerSize() {
    return window.innerWidth + 'x' + window.innerHeight;
}

@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    private updated: boolean = false;
    private cachedDeviceUUID: string = '';

    constructor(
        private http: HttpClient,
        private urlService: UrlService,
        private pushService: PushService,
        private storage: StorageService
    ) {
        this.pushService.registered.subscribe((token) => {
            // Automatically save the push token whenever it becomes available
            this.createOrUpdate().catch((error) => {
                console.log('error updating device with push token', error);
            });
        });
        this.pushService.declined.subscribe(() => {
            // TODO - implement this
        });
    }

    /*
     * Returns the device UUID from cache, or from storage, or an empty string
     * if not found.
     */
    private getStoredDeviceUUID(): Promise<string> {
        if (this.cachedDeviceUUID) {
            return Promise.resolve(this.cachedDeviceUUID);
        }
        return this.storage.load(StorageKey.DEVICE, '');
    }

    /*
     * Returns the device UUID or generates one if it doesn't already exist
     */
    async getDeviceUUID(): Promise<string> {
        const stored = await this.getStoredDeviceUUID();
        if (stored) {
            return stored;
        }
        // Generate one dynamically
        this.cachedDeviceUUID = uuidv4();
        await this.storage.save(StorageKey.DEVICE, this.cachedDeviceUUID);
        return this.cachedDeviceUUID;
    }

    /*
     * Update (creating if needed) the device record on the server.
     */
    async createOrUpdate(): Promise<void> {
        const uuid = await this.getDeviceUUID();
        const url = await this.urlService.getDeviceUrl(uuid);
        const details = await DeviceService.getCapacitorDeviceDetails();
        const body = {
            id: uuid,
            app_version: Build.VERSION,
            type: getPlatform(),
            // The endpoint only supports integer hour offsets which is fine
            // because the server mostly (probably) ignores this field.
            timezone: Math.floor(getCurrentTimezoneOffset() / 60),
            timezone_name: getCurrentTimezone(),
            os_version: details.osVersion,
            device_model: details.model,
            manufacturer: details.manufacturer,
            window_outer_size: getWindowOuterSize(),
            window_inner_size: getWindowInnerSize(),
            user_agent: navigator.userAgent,
            locale: getCurrentLocale(),
        };
        /* Include the token only when available. If the device record already
         * exists this prevents the server from overwriting the push token if
         * it's set on the record, but not yet available to this service. */
        const pushToken = await this.pushService.getToken();
        if (pushToken) {
            body['push_token'] = pushToken;
        }
        return this.http
            .put(url, body)
            .toPromise()
            .then(() => {})
            .catch((error) => {
                throw getAPIError(error);
            });
    }

    /*
     * Same as createOrUpdate, but will only make a network request once during
     * the in-memory lifetime of the app. This is the preferred way to update
     * the server-side device record - since the server is only interested in
     * major changes. (ie new app version, new browser, new timezone etc)
     */
    createOrUpdateOnce(): Promise<void> {
        if (this.updated) {
            return Promise.resolve();
        }
        return this.createOrUpdate().then(() => {
            this.updated = true;
        });
    }

    /*
     * Deletes the (previously created) device record on the server. This also
     * clears the device UUID from the service cache.
     */
    async delete(): Promise<void> {
        const uuid = await this.getStoredDeviceUUID();
        if (!uuid) {
            // Nothing to delete
            return;
        }
        const url = await this.urlService.getDeviceUrl(uuid);
        // Note this endpoint always returns 200
        try {
            await this.http.delete(url).toPromise();
        } catch (error) {
            throw getAPIError(error);
        }
        await this.clear();
    }

    clear(): Promise<void> {
        this.updated = false;
        this.cachedDeviceUUID = '';
        return this.storage.remove(StorageKey.DEVICE);
    }

    /*
     * Get device details as reported by the capacitor device plugin. If the
     * plugin isn't available this returns empty strings for all properties.
     */
    static async getCapacitorDeviceDetails(): Promise<DeviceDetails> {
        if (
            !Capacitor.isPluginAvailable('Device') ||
            !Capacitor.isNativePlatform()
        ) {
            /* Capacitor claims the plugin is availble in the browser, but the
             * data it reports is misleading. (eg. macOS/Chrome reports the
             * manufacturer is "Google Inc.") It looks like it's inferring
             * based on the user agent string which isn't great. It's better
             * to leave these blank. */
            return {
                osVersion: '',
                manufacturer: '',
                model: '',
            };
        }
        const deviceInfo = await Device.getInfo();
        return {
            osVersion: deviceInfo.osVersion,
            manufacturer: deviceInfo.manufacturer,
            model: deviceInfo.model,
        };
    }
}
