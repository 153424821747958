import { Component, Input, OnInit } from '@angular/core';
import {
    Activity,
    filterByWeek,
    getCompletedActivities,
    getMissedActivities,
} from '@app/services/activity-model';
import { DateTime } from 'luxon';
import memoize from 'micro-memoize';

const getCompletedActivitiesCached = memoize(getCompletedActivities);
const getMissedActivitiesCached = memoize(getMissedActivities);

@Component({
    selector: 'app-segmented-bar',
    templateUrl: './segmented-bar.component.html',
    styleUrls: ['./segmented-bar.component.scss'],
})
export class SegmentedBarComponent implements OnInit {
    @Input() activities: readonly Activity[] = [];
    @Input() committed: number;
    now: DateTime;
    _unScheduledActivity: any[] = [];
    constructor() {}

    /* The commitment that applies to the current week */
    get numCommittedActivities(): number {
        if (!this.committed) return 1;
        return this.committed;
    }

    // Number of activities in the current week
    get numActivities(): number {
        if (!this.activities) return 0;
        return this.activities.length;
    }

    get numCompletedActivities(): number {
        return getCompletedActivitiesCached(this.activities).length;
    }

    get numMissedActivities(): number {
        if (!this.activities) return 0;
        return getMissedActivitiesCached(this.activities, this.now).length;
    }

    get numNextToGoActivities(): number {
        return (
            this.numActivities -
            (this.numCompletedActivities + this.numMissedActivities)
        );
    }

    get unScheduleActivites(): number {
        return this.numCommittedActivities - this.numActivities;
    }

    get unScheduledActivity(): any[] {
        if (this.numCommittedActivities > this.numActivities) {
            this._unScheduledActivity.length = this.unScheduleActivites;
        } else {
            this._unScheduledActivity.length = 0;
        }
        return this._unScheduledActivity;
    }

    ngOnInit() {
        this.now = DateTime.now();
    }

    isCompleted(progress): boolean {
        return progress.recorded !== undefined && progress.recorded !== null;
    }

    isMissed(progress): boolean {
        return (
            DateTime.fromISO(progress.startTime) < this.now &&
            progress.recorded === null
        );
    }

    isPending(progress): boolean {
        return (
            DateTime.fromISO(progress.startTime) > this.now &&
            progress.recorded === null
        );
    }
}
