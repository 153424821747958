import { NgModule, isDevMode, Type } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';

import { RouteReuseStrategy } from '@angular/router';

import { DatePipe } from '@angular/common';

import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';

import { ComponentsModule } from './components/components.module';

import { UrlService } from './services/url.service';

import { MessageService } from './services/message.service';

import { MessageCacheService } from './services/message-cache.service';

import { AuthStorageService } from './services/auth-storage.service';

import { AuthenticationService } from './services/authentication.service';

import { ActivityService } from './services/activity.service';

import { TokenInterceptor } from './services/token.interceptor';

import { TimeoutInterceptor } from './services/timeout.interceptor';

import { AppService } from './services/app.service';

import { SummaryService } from './services/summary.service';

import { ProfileService } from './services/profile.service';

import { ToastService } from './services/toast.service';

import { SmartLocationService } from './services/smart-location.service';

import { PushService } from './services/push.service';

import { NativePushService } from './services/native-push.service';

import { WebPushService } from './services/web-push.service';

import { NotificationActionService } from './services/notification-action.service';

import { StorageService } from './services/storage.service';

import { LocalProService } from './services/local-pro.service';

import { RetryInterceptor } from './services/retry.interceptor';

import { ServiceWorkerModule } from '@angular/service-worker';

import { Capacitor } from '@capacitor/core';

/*
 * Returns the push service class that we should be using, based on whether we're
 * running on a native device or on the web.
 */
function getPushService(): Type<PushService> {
    if (Capacitor.isNativePlatform()) {
        return NativePushService;
    }
    return WebPushService;
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            // Disabled by default in ionic v7
            // See https://ionicframework.com/docs/techniques/security#enabling-custom-html-parsing-via-innerhtml
            innerHTMLTemplatesEnabled: true,
        }),
        AppRoutingModule,
        ComponentsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode() && !Capacitor.isNativePlatform(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TimeoutInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
        UrlService,
        MessageService,
        MessageCacheService,
        AuthStorageService,
        AuthenticationService,
        ActivityService,
        AppService,
        SummaryService,
        ToastService,
        ProfileService,
        SmartLocationService,
        {
            provide: PushService,
            useClass: getPushService(),
        },
        NotificationActionService,
        StorageService,
        LocalProService,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
