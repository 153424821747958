import { Injectable } from '@angular/core';

import {
    HttpClient,
    HttpErrorResponse,
    HttpStatusCode,
} from '@angular/common/http';

import { InvalidServerResponse, getAPIError } from './error';

import { UrlService } from './url.service';

import { Invite, isInvite, InviteRef, asInviteArray } from './invite-model';

export { Invite, InviteRef, isInvite };

const EMPTY_LIST = [];

@Injectable({
    providedIn: 'root',
})
export class InviteService {
    cached: Invite[] | null;

    constructor(private urlService: UrlService, private http: HttpClient) {}

    async fetch(): Promise<Invite[]> {
        if (this.cached) {
            return this.cached;
        }
        const url = await this.urlService.getInviteUrl();
        return this.http
            .get(url)
            .toPromise()
            .catch((error) => {
                throw getAPIError(error);
            })
            .then((payload) => {
                const invites = asInviteArray(payload);
                if (!invites) {
                    throw InvalidServerResponse(payload);
                }
                this.cached = invites;
                return invites;
            });
    }

    getCached(): Invite[] {
        return this.cached ?? EMPTY_LIST;
    }
}
