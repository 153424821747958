import { Location } from '@angular/common';

import { Injectable } from '@angular/core';

/* Returns the ion-back-button element that appears on the screen */
function findActiveBackButton(): HTMLElement | null {
    function inActivePage(element: HTMLElement) {
        let current = element;
        while (current) {
            /*
             * Ionic will keep a number of pages cached in the DOM, but
             * inactive, for when the user wants to go back. Sadly they mark
             * the inactive pages, not the active one, so it's a bit of work
             * to determine if a button is on an active page or not.
             *
             * Also pages can be nested (ie app-tabs -> app-home) and only the
             * top-most page will be marked inactive. So we need to trace our
             * way to the top of the DOM for each button.
             */
            if (
                current.classList.contains('ion-page') &&
                current.classList.contains('ion-page-hidden')
            ) {
                return false;
            }
            current = current.parentElement;
        }
        return true;
    }
    const buttons = document.querySelectorAll('ion-back-button');
    const button = Array.from(buttons).find(inActivePage);
    if (!button) {
        return null;
    }
    return <HTMLElement>button;
}

@Injectable({
    providedIn: 'root',
})
export class SmartLocationService {
    constructor(private location: Location) {}

    /*
     * Navigate back one page. This looks for and uses the ion-back-button on the
     * current page to do the back navigation. As a fallback it will use the
     * angular location service to go back.
     */
    back() {
        /* Programmatically click on the element. It would be nicer if ionic let
         * us do this through the component interface but sadly they don't. */
        const backButton = findActiveBackButton();
        if (backButton) {
            backButton.click();
        } else {
            this.location.back();
        }
    }
}
