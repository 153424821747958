import { DateTime } from 'luxon';

import { Injectable, EventEmitter } from '@angular/core';

import { ActivityCache } from './activity-cache';

import { getWeekStart, getWeekEnd } from '@app/date-utils';

import { Activity } from './activity-model';

const EMPTY: readonly Activity[] = [];

export { Activity };

export function getWeekCacheKey(weekStart: DateTime): string {
    return weekStart.toISODate();
}

/*
 * Manages activities over a week range.
 */
export class ActivityWeekRangeCache extends ActivityCache {
    constructor(
        public readonly startDate: DateTime,
        public readonly endDate: DateTime
    ) {
        super();
    }

    containsDate(date: DateTime): boolean {
        return date >= this.startDate && date < this.endDate;
    }
}

/*
 * Manages activities on a week-by-week basis.
 */
@Injectable({
    providedIn: 'root',
})
export class ActivityWeeklyCacheService {
    private cacheByWeek: { [weekStartISO: string]: ActivityWeekRangeCache };

    cacheUpdate: EventEmitter<DateTime> = new EventEmitter();

    constructor() {
        this.cacheByWeek = {};
    }

    /*
     * Set the activity cache for the week. (given by starting date) Returns the
     * list of activities passed into this function sorted by ascending date.
     */
    setWeek(
        weekStart: DateTime,
        activities: readonly Activity[]
    ): readonly Activity[] {
        const key = getWeekCacheKey(weekStart);
        const weekEnd = getWeekEnd(weekStart);
        const cache = new ActivityWeekRangeCache(weekStart, weekEnd);
        this.cacheByWeek[key] = cache;
        const result = cache.set(activities);
        this.cacheUpdate.emit(weekStart);
        return result;
    }

    /*
     * Called to update this cache when an activity is created. Note if the
     * week containing this activity hasn't previously been cached, this does
     * nothing.
     */
    created(activity: Activity) {
        const date = DateTime.fromISO(activity.startTime);
        Object.values(this.cacheByWeek).forEach((cache) => {
            if (cache.containsDate(date)) {
                cache.created(activity);
            }
        });
        this.cacheUpdate.emit(getWeekStart(date));
    }

    /*
     * Called to update this cache when an activity is modified. This does
     * nothing if the activity wasn't previously cached.
     */
    updated(replacement: Activity) {
        const date = DateTime.fromISO(replacement.startTime);
        this.deleted(replacement.id);
        Object.values(this.cacheByWeek).forEach((cache) => {
            if (cache.containsDate(date)) {
                cache.created(replacement);
            }
        });
        this.cacheUpdate.emit(getWeekStart(date));
    }

    /*
     * Called to update this cache when an activity is deleted. This function
     * does nothing if the activity wasn't previously cached.
     */
    deleted(id: number) {
        // TODO - refactor this code
        const cache = Object.values(this.cacheByWeek).find((cache) => {
            return !!cache.find(id);
        });
        Object.values(this.cacheByWeek).forEach((cache) => {
            cache.deleted(id);
        });
        if (cache) {
            this.cacheUpdate.emit(cache.startDate);
        }
    }

    /*
     * Returns the contents of the activity cache for the week (give by starting
     * date) or an empty list if the cache is empty/not defined.
     */
    getWeek(weekStart: DateTime): readonly Activity[] {
        const key = getWeekCacheKey(weekStart);
        if (this.cacheByWeek[key]) {
            return this.cacheByWeek[key].activities;
        }
        return EMPTY;
    }

    /*
     * Whether or not the cache contains valid data for the given week.
     */
    isWeekValid(weekStart: DateTime): boolean {
        const key = getWeekCacheKey(weekStart);
        return !!this.cacheByWeek[key];
    }

    /*
     * Clears the contents of this cache.
     */
    clear(): Promise<void> {
        this.cacheByWeek = {};
        return Promise.resolve();
    }
}
