import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { InvalidServerResponse, getAPIError } from './error';

import { UrlService, LocalProSearchParams } from './url.service';

import { TimeTracker } from '@app/date-utils';

/* We want to cache the location map because we don't want to keep hitting the
 * server as the user taps around Find a Pro. But also we want the cache to
 * expire in case the user has the app open for a long period of time (eg in a
 * browser tab) and the map might change. */
const LOCATION_MAP_CACHE_EXPIRY = 5 * 60 * 1000; // (ms)

export interface LocalProSearchDetails {
    country?: string;
    province?: string;
    city?: string;
    visibility?: string;
    ids?: number[];
    support?: number;
}

export interface LocalProProfile {
    readonly id: number;
    readonly proID: number;
    readonly proName: string;
    readonly companyName: string;
    readonly streetAddress: string;
    readonly city: string;
    readonly country: string;
    readonly province: string;
    readonly email: string;
    readonly website: string;
    readonly phoneNumber: string;
    readonly phoneExtension: string;
    readonly description: string;
    readonly descriptionDelta: object;
    readonly certification: string;
    readonly affiliation: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly allowConnections: boolean;
}

export interface LocalProCitiesMeta {
    readonly [city: string]: number;
}

export interface LocalProProvinceMeta {
    readonly cities: LocalProCitiesMeta;
    readonly remote: number;
}

export interface LocalProCountryMeta {
    readonly provinces: {
        readonly [province: string]: LocalProProvinceMeta;
    };
    readonly remote: number;
}

export interface LocalProMeta {
    readonly countries: {
        readonly [country: string]: LocalProCountryMeta;
    };
}

export type LocalProProfileRef = LocalProProfile | null;
export type LocalProLocationMapRef = LocalProMeta | null;
export type LocalProLocationMap = LocalProMeta | null;

function isDelta(obj: any): boolean {
    return !!(obj && typeof obj === 'object' && 'ops' in obj);
}

export function isLocalProProfile(data: unknown): data is LocalProProfile {
    const profile = data as LocalProProfile;
    return !!(
        profile &&
        typeof profile.id === 'number' &&
        typeof profile.proID === 'number' &&
        typeof profile.proName === 'string' &&
        typeof profile.companyName === 'string' &&
        typeof profile.streetAddress === 'string' &&
        typeof profile.city === 'string' &&
        typeof profile.country === 'string' &&
        typeof profile.province === 'string' &&
        typeof profile.email === 'string' &&
        typeof profile.website === 'string' &&
        typeof profile.phoneNumber === 'string' &&
        typeof profile.phoneExtension === 'string' &&
        typeof profile.description === 'string' &&
        isDelta(profile.descriptionDelta) &&
        typeof profile.certification === 'string' &&
        typeof profile.affiliation === 'string' &&
        typeof profile.latitude === 'number' &&
        typeof profile.longitude === 'number' &&
        typeof profile.allowConnections === 'boolean'
    );
}

export function asLocalProProfileArray(data: any): LocalProProfile[] | null {
    if (!Array.isArray(data)) {
        return null;
    }
    const profiles = data.map(asLocalProProfile);
    if (profiles.some((profile) => !profile)) {
        return null;
    }
    return profiles;
}

export function asLocalProProfile(data: any): LocalProProfileRef {
    if (!data) {
        return null;
    }
    /*
     * Note: we add guards against returning null for many of the fields here.
     * This was mostly a problem with the old v1 fitness partner endpoint, but
     * they're left here just in case. Also there's a guard against pro_id
     * being null which is the case for some of our staging data.
     */
    const profile = {
        id: data.id,
        proID: data.pro_id ?? 0,
        proName: data.pro_name || '',
        companyName: data.company_name || '',
        streetAddress: data.street_address || '',
        city: data.city || '',
        country: data.country || '',
        province: data.province || '',
        email: data.email || '',
        website: data.website || '',
        phoneNumber: data.phone_number || '',
        phoneExtension: data.phone_extension || '',
        description: data.description || '',
        descriptionDelta: data.description_delta,
        certification: data.certification || '',
        affiliation: data.affiliation || '',
        latitude: parseFloat(data.lat) || 0,
        longitude: parseFloat(data.lng) || 0,
        allowConnections: !!(data.allow_connections && data.pro_id),
    };
    if (!isLocalProProfile(profile)) {
        return null;
    }
    return profile;
}

export function isLocalProMeta(data: any): data is LocalProMeta {
    return !!(
        data &&
        typeof data === 'object' &&
        typeof data.countries === 'object' &&
        Object.values(data.countries).every(isLocalProCountryMeta)
    );
}

function isLocalProCountryMeta(data: any): data is LocalProCountryMeta {
    return !!(
        data &&
        typeof data === 'object' &&
        typeof data.provinces === 'object' &&
        typeof data.remote === 'number' &&
        Object.values(data.provinces).every(isLocalProProvinceMeta)
    );
}

function isLocalProProvinceMeta(data: any): data is LocalProProvinceMeta {
    return !!(
        data &&
        typeof data === 'object' &&
        typeof data.remote == 'number' &&
        typeof data.cities === 'object' &&
        Object.values(data.cities).every((value) => typeof value === 'number')
    );
}

/*
 * Returns a city name from the location map that "closely matches" the given
 * city name. This is helpful for clients who enter eg. "red deer" which should
 * match "Red Deer".
 */
export function findCloseMatchCity(
    provinceMeta: LocalProProvinceMeta,
    city: string
): string {
    if (!provinceMeta) {
        return '';
    }
    const match = Object.keys(provinceMeta.cities).find((check) => {
        return check.toLowerCase().trim() === city.toLowerCase().trim();
    });
    return match || '';
}

/*
 * Whether the given profile has enough info to be plotted on a map.
 */
export function isLocalProProfilePlottable(profile: LocalProProfile): boolean {
    /* Note: some profiles have lat/lng but no street address. In this case
     * the lat/lng refers to the city coordinates. We don't want to plot these
     * profiles since: 1) the location isn't actually useful, 2) if there is
     * more than one of these plotted at a time, for a given city, they'll all
     * be placed atop one another. */
    return !!(profile.streetAddress && profile.longitude && profile.latitude);
}

@Injectable({
    providedIn: 'root',
})
export class LocalProService {
    locationMapCache: LocalProMeta | null = null;
    locationMapCacheExpiry: TimeTracker = new TimeTracker(
        LOCATION_MAP_CACHE_EXPIRY
    );

    constructor(private urlService: UrlService, private http: HttpClient) {}

    /*
     * Search for market profiles given the search criteria
     */
    async search(
        details: LocalProSearchDetails
    ): Promise<readonly LocalProProfile[]> {
        if (details.ids && !details.ids.length) {
            // Nothing to search
            return Promise.resolve([]);
        }
        const url = await this.urlService.getLocalProSearchUrl({
            country: details.country,
            province: details.province,
            city: details.city,
            visibility: details.visibility,
            support: details.support,
            ids: details.ids,
        });
        return this.http
            .get(url)
            .toPromise()
            .then((data) => {
                const profiles = asLocalProProfileArray(data);
                if (!profiles) {
                    throw InvalidServerResponse(data);
                }
                return profiles;
            })
            .catch((error) => {
                throw getAPIError(error);
            });
    }

    async fetchLocationMap(): Promise<LocalProMeta> {
        if (this.locationMapCache && !this.locationMapCacheExpiry.isDone) {
            return this.locationMapCache;
        }
        const url = await this.urlService.getLocalProLocationsUrl();
        return this.http
            .get(url)
            .toPromise()
            .then((data: LocalProMeta) => {
                if (!isLocalProMeta(data)) {
                    throw InvalidServerResponse(data);
                }
                this.locationMapCache = data;
                this.locationMapCacheExpiry.start();
                return data;
            })
            .catch((error) => {
                throw getAPIError(error);
            });
    }

    getLocationMapCached(): LocalProMeta {
        return this.locationMapCache;
    }

    getCountryMetaCached(country: string): LocalProCountryMeta {
        if (!this.locationMapCache) {
            return null;
        }
        return this.locationMapCache.countries[country];
    }

    getProvinceMetaCached(
        country: string,
        province: string
    ): LocalProProvinceMeta {
        if (
            !this.locationMapCache ||
            !this.locationMapCache.countries ||
            !this.locationMapCache.countries[country] ||
            !this.locationMapCache.countries[country].provinces
        ) {
            return null;
        }

        return this.locationMapCache.countries[country].provinces[province];
    }

    getCitiesCached(country: string, province: string): LocalProCitiesMeta {
        if (
            !this.locationMapCache ||
            !this.locationMapCache.countries ||
            !this.locationMapCache.countries[country] ||
            !this.locationMapCache.countries[country].provinces ||
            !this.locationMapCache.countries[country].provinces[province]
        ) {
            return null;
        }

        return this.locationMapCache.countries[country].provinces[province]
            .cities;
    }
}
