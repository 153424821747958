import { OperatorFunction, Observable, from, throwError } from 'rxjs';

import { catchError, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpEventType,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';

import { AuthStorageService, TokenDetails } from './auth-storage.service';

import { AppService } from './app.service';

import { getAPIError, isInvalidAuthToken } from './error';

/* Inject an auth token into the HTTP request header */
function injectToken(
    next: HttpHandler,
    request: HttpRequest<any>
): OperatorFunction<TokenDetails, HttpEvent<any>> {
    return mergeMap((details: TokenDetails) => {
        if (!details.token) {
            return next.handle(request);
        }
        if (
            request.url.startsWith(details.serverUrl) &&
            !request.headers.has('authorization')
        ) {
            // This interceptor only injects the token when hitting the API
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + details.token,
                },
            });
        }
        return next.handle(request);
    });
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private authStorage: AuthStorageService,
        private appService: AppService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        /* To inject the token we first need to (potentially) load it from
         * (async) storage if it isn't already. */
        return from(this.authStorage.getTokenDetails()).pipe(
            injectToken(next, request)
        );
    }
}
