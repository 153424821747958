// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  background-image: url('logo-for-white-background.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 8em;
}

main {
  margin: calc(1 * var(--app-page-margin));
  padding: 1.5em;
}

section {
  margin-top: 2em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/notifications-opt-in/notifications-opt-in.page.scss"],"names":[],"mappings":"AAAA;EACI,sDAAA;EACA,4BAAA;EACA,wBAAA;EACA,2BAAA;EACA,WAAA;EACA,WAAA;AACJ;;AAEA;EACI,wCAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".logo {\n    background-image: url('../../../assets/img/logo-for-white-background.svg');\n    background-repeat: no-repeat;\n    background-size: contain;\n    background-position: center;\n    width: 100%;\n    height: 8em;\n}\n\nmain {\n    margin: calc(1 * var(--app-page-margin));\n    padding: 1.5em;\n}\n\nsection {\n    margin-top: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
