import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import {
    UrlService,
    MessageListGetParams,
    CannotBuildUrl,
} from './url.service';

import { APIError, getAPIError, InvalidServerResponse } from './error';

import { DateTime } from 'luxon';

export interface ClientMessage {
    readonly id: number;
    readonly senderName: string;
    readonly sentISO: string; // UTC
    readonly body: string;
    readonly linkUrl: string;
    readonly linkName: string;
    readonly isRead: boolean;
}

export type ClientMessageRef = ClientMessage | null;

export function isClientMessage(data: unknown): data is ClientMessage {
    const msg = data as ClientMessage;
    return (
        // Bool conversion is needed otherwise falsy values will pass through
        !!msg &&
        typeof msg.id === 'number' &&
        typeof msg.senderName === 'string' &&
        typeof msg.sentISO === 'string' &&
        typeof msg.body === 'string' &&
        typeof msg.linkUrl === 'string' &&
        typeof msg.linkName === 'string' &&
        typeof msg.isRead === 'boolean'
    );
}

/*
 * Attempt to convert the given object (from the API server endpoint) into a
 * ClientMessage type. Returns null if the conversion fails. Taking this
 * approach validating the end result, as opposed to first evaluating the
 * the API returned data, then the conversion) cuts down on code and types.
 */
export function asClientMessage(data: any): ClientMessageRef {
    if (!data) {
        return null;
    }
    // Note: we want to catch cases where data.is_uread === undefined
    const isRead = typeof data.is_unread === 'boolean' ? !data.is_unread : null;
    const date =
        typeof data.date === 'number'
            ? DateTime.fromSeconds(data.date).setZone('UTC').toISO()
            : null;
    const msg = {
        id: data.id,
        senderName: data?.sender?.name,
        sentISO: date,
        body: data.body,
        linkUrl: data.link_url || '', // Note can be null
        linkName: data.link_name || '', // Note can be null
        isRead: isRead,
    };
    if (isClientMessage(msg)) {
        return msg;
    }
    return null;
}

/*
 * Attempts to convert the given data (returned by the API server) to a list
 * of client messages. If any message fails to convert, this function returns
 * null.
 */
export function asClientMessageArray(data: any): ClientMessage[] | null {
    if (data && Array.isArray(data)) {
        const messages = data.map(asClientMessage);
        if (messages.every((msg) => !!msg)) {
            return messages as ClientMessage[];
        }
    }
    return null;
}

/* Returns the messages sorted by descending date, then descending ID (for
 * messages sent on the same day) */
export function sortedNewestFirst(messages: ClientMessage[]): ClientMessage[] {
    // Slice because we don't want to mutate the original list
    return messages.slice().sort((msg1, msg2) => {
        // This string comparison works because they're all expressed in
        // the same time offset. (UTC)
        if (msg1.sentISO < msg2.sentISO) {
            return 1;
        } else if (msg1.sentISO > msg2.sentISO) {
            return -1;
        } else {
            // Sort by ID (always unique) so the sort is deterministic
            return msg2.id - msg1.id;
        }
    });
}

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(private urlService: UrlService, private http: HttpClient) {}

    /*
     * Fetches client messages from the server and returns them via promise.
     * Filter parameters (newer than, older than) can be passed via params.
     */
    async fetch(params: MessageListGetParams = {}): Promise<ClientMessage[]> {
        const url = await this.urlService.getMessageListUrl(params);
        return this.http
            .get(url)
            .toPromise()
            .then((response) => {
                const msgList = asClientMessageArray(response);
                if (msgList === null) {
                    return Promise.reject(InvalidServerResponse(response));
                }
                return msgList;
            })
            .catch((response) => {
                throw getAPIError(response);
            });
    }

    /*
     * Mark the given message as read. Returns a promise that resolves to the
     * state of the new message.
     */
    async markAsRead(message: ClientMessage): Promise<ClientMessage> {
        const body = { is_unread: false };
        const url = await this.urlService.getMessageUrl(message.id);
        return this.http
            .put(url, body)
            .toPromise()
            .then((response) => {
                const msg = asClientMessage(response);
                if (!msg) {
                    throw InvalidServerResponse(response);
                }
                return msg;
            })
            .catch((response) => {
                throw getAPIError(response);
            });
    }
}
