// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --label-input-gap: var(--app-input-to-label-margin);
}

button:focus {
  outline: none;
}

button:focus-within::before {
  content: " ";
  position: absolute;
  display: block;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 4px;
}

button {
  display: flex;
  position: relative;
  border-radius: 5px;
  width: 100%;
  margin-top: var(--label-input-gap);
  margin-bottom: var(--app-input-bottom-margin);
  padding: 0.4em;
  text-align: left;
  border: solid 1px var(--ion-color-medium);
  align-items: center;
  color: var(--ion-color-primary);
  background-color: var(--ion-color-primary-contrast);
  font-size: 1rem;
}
button ion-icon {
  position: relative;
  padding-right: 0.5em;
  width: 1.5rem;
  height: 1.5rem;
}

label.disabled {
  opacity: var(--app-disabled-opacity);
}

button:disabled {
  opacity: var(--app-disabled-opacity);
}

label {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/components/duration-button/duration-button.component.scss"],"names":[],"mappings":"AAAA;EACI,mDAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,kBAAA;EACA,cAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,kCAAA;EACA,6CAAA;EACA,cAAA;EACA,gBAAA;EACA,yCAAA;EACA,mBAAA;EACA,+BAAA;EACA,mDAAA;EACA,eAAA;AACJ;AACI;EACI,kBAAA;EACA,oBAAA;EACA,aAAA;EACA,cAAA;AACR;;AAGA;EACI,oCAAA;AAAJ;;AAGA;EACI,oCAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ","sourcesContent":[":host {\n    --label-input-gap: var(--app-input-to-label-margin);\n}\n\nbutton:focus {\n    outline: none;\n}\n\nbutton:focus-within::before {\n    content: ' ';\n    position: absolute;\n    display: block;\n    top: -1px;\n    left: -1px;\n    right: -1px;\n    bottom: -1px;\n    border: 2px solid var(--ion-color-primary);\n    border-radius: 4px;\n}\n\nbutton {\n    display: flex;\n    position: relative;\n    border-radius: 5px;\n    width: 100%;\n    margin-top: var(--label-input-gap);\n    margin-bottom: var(--app-input-bottom-margin);\n    padding: 0.4em;\n    text-align: left;\n    border: solid 1px var(--ion-color-medium);\n    align-items: center;\n    color: var(--ion-color-primary);\n    background-color: var(--ion-color-primary-contrast);\n    font-size: 1rem;\n\n    ion-icon {\n        position: relative;\n        padding-right: 0.5em;\n        width: 1.5rem;\n        height: 1.5rem;\n    }\n}\n\nlabel.disabled {\n    opacity: var(--app-disabled-opacity);\n}\n\nbutton:disabled {\n    opacity: var(--app-disabled-opacity);\n}\n\nlabel {\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
