import {
    Directive,
    ElementRef,
    Output,
    EventEmitter,
    HostListener,
} from '@angular/core';

@Directive({
    selector: '[clickOutside]',
})
export class ClickOutsideDirective {
    @Output() public clickOutside = new EventEmitter();
    constructor(private _elementRef: ElementRef) {}

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event): void {
        if (!this._elementRef.nativeElement.contains(event.target)) {
            this.clickOutside.emit(false);
        }
    }
}
